import React, { PureComponent, Fragment } from 'react';
import { Container, Col, Row, Button } from 'bootstrap-4-react';
// import { ProgressBar  } from 'react-bootstrap';
import { Link } from 'react-router-dom';

import { Swiper, SwiperSlide } from 'swiper/react';

import SwiperCore, { Navigation, Autoplay } from "swiper";

// swiper core styles
import 'swiper/css';
import cardImage1 from './../../assets/OurProgramsthehopeproject.jpg';
import cardImage2 from './../../assets/OurProgramstheaspireprojet.jpg';
import cardImage3 from './../../assets/OurProgramsempowerproject.jpg';
import cardImage4 from './../../assets/OurProgramsjoyproject.jpg';


import * as apiUrl from '../../apiUrl';
import axios from "axios";

SwiperCore.use([Navigation, Autoplay]);
class HomePrograms extends PureComponent {

  //constructor
  constructor(props) {
    super(props);

    //variables
    this.state = {

      section_title: "",
      section_description: "",
      section_description2: "",
      read_more_link: "",
      read_more_text: "",


      errors: {},


      data: {},
      programs: [],
    };



  }

  //componentDidMount
  async componentDidMount() {
    axios.post(apiUrl.apiUrl + "/home/edit_home_page_our_programs/1").then((res) =>
      this.setState({
        section_title: res.data[0].section_title,
        section_description: res.data[0].section_description,
        read_more_link: res.data[0].read_more_link,
        read_more_text: res.data[0].read_more_text,

        section_description2: res.data[0].section_description2,



      })
    );



    axios
      .post(apiUrl.apiUrl + "/programs/fetch_programs")
      .then((res) => {
        let programs = [];

        programs = res.data;
        this.setState({ programs });

        console.log(this.state.programs);

      });


  }


  render() {




    return (
      <Fragment>
        {/* Programs Section */}
        <section
          className="programs-sec lightGreenBG"
        >
          <Container fluid className="wrap">
            <div
              className="commonHeadingDiv"
            >
              <Row className="commonHeadingRow blackColor">
                <Col md={6}>
                  <h1 className="common-heading" data-aos='fade-up'>{this.state.section_title}</h1>
                  <p className="common-para" data-aos='fade-up'>
                    {this.state.section_description}
                  </p>

                </Col>
              </Row>
            </div>
          </Container>
          <Container fluid className="wrap programs-div OurProgramsSlidersDiv">

            <Row>
              <Col md={12}>
                <Swiper
                  className="OurProgramsSliders"
                  navigation={true}
                  spaceBetween={50}
                  slidesPerView={3.5}
                  loop={true}
                  autoplay={false}
                  draggable={true}
                  grabCursor={true}
                  breakpoints={{
                    // when window width is >= 640px
                    320: {
                      slidesPerView: 1,
                    },
                    420: {
                      slidesPerView: 1,
                    },
                    768: {
                      slidesPerView: 2,
                    },
                    1024: {
                      slidesPerView: 2.5,
                    },
                    1280: {
                      slidesPerView: 3.5,
                    }

                  }}
                >
                  {


                    this.state.programs.map((item, i) => (



                      <SwiperSlide key={"programs-card-" + i}>
                        <Link to={{ pathname: `/programs-inner/${item.slug}` }}>
                          <div className="programs-card" data-aos='fade-up'>
                            <div className="card-top-stripe">{item.main_title}</div>
                            <div className="card-img">
                              <img
                                src={apiUrl.apiUrl + '/mvd/ourPrograms/' + item.section_background_image}
                                className="img-fluid"
                                alt="cardimage"
                              />
                            </div>
                            <div className="card-content">
                              <h5 className="font-weight-bold">{item.section_title}
                              </h5>

                              <p dangerouslySetInnerHTML={{ __html: item.main_description }}
                              ></p>


                              <Button className="yellowColorBtn">Donate</Button>
                            </div>
                          </div>
                        </Link>
                      </SwiperSlide>

                    ))


                  }




                </Swiper>

              </Col>
            </Row>
            <Row className="getInvolvedButtonrow text-center pt-3">
              <Col md={12}>
                <p className="common-para" data-aos='fade-up'>
                  {this.state.section_description2}
                </p>

                <Link to={{ pathname: `/${this.state.read_more_link}` }}><Button className="yellowColorBtn">{this.state.read_more_text}</Button> </Link>
              </Col>
            </Row>
          </Container>
        </section>
      </Fragment >
    );
  }
}


export default HomePrograms