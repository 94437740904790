import React, { PureComponent, Fragment } from 'react';
import { Container, Col, Row } from 'bootstrap-4-react';   
class AmuthaValliDescription extends PureComponent {
    render() { 
        return (
          <Fragment>  
          <section className="media-inner-description-sec">
             
             <Container fluid className="wrap"> 
                 <Row>
                      <Col md={12}>
                          <div className="media-inner-des-smallhead"> 

                          <h6 className="media-inner-desc-heading" data-aos='fade-up'>Happiness begins with good health</h6>
                          <p className="common-para" data-aos='fade-right'>Amuthavalli lives with her husband and 2 daughters in the outskirts of Chennai. One day out of the blue, her elder daughter Sivasri was affected by a severe back ache. They tried to treat her in several different government hospitals, without any success. </p>
                          <p className="common-para" data-aos='fade-right'>Without a choice, they took her to a reputed private hospital, where they were informed that she needed an immediate spinal cord surgery. Even though the surgery was a success, her treatments had to continue. Amuthavalli had already pledged all her jewelry, including her Thali (Mangalsutra) for the hospital expenses. She felt helpless. </p>
                          <p className="common-para" data-aos='fade-right'>This was when the family heard about Rahul Charitable Trust. They met Jo, the founder, and told him all about Sivasri’s condition. Without any hesitation,  Jo ensured that Sivasri was given the financial aid they required for her treatment and medical expenses. </p>
                          <p className="common-para" data-aos='fade-right'>Now, Sivasri has recovered and is happy and healthy.  </p>
 


                           </div>  

                      </Col>
                 </Row>
               </Container>   
        </section>
     
          </Fragment>
        )
      }
}


export default AmuthaValliDescription