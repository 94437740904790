import React, { PureComponent, Fragment } from 'react';
import { Container } from 'bootstrap-4-react';
import * as apiUrl from '../../../apiUrl';
import axios from "axios";



class ProgramsInnerBanner extends PureComponent {


  constructor(props) {
    super(props);
    this.state = {
      main_title: "",
      main_description: "",
      slug: "",
      media_type: "",
      selectedFiles: undefined,
      currentFile: undefined,
      imageURL: "",
      section_title: "",
      section_title1: "",
      section_description1: "",
      section_title2: "",
      section_description2: "",


      errors: {},


    };



  }

  //componentDidMount
  async componentDidMount() {

    var slug = window.location.href.substring(window.location.href.lastIndexOf('/') + 1);
    axios.post(apiUrl.apiUrl + "/programs/edit_programs_by_slug/" + slug).then((res) =>
      this.setState({



        media_type: res.data[0].media_type,




        imageURL:
          apiUrl.apiUrl +
          "/mvd/ourPrograms/" +
          res.data[0].section_background_image,




      })
    );

    console.log(this.state);
  }





  render() {
    return (
      <Fragment>
        {/* Media Banner Section */}
        <Container fluid className="bannerSec programs-inner-banner" style={this.state.media_type == 'image' ? {
          backgroundImage: `url("` + this.state.imageURL + `")`
        } : {}}>
          <div className="overlay"></div>

          {this.state.media_type == 'video' ?
            <video src={this.state.imageURL} playsInline="playsInline" autoPlay="autoPlay" muted="muted" loop="loop">

            </video>
            : ''

          }
        </Container>
      </Fragment>
    )
  }
}


export default ProgramsInnerBanner