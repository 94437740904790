import React, { PureComponent, Fragment } from 'react';
import { Container } from 'bootstrap-4-react';
import * as apiUrl from '../../../apiUrl';
import axios from "axios";

class MediaInnerBanner extends PureComponent {


  //constructor
  constructor(props) {
    super(props);

    //member variables
    this.state = {
      main_title: "",
      main_description: "",
      slug: "",

      date: undefined,
      main_description2: "",
      media_type: "",
      selectedFiles: undefined,
      currentFile: undefined,
      imageURL: "",
      section_title1: "",
      section_description1: "",
      section_title2: "",
      section_description2: "",
      section_title3: "",
      section_description3: "",


      errors: {},



    };



  }

 

  //componentDidMount
  async componentDidMount() {


    this.updateUser();
    

  }

updateUser(){

  var id = window.location.href.substring(window.location.href.lastIndexOf('/') + 1);
    axios.post(apiUrl.apiUrl + "/news/edit_news_by_slug/" + id).then((res) =>
      this.setState({
        main_title: res.data[0].main_title,
        main_description: res.data[0].main_description,
        section_title1: res.data[0].section_title1,
        section_description1: res.data[0].section_description1,
        slug: res.data[0].slug,
        section_title2: res.data[0].section_title2,
        section_description2: res.data[0].section_description2,
        section_title3: res.data[0].section_title3,
        section_description3: res.data[0].section_description3,
        date: res.data[0].date,
        main_description2: res.data[0].main_description2,
        media_type: res.data[0].media_type,

        imageURL:
          apiUrl.apiUrl +
          "/mvd/LatestNews/" +
          res.data[0].section_background_image,




      })
    );

    console.log("imageURL : " + this.state.imageURL);
}

 

  render() {
    return (
      <Fragment>
        {/* Media Banner Section */}
        <Container fluid className="bannerSec media-inner-banner" style={this.state.media_type == 'image' ? {
          backgroundImage: `url("` + this.state.imageURL + `")`
        } : {}}>
          <div className="overlay"></div>


          {this.state.media_type == 'video' ?
            <video src={this.state.imageURL} playsInline="playsInline" autoPlay="autoPlay" muted="muted" loop="loop">

            </video>
            : ''

          }
        </Container>
      </Fragment>
    )
  }
}


export default MediaInnerBanner