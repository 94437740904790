import React, { PureComponent, Fragment } from 'react';
import 'bootstrap-4-react';
import Header from './../../common/header';
import Footer from './../../common/footer';
import AmuthaValliBanner from './amuthavalli-banner'; 
 import AmuthaValliDescription from './amuthavalli-description';
import AmuthavalliSmilarStories from './stories-inner-amuthavalli-smilarstories';   

class AmuthavalliPage extends PureComponent{

    render(){

        return(
            <Fragment> 
            <Header /> 
            <AmuthaValliBanner/>
            <AmuthaValliDescription />
            <AmuthavalliSmilarStories/>  
            <Footer />
        </Fragment>
        )

    }
}

export default AmuthavalliPage