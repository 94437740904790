
import React, { PureComponent, Fragment } from 'react';
import { Container, Col, Row, Button } from 'bootstrap-4-react';
import { Link } from 'react-router-dom';
import { Swiper, SwiperSlide } from 'swiper/react';

import SwiperCore, { Autoplay } from 'swiper';

// swiper core styles
import 'swiper/css';
import newsImage1 from './../../../assets/news1.png';
import newsImage2 from './../../../assets/news7.png';
import newsImage3 from './../../../assets/news3.png';

import * as apiUrl from '../../../apiUrl';
import axios from "axios";

SwiperCore.use([Autoplay]);
class MediaLatestNews extends PureComponent {

  convert(str) {
    var date = new Date(str),
      mnth = ("0" + (date.getMonth() + 1)).slice(-2),
      day = ("0" + date.getDate()).slice(-2);
    return [day, mnth, date.getFullYear()].join("-");
  }

  constructor(props) {
    super(props);
    this.state = {
      data: [],
      blogs: [],  
    };
  }

  componentDidMount() {

    let blogs = [];

    var id = window.location.href.substring(window.location.href.lastIndexOf('/') + 1);

    axios
      .post(apiUrl.apiUrl + "/news/fetch_news")
      .then((res) => {

        this.setState({ data: res.data });

        let dataxx = [];

        this.state.data.forEach((element) => {
          

          if(element.slug==id)
          {
            dataxx = element;
          }
          else
          {
            blogs.push(element);
          }
        
        });

        blogs.push(dataxx);


        this.setState({ blogs });

      })

  }


  render() {

    console.log(this.state.blogs);

    
    return (
      <Fragment>
        {/* Latestnews Section */}
        <section className="latestnews-sec lightGreenBG latest-news-inner">
          <Container fluid className="wrap greenColor">
            <div className="newsHeadingDiv">
              <Row className="newsHeadingRow">
                <Col md={6} className="">
                  <h1 className="common-heading" data-aos='fade-right'><span className="poppinsRegular">Latest</span> Blogs</h1>
                </Col>
                <Col md={6} className="buttonDIv">
                  <Link to={{ pathname:'/blogs'}}><Button className="greenColorBtn" data-aos='fade-left'>EXPLORE ALL</Button></Link>
                </Col>
              </Row>
            </div>
          </Container>
          <Container fluid className="wrap">
            <Row>
              <Col md={12}>
                <Swiper
                  className="newslatestSlider"
                  spaceBetween={50}
                  slidesPerView={3}
                  loop={false}
                  autoplay={false}
                  draggable={true}
                  breakpoints={{
                    // when window width is >= 640px
                    320: {
                      slidesPerView: 1,
                    },
                    420: {
                      slidesPerView: 1,
                    },
                    768: {
                      slidesPerView: 3,
                    }
                  }}
                >

                  {


                    this.state.blogs.map((item, i) => (
                      <SwiperSlide>
                        <a href={`/blogs-inner/${item.slug}`}>
                          <div className="latest-news" data-aos='fade-up'>
                            <div className="news-img">
                              <img src={apiUrl.apiUrl + '/mvd/LatestNews/' + item.section_background_image} className="img-fluid" alt="newsimage" />
                            </div>
                            <div className="news-content">
                              <Row className="newsDateRow">
                                <Col md={6}>
                                  <p className="text-uppercase">{item.main_title}</p>
                                </Col>
                                <Col md={6} className="newsDate">
                                  <p>{this.convert(item.date)}</p>
                                </Col>
                              </Row>
                              <h5 className="sub-heading">{item.main_description.substring(0, 50)}</h5>
                              <p>{item.main_description2.substring(0, 150)}</p>
                            </div>
                          </div>
                        </a>
                      </SwiperSlide>

                    ))


                  }


                </Swiper>
              </Col>
            </Row>
          </Container>
        </section>

      </Fragment>
    )
  }
}


export default MediaLatestNews