import React, { Fragment, PureComponent } from 'react';
import { Container, Col, Row } from 'bootstrap-4-react';
import { FaMapMarkerAlt, FaEnvelope, FaPhoneAlt } from "react-icons/fa";
import axios from 'axios';
import * as apiUrl from '../../apiUrl';
class OtherwaysContact extends PureComponent {
    constructor(props) {
        super(props);
        this.state = {
 
            contact_heading:"",
            contact_title: "",
            contact_address: "",
            mobile_title: "",
            mobile_number: "",
            email_title: "", 
            email: "",  

        }; 
    } 

    async componentDidMount() {

        axios.post(apiUrl.apiUrl + '/contact/edit_contact_page_address_section/1')
            .then(res =>

                this.setState({
                    contact_heading: res.data[0].contact_heading,
                    contact_title: res.data[0].contact_title,
                    contact_address: res.data[0].contact_address,
                    mobile_title: res.data[0].mobile_title,
                    mobile_number: res.data[0].mobile_number,
                    email_title: res.data[0].email_title,
                    email: res.data[0].email, 

                })

            ); 

    }

    render () {
        return (
            <Fragment>
                <section className="lightGreenBG other-ways-contact-sec">
                  <Container fluid className="wrap ">
                        <div className="contactHeadingDiv">
                            <Row className="contactHeadingRow">  
                                <Col md={12} className="text-center">   
                                    <h1 className="common-heading" data-aos='fade-up'>{this.state.contact_heading}</h1> 
                                </Col>   
                            </Row> 
                        </div> 

                        <div className="other-ways-contact-div">
                            <Row>
                                    <Col md={4}>
                                        <div className="other-ways" data-aos='fade-up'>
                                            <div className="circle-icon">
                                                <div className="icon">
                                                    <FaMapMarkerAlt />
                                                </div>
                                            </div>
                                            <div className="desc-div">
                                                <p>{this.state.contact_title}<br/>
                                                {this.state.contact_address}
                                                </p>
                                            </div>
                                        </div>
                                    </Col>
                                    <Col md={4}>
                                        <div className="other-ways" data-aos='fade-up'>
                                            <div className="circle-icon">
                                                <div className="icon">
                                                    <FaEnvelope />
                                                </div>
                                            </div>
                                            <div className="desc-div">
                                                <p>{this.state.email_title} <a href={'mailto:'+this.state.email}>{this.state.email}</a></p>
                                            </div>
                                        </div>
                                    </Col>
                                    <Col md={4}>
                                        <div className="other-ways" data-aos='fade-up'>
                                            <div className="circle-icon">
                                                <div className="icon">
                                                    <FaPhoneAlt />
                                                </div>
                                            </div>
                                            <div className="desc-div desc-mobile-div">
                                                <p>{this.state.mobile_title} <a href={'tel:'+this.state.mobile_number} >{this.state.mobile_number}</a></p>
                                            </div>
                                        </div>
                                    </Col>
                            </Row>
                        </div>
                    </Container>
                </section>
            </Fragment>
        )
    }
}

export default OtherwaysContact