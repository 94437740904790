import React, { PureComponent, Fragment } from "react";
import { Container, Row, Col } from "bootstrap-4-react";

import * as apiUrl from '../../apiUrl';
import axios from "axios";

// donation section
class DonationSection extends PureComponent {

  //constructor
  constructor(props) {
    super(props);

    //variables
    this.state = {
      media_type: "",
      selectedFiles: undefined,
      currentFile: undefined,
      section_title: "",
      section_description: "",

      errors: {},
      imageURL: "",
      data: {

      },





    };









  }

  //componentDidMount
  componentDidMount() {

    axios.post(apiUrl.apiUrl + '/about/edit_about_page_mission_section/1')
      .then(res =>

        this.setState({
          section_title: res.data[0].section_title,
          section_description: res.data[0].section_description,


          media_type: res.data[0].media_type,



          imageURL: apiUrl.apiUrl + "/mvd/AboutPageMissionSection/" + res.data[0].section_background_image,

        })

      );




  }


  render() {

    let section_description = this.state.section_description.split("\n");

    return (
      <Fragment>
        <section className="donation-section">
          <Container fluid className="about-donate-top-div">
            <Row>
              <Col md={5} className="d-flex justify-content-center">
                <div className="donation-section-description">



                  <p className="common-para" data-aos='fade-right'>  {


                    section_description.map((item, i) => (

                      <span key={"desc2_" + i}>
                        {item}<br />
                      </span>

                    ))
                  }</p>

                </div>
              </Col>
              <Col md={7} className="d-flex justify-content-center">
                <div className="donor-heart-image" data-aos='fade-left'>


                  {this.state.media_type == 'video' ?
                    <video src={this.state.imageURL} playsInline="playsInline" autoPlay="autoPlay" muted="muted" loop="loop">

                    </video>
                    : <img src={this.state.imageURL} alt="" className="img-fluid" />

                  }
                </div>
              </Col>
            </Row>
          </Container>

        </section>
      </Fragment>
    );
  }
}

export default DonationSection;
