import React, {Fragment, PureComponent} from 'react';
import Header from './../common/header';
import Footer from './../common/footer';
import PrivacyPolicyBanner from './privacy-policy-banner';
import PrivacypolicyDescription from './privacy-policy-descriptions';
import * as apiUrl from '../../apiUrl';
import axios from "axios";
import { Helmet } from "react-helmet";

class PrivacypolicyPage extends PureComponent {

    constructor(props) {
        super(props);
        this.state = {

            privacy_meta_title: "",
            privacy_meta_description: "",
        }
    }

    componentDidMount() {

        axios.post(apiUrl.apiUrl + "/seometa/edit_seo_meta/1").then((res) => {
            this.setState({
                privacy_meta_title: res.data[0].privacy_meta_title,
                privacy_meta_description: res.data[0].privacy_meta_description,


            })


        }
        );


    }


    render () {
        return (
            <Fragment>
                <Header/>

                <Helmet>
                    <title>{this.state.privacy_meta_title}</title>
                    <meta name="description" content={this.state.privacy_meta_description} />
                </Helmet>
                <PrivacyPolicyBanner />
                <PrivacypolicyDescription />
                <Footer/>
            </Fragment>
        )
    }
}
export default PrivacypolicyPage