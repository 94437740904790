import React, { PureComponent, Fragment } from 'react';
import 'bootstrap-4-react';
import Header from './../common/header';
import Footer from './../common/footer';
import NewsBanner from './news-banner'; 
import NewsList from './news-list';   
import * as apiUrl from '../../apiUrl';
import axios from "axios";
import { Helmet } from "react-helmet";

class NewsPage extends PureComponent{

    constructor(props) {
        super(props);
        this.state = {
          
            programs_meta_title:"",
            programs_meta_description:"",
        }
      }
    
      componentDidMount() { 
    
        axios.post(apiUrl.apiUrl + "/seometa/edit_seo_meta/1").then((res) => {
          this.setState({
            programs_meta_title: res.data[0].programs_meta_title,
            programs_meta_description: res.data[0].programs_meta_description,
    
      
          })
    
    
      }
      );
    
    
    }

    render(){

        return(
            <Fragment> 
                
            <Header /> 

            <Helmet> 
                <title>{this.state.programs_meta_title}</title>
                <meta name="description" content={this.state.programs_meta_description} />
            </Helmet> 


            <NewsBanner/>
            <NewsList/>  
            <Footer />
        </Fragment>
        )

    }
}

export default NewsPage