import React, { PureComponent, Fragment } from 'react';
import { Container, Col, Row, Button } from 'bootstrap-4-react';
import { Link } from 'react-router-dom';
import aboutimage from './../../assets/Impact.jpg';
import rahulImage from  './../../assets/Rahul-01.svg';
import * as apiUrl from '../../apiUrl';
import axios from "axios";

class HomeAbout extends PureComponent {

  //constructor
  constructor(props) {
    super(props);

    //variables
    this.state = {

      section_description: "",
      section_title: "",
      section_description2: "",
      know_more_text: "",
      know_more_link: "",
      media_type: "",
      selectedFiles: undefined,
      currentFile: undefined,
      errors: {},
      imageURL: "",
      media_type1: "",
      selectedFiles1: undefined,
      currentFile1: undefined,
      imageURL1: "",
      data: {},
      stories_title: "",
      stories_description: "",

      know_more_text2: "",
      know_more_link2: "",

    };


  }

  //componentDidMount
  async componentDidMount() {
    axios.post(apiUrl.apiUrl + "/home/edit_home_page_second_section/1").then((res) =>
      this.setState({






        section_description: res.data[0].section_description,
        section_title: res.data[0].section_title,
        section_description2: res.data[0].section_description2,
        know_more_text: res.data[0].know_more_text,
        know_more_link: res.data[0].know_more_link,
        media_type: res.data[0].media_type,
        media_type1: res.data[0].media_type1,

        stories_title: res.data[0].stories_title,
        stories_description: res.data[0].stories_description,

        imageURL:
          apiUrl.apiUrl +
          "/mvd/HomePageSecondSection/" +
          res.data[0].section_background_image,

        imageURL1:
          apiUrl.apiUrl +
          "/mvd/HomePageSecondSection/" +
          res.data[0].discover_image,

        know_more_text2: res.data[0].know_more_text2,
        know_more_link2: res.data[0].know_more_link2,
      })
    );

    console.log(this.state);
  }

  render() {

    let section_title = this.state.section_title.split(" ");

    let stories_title = this.state.stories_title.split(" ");

    return (
      <Fragment>

        <section className="aboutus-sec">
          <Container fluid className="wrap">
            <div className="aboutHeadingDiv">
              <Row className="aboutHeadingRow text-center">
                <Col md={9}>
                  <img src={rahulImage} className="img-fluid" alt="rahulImage1"  data-aos='fade-up' />
                  <p className="common-para" data-aos='fade-left'>  {this.state.section_description}</p>
                  <h1 className="common-heading" data-aos='fade-right'>
                    <span>
                      {


                        section_title.map((item, i) => (
                          (
                            i == 6 || i == 7 ?
                              <span key={"section_title_home_about_" + i}>
                                {item} &nbsp;
                              </span> : <span key={"section_title_home_about_" + i}>
                                {item} &nbsp;
                              </span>


                          )

                        ))


                      }

                    </span>


                  </h1>

                  <p className="common-para" data-aos='fade-left'> {this.state.section_description2}
                  </p>
                  <Link to={{ pathname: `/${this.state.know_more_link}` }}><Button className="yellowColorBtn">{this.state.know_more_text}</Button> </Link>
                </Col>
              </Row>
            </div>
          </Container>

          <Container fluid className="aboutBottomDiv wrap">
            <Row>

              <Col md={6} className="padding-zero" data-aos='fade-right'>
                <div className="overlay"></div>
                <div className="about-newbgimg-div">

                {this.state.media_type == 'video' ?
                        <video src={this.state.imageURL} playsInline="playsInline" autoPlay="autoPlay" muted="muted" loop="loop">

                        </video>
                        :  <img src={this.state.imageURL} className="img-fluid" alt="about-image" />

                    }

                 

                </div>
              </Col>
              <Col md={6} className="padding-zero about-image-bg" data-aos='fade-left' style={this.state.media_type == 'image' ? {
                    backgroundImage: `url("` + this.state.imageURL1 + `")`
                } : {}}>
                <div className="overlay"></div>

                {this.state.media_type == 'video' ?
                        <video src={this.state.imageURL} playsInline="playsInline" autoPlay="autoPlay" muted="muted" loop="loop">

                        </video>
                        : ''

                    }
                <div className="stories-change-div whiteColor">
                  <h3>

                   
                    <span>
                      {


                        stories_title.map((item, i) => (
                          (
                            i == 2 ?
                              <span key={"section_title_home_about_stories_" + i}>
                                {item} &nbsp;
                              </span> : <span key={"section_title_home_about_stories_" + i}>
                                {item} &nbsp;
                              </span>


                          )

                        ))


                      }

                    </span>

                  </h3>
                  <p>{this.state.stories_description}</p>
                  <Link to={{ pathname: `/${this.state.know_more_link2}` }}><Button className="yellowColorBtn">{this.state.know_more_text2}</Button> </Link>
                </div>
              </Col>
            </Row>
          </Container>
        </section>
      </Fragment>
    )
  }
}


export default HomeAbout


