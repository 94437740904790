import React, { PureComponent, Fragment } from 'react';
import 'bootstrap-4-react';
import Header from './../../common/header';
import Footer from './../../common/footer';
import MediaInnerBanner from './media-inner-banner'; 
 import MediaInnerDescription from './media-inner-description';
import MediaLatestNews from './media-inner-latestnews';   

class MediaInnerPage extends PureComponent{

    render(){

        return(
            <Fragment> 
            <Header /> 
            <MediaInnerBanner/>
            <MediaInnerDescription />
            <MediaLatestNews/>  
            <Footer />
        </Fragment>
        )

    }
}

export default MediaInnerPage