import React, { PureComponent, Fragment } from 'react';
import { Container, Col, Row } from 'bootstrap-4-react';   
class TheEmpowerProjectDescription extends PureComponent {
    render() { 
        return (
          <Fragment>  
         <section className="media-inner-description-sec">
             
                  <Container fluid className="wrap"> 
                      <Row>
                           <Col md={12}>
                               <div className="media-inner-des-smallhead"> 


                               <h6 className="media-inner-desc-heading" data-aos='fade-up'> Why we need to help:</h6>
 
                               <p className="common-para" data-aos='fade-right'>The most transformative way to promote women’s empowerment and equality is through decent employment and social protection. Providing women with adequate opportunities to work will pave the way towards fair income opportunities, security in the workplace, and better prospects for personal and social development.</p>
 
                               <p className="common-para" data-aos='fade-right'>An increased disposable income would also have a positive impact on the entire family, especially in important matters like education for the children, nutrition, medical treatments, and even social activities. The employment of women would lessen their economic marginalization and subsequently help them claim their right to education, development of skills and ultimately a way to realize their dreams.  </p>
 
 
                               <h6 className="media-inner-desc-heading" data-aos='fade-up'>How we help:</h6>
                               <p className="common-para" data-aos='fade-right'>Through this initiative, we identify unemployed women, train them, and impart skills necessary for their employment. We then get them connected with businesses and income generation opportunities which would help them earn a living wage.</p>
 
                               <p className="common-para" data-aos='fade-right'>By enabling women to achieve the socio-economic freedom that they rightfully deserve, they can have an autonomous and complete human experience.</p>

                                   
  




                                </div>  
 
                           </Col>
                      </Row>
                    </Container>   
             </section>
          
          </Fragment>
        )
      }
}


export default TheEmpowerProjectDescription