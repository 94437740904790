import React, { PureComponent, Fragment } from 'react';
import { Container, Col, Row } from 'bootstrap-4-react';
// import { Link } from 'react-router-dom';  
import Storyleftimage from './../../assets/group-indian-children-running.jpg';
import * as apiUrl from '../../apiUrl';
import axios from "axios";

class DonateDescriptions extends PureComponent {

    //constructor
    constructor(props) {
        super(props);

        //variables
        this.state = {
            section_title: "",
            section_description: "",
            selectedFiles: undefined,
            currentFile: undefined,

            errors: {},
            imageURL: "",

            section_title2: "",
            section_description2: "",

            data: {},



            section_title1: "",
            section_description1: "",



            imageURL1: "",


        };


    }

    //componentDidMount
    componentDidMount() {
        axios.post(apiUrl.apiUrl + "/donate/edit_donate_page_second_section/1").then((res) =>
            this.setState({


                section_title: res.data[0].section_title,
                section_description: res.data[0].section_description,
                imageURL:
                    apiUrl.apiUrl +
                    "/mvd/DonatePageSecondSection/" +
                    res.data[0].section_background_image,

                section_title2: res.data[0].section_title2,
                section_description2: res.data[0].section_description2,
            })
        );

        console.log(this.state);

        axios.post(apiUrl.apiUrl + "/donate/edit_donate_page_third_section/1").then((res) =>
            this.setState({


                section_title1: res.data[0].section_title,
                section_description1: res.data[0].section_description,
                imageURL1:
                    apiUrl.apiUrl +
                    "/mvd/DonatePageThirdSection/" +
                    res.data[0].section_background_image,


            })
        );
    }

    render() {

        let section_description1 = this.state.section_description1.split(" ");

        return (
            <Fragment>
                <section className="stories-inner-description-sec donate-page-desc">
                    {/* Donate-top-description div */}
                    <Container className="story-left-image story-left-image1">
                        <Row className="border-bottom-div">
                            <Col md={12}>
                                <div className="stories-des-text">
                                    <h4 className="fonsecaBold" data-aos='fade-right'>{this.state.section_title}</h4>
                                    <p data-aos='fade-left'>{this.state.section_description}  </p>
                                </div>
                            </Col>
                        </Row>
                    </Container>
                    {/* Donate-description div */}
                    <Container className="story-left-image story-left-image donatedescription-container">
                        <Row>
                            <Col lg={7}>
                                <div className="stories-left-image" data-aos='fade-right'>
                                    <img src={this.state.imageURL} className="img-fluid" alt="donateimage" />
                                </div>
                            </Col>
                            <Col lg={5} className="stories-right-col">
                                <div className="stories-right-content">
                                    <h4 className="fonsecaBold" data-aos='fade-left'>{this.state.section_title2}</h4>
                                    <p data-aos='fade-left'>{this.state.section_description2} </p>

                                </div>
                            </Col>
                        </Row>
                    </Container>


                    <Container fluid className="parallaxBG donate-parallax" style={{
                        backgroundImage: `url("` + this.state.imageURL1 + `")`
                    }}>
                        <div className="overlay"></div>
                        <div className="donate-parallax-container">
                            <Row className=" ">
                                <Col lg={6}>
                                    <div className="donate-parallax-content">

                                        <h5 className="fonsecaBold" data-aos='fade-right'> {this.state.section_title1}</h5>
                                        <p data-aos='fade-right'>

                                          

                                            <span>
                                                {


                                                    section_description1.map((item, i) => (
                                                        (
                                                            <span key={"section_description1_" + i}>
                                                                {item} &nbsp;
                                                            </span>


                                                        )

                                                    ))


                                                }

                                            </span>

                                        </p>

                                    </div>

                                </Col>
                            </Row>
                        </div>

                    </Container>

                </section>

            </Fragment>
        )
    }
}


export default DonateDescriptions