import React, { PureComponent, Fragment } from 'react';
import { Container, Col, Row, Button } from 'bootstrap-4-react';
// import { Pagination } from 'react-bootstrap';
import { Link } from 'react-router-dom';
// import {FaArrowRight}  from "react-icons/fa";
import storiesImage1 from './../../assets/Muthuselvi.JPG';
import storiesImage2 from './../../assets/amudha.JPG';
import storiesImage3 from './../../assets/lokeshwari.jpg';
// import storiesImage3 from './../../assets/poor-african-american-child.jpg';   
// import storiesImage4 from './../../assets/cheerful-african-kid.jpg'; 
// import storiesImage5 from './../../assets/young-child.jpg'; 
// import storiesImage6 from './../../assets/happy-rural-indian-girl.jpg';   
import * as apiUrl from '../../apiUrl';
import axios from "axios";

class StoriesLists extends PureComponent {
    //constructor
    constructor(props) {
        super(props);

        //variables
        this.state = {

            section_title: "",
            section_description: "",



            errors: {},


            data: {},
            stories: [],
        };



    }

    //componentDidMount
    async componentDidMount() {
        axios.post(apiUrl.apiUrl + "/stories/edit_stories_page_second_section/1").then((res) =>
            this.setState({


                section_title: res.data[0].section_title,
                section_description: res.data[0].section_description,



            })
        );



        axios
            .post(apiUrl.apiUrl + "/stories/fetch_stories")
            .then((res) => {
                let stories = [];

                stories = res.data;
                this.setState({ stories });

                console.log(this.state.stories);

            });


    }
    render() {
        return (
            <Fragment>
                <section className="stories-list-sec">
                    <Container fluid className="wrap stories-row-div">
                        <div className="commonHeadingDiv">
                            <Row className="commonHeadingRow text-center blackColor">
                                <Col md={9}>
                                    <h1 className="common-heading" data-aos='fade-right'>{this.state.section_title}</h1>
                                    <p className="common-para" data-aos='fade-left'> {this.state.section_description}</p>
                                </Col>
                            </Row>
                        </div>
                        <Row className="d-flex justify-content-center">
                            {
                                this.state.stories.map((item, i) => (
                                    <>

                                        <Col md={4}>
                                            <Link to={{ pathname: `/stories-inner/${item.slug}` }}>
                                                <div className="our-stories" data-aos='fade-up'>
                                                    <div className="stories-img">
                                                        <img src={apiUrl.apiUrl + '/mvd/ourStoriesPost/' + item.section_background_image} className="img-fluid" alt="storiesimage" />
                                                    </div>
                                                    <div className="stories-content">
                                                        <h5 className="sub-heading">{item.main_title}</h5>
                                                        <p>{item.main_description.substring(0, 200)} ... </p>
                                                        <Link to={{ pathname: `/stories-inner/${item.slug}` }}><Button className="bottomBorder">Know More</Button>  </Link>
                                                    </div>
                                                </div>
                                            </Link>
                                        </Col>

                                    </>
                                ))

                            }



                            {/* <Col md={4}>
                              <Link to="/stories-inner-lokeshwari">
                                  <div className="our-stories" data-aos='fade-up'>
                                      <div className="stories-img">
                                        <img src={storiesImage3} className="img-fluid" alt="storiesimage" /> 
                                      </div>
                                      <div className="stories-content"> 
                                          <h5 className="sub-heading">Listening and hearing are two different senses</h5>    
    <p>Lokeswari, the elder of two sisters, was born with a hearing impairment. When she was just a little girl, she was examined by a medical professional.</p>
<Link to="/stories-inner-lokeshwari"><Button className="bottomBorder">Know More</Button>  </Link>
                                      </div>
                                  </div>
                              </Link>
                          </Col>

                          <Col md={4}>
                              <Link to="/stories-inner-amuthavalli">
                                  <div className="our-stories" data-aos='fade-up'>
                                      <div className="stories-img">
                                        <img src={storiesImage2} className="img-fluid" alt="storiesimage" /> 
                                      </div>
                                      <div className="stories-content"> 
                                          <h5 className="sub-heading">Happiness begins with good health</h5>    
    <p>Amuthavalli lives with her husband and 2 daughters in the outskirts of Chennai. One day out of the blue, her elder daughter Sivasri was affected by a severe back ache.</p>
<Link to="/stories-inner-amuthavalli"><Button className="bottomBorder">Know More</Button>  </Link>
                                      </div>
                                  </div>
                              </Link>
                          </Col>
                          
                          <Col md={4}>
                            <Link to="/stories-inner-muthuselvi">
                                  <div className="our-stories" data-aos='fade-up'> 
                                      <div className="stories-img">
                                        <img src={storiesImage1} className="img-fluid" alt="storiesimage" /> 
                                      </div>
                                      <div className="stories-content"> 
                                          <h5 className="sub-heading " >College is but a dream</h5>    
    <p>This is Muthuselvi. She has always been determined to pursue her higher education. She did not give up even when she didn’t meet the academic criteria to join a government college. She got into a private college. </p>
<Link to="/stories-inner-muthuselvi"><Button className="bottomBorder">Know More</Button>  </Link>
                                      </div>
                                  </div>
                              </Link>
                          </Col> */}
                            {/* <Col md={4}>
                              <Link to="/stories-inner">
                                  <div className="our-stories" data-aos='fade-up'> 
                                      <div className="stories-img">
                                        <img src={storiesImage3} className="img-fluid" alt="storiesimage" /> 
                                      </div>
                                      <div className="stories-content"> 
                                          <h5 className="sub-heading">Aenean commodo ligula
eget dolor. Aenean massa.</h5>   
    <p className="font-weight-bold">04.06.2022</p>
    <p>Lorem ipsum dolor sit amet, consectetuer
adipiscing elit. Aenean commodo ligula
eget dolor. Aenean massa</p>
<Link to="/stories-inner"><Button className="bottomBorder">Know More</Button>  </Link>
                                      </div>
                                  </div>
                              </Link>
                          </Col>   
                          <Col md={4}>
                            <Link to="/stories-inner">
                                  <div className="our-stories" data-aos='fade-up'> 
                                      <div className="stories-img">
                                        <img src={storiesImage4} className="img-fluid" alt="storiesimage" /> 
                                      </div>
                                      <div className="stories-content"> 
                                          <h5 className="sub-heading">Aenean commodo ligula
eget dolor. Aenean massa.</h5>   
    <p className="font-weight-bold">04.06.2022</p>
    <p>Lorem ipsum dolor sit amet, consectetuer
adipiscing elit. Aenean commodo ligula
eget dolor. Aenean massa</p>
<Link to="/stories-inner"><Button className="bottomBorder">Know More</Button>  </Link>
                                      </div>
                                  </div>
                              </Link>
                          </Col>
                          <Col md={4}>
                              <Link to="/stories-inner">
                                  <div className="our-stories" data-aos='fade-up'>
                                      <div className="stories-img">
                                        <img src={storiesImage5} className="img-fluid" alt="storiesimage" /> 
                                      </div>
                                      <div className="stories-content"> 
                                          <h5 className="sub-heading">Aenean commodo ligula
eget dolor. Aenean massa.</h5>   
    <p className="font-weight-bold">04.06.2022</p>
    <p>Lorem ipsum dolor sit amet, consectetuer
adipiscing elit. Aenean commodo ligula
eget dolor. Aenean massa</p>
<Link to="/stories-inner"><Button className="bottomBorder">Know More</Button>  </Link>
                                      </div>
                                  </div>
                              </Link>
                          </Col>
                          <Col md={4}>
                              <Link to="/stories-inner" >
                                  <div className="our-stories" data-aos='fade-up'> 
                                      <div className="stories-img">
                                        <img src={storiesImage6} className="img-fluid" alt="storiesimage" /> 
                                      </div>
                                      <div className="stories-content"> 
                                          <h5 className="sub-heading">Aenean commodo ligula
eget dolor. Aenean massa.</h5>   
    <p className="font-weight-bold">04.06.2022</p>
    <p>Lorem ipsum dolor sit amet, consectetuer
adipiscing elit. Aenean commodo ligula
eget dolor. Aenean massa</p>
<Button className="bottomBorder">Know More</Button>  
                                      </div>
                                  </div>
                              </Link>
                          </Col>   */}
                        </Row>
                    </Container>
                    {/* <Container fluid>
                        <Row>
                            <Col md={12}>
                                <Pagination className="justify-content-center">  
                                    <Pagination.Item active>{1}</Pagination.Item> 
                                    <Pagination.Item>{2}</Pagination.Item>
                                    <Pagination.Item>{3}</Pagination.Item> 
                                    <Pagination.Ellipsis />
                                    <Pagination.Item>{7}</Pagination.Item>
                                    <Pagination.Next> NEXT <FaArrowRight/></Pagination.Next> 
                                </Pagination>
                            </Col>
                        </Row>
                        
                    </Container>  */}
                </section>

            </Fragment>
        )
    }
}


export default StoriesLists