import React, { PureComponent, Fragment } from 'react';
import { Container, Col, Row, Button } from 'bootstrap-4-react';
import Form from 'react-bootstrap/Form';
import axios from "axios";
import Logo from './../../assets/RCT Horizontal Logo.gif';
import * as apiUrl from '../../apiUrl';
import ReCAPTCHA from "react-google-recaptcha";

class DonateBanner extends PureComponent {

  constructor(props) {
    super(props);
    this.state = {
      amount: "1000",

      fname: "",
      lname: "",
      email: "",
      mobile: "",
      errors: {},
      section_title: "",
      section_description: "",


      errors: {},


      data: {},
    }


    this.displayRazorpay = this.displayRazorpay.bind(this, true);



  }

  onChange(value) {



    window.localStorage.setItem('DonateCaptcha', value);




    let errors = this.state.errors;
    errors["Captcha"] = "";

    this.setState({ errors: errors });


  }




  //amount
  onChangeAmount = event => {

    this.setState({ amount: event.target.value });

    let errors = this.state.errors;
    errors["amount"] = "";

    this.setState({ errors: errors });


  }

  //amount
  onChangeFname = event => {

    this.setState({ fname: event.target.value });

    let errors = this.state.errors;
    errors["fname"] = "";

    this.setState({ errors: errors });
  }

  //amount
  onChangeLname = event => {

    this.setState({ lname: event.target.value });

    let errors = this.state.errors;
    errors["lname"] = "";

    this.setState({ errors: errors });


  }

  //email
  onChangeEmail = event => {

    this.setState({ email: event.target.value });

    let errors = this.state.errors;
    errors["email"] = "";

    this.setState({ errors: errors });


  }

  //amount
  onChangeMobile = event => {

    this.setState({ mobile: event.target.value });

    let errors = this.state.errors;
    errors["mobile"] = "";

    this.setState({ errors: errors });


  }

  handleValidation() {




    let amount = this.state.amount;
    let fname = this.state.fname;

    let lname = this.state.lname;
    let email = this.state.email;
    let mobile = this.state.mobile;

    let errors = {};
    let formIsValid = true;

    const regex = /^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i;



    let token = window.localStorage.getItem('DonateCaptcha');




    if (token === null || token === 'null') {
      formIsValid = false;
      errors["Captcha"] = "*Please Select The Captcha";
    }

    if (!this.state.email || regex.test(this.state.email) === false) {

      errors['email'] = "Email is not valid";
      this.setState({
        errors: errors
      });
      formIsValid = false;
    }

    if (amount == "") {
      formIsValid = false;
      errors["amount"] = "Please Enter The Amount";
    }

    if (fname == "") {
      formIsValid = false;
      errors["fname"] = "Please Enter The First Name";
    }

    if (lname == "") {
      formIsValid = false;
      errors["lname"] = "Please Enter The Last Name";
    }

    if (email == "") {
      formIsValid = false;
      errors["email"] = "Please Enter The Email Address";
    }

    if (mobile == "") {
      formIsValid = false;
      errors["mobile"] = "Please Enter The Mobile Number";
    }

    this.setState({ errors: errors });
    return formIsValid;
  }

  validate = evt => {
    var theEvent = evt || window.event;

    // Handle paste
    if (theEvent.type === 'paste') {
      key = evt.clipboardData.getData('text/plain');
    } else {
      // Handle key press
      var key = theEvent.keyCode || theEvent.which;
      key = String.fromCharCode(key);
    }
    var regex = /[0-9]|\./;
    if (!regex.test(key)) {
      theEvent.returnValue = false;
      if (theEvent.preventDefault) theEvent.preventDefault();
    }
  }

  //componentDidMount
  componentDidMount() {
    axios.post(apiUrl.apiUrl + "/donate/edit_donate_page_banner_section/1").then((res) =>
      this.setState({


        section_title: res.data[0].section_title,
        section_description: res.data[0].section_description,
        imageURL:
          apiUrl.apiUrl +
          "/mvd/DonatePageBannerSection/" +
          res.data[0].section_background_image,
        media_type: res.data[0].media_type,

      })
    );

    console.log(this.state);
  }

  async displayRazorpay() {

    if (this.handleValidation()) {

      var src = "https://checkout.razorpay.com/v1/checkout.js";


      const res = new Promise((resolve) => {
        const script = document.createElement("script");
        script.src = src;
        script.onload = () => {
          resolve(true);
        };
        script.onerror = () => {
          resolve(false);
        };
        document.body.appendChild(script);
      });

      if (!res) {
        alert("Razorpay SDK failed to load. Are you online?");
        return;
      }

      var self = this;

      let token = window.localStorage.getItem('DonateCaptcha');

      const result = await axios.post(apiUrl.apiUrl + "/payment/orders", { amount: this.state.amount * 100, fname: self.state.fname, lname: self.state.lname, email: self.state.email, mobile: self.state.mobile, captcha: token });

      if (!result) {
        alert("Server error. Are you online?");
        return;
      }

      const { amount: amount, order_id: order_id, currency } = result.data;



      const options = {
        key: "rzp_live_3ExgbNxv2rmgSq", // Enter the Key ID generated from the Dashboard
        amount: amount.toString(),
        currency: currency,

        description: "Towards RCT Donation",
        image: { Logo },
        order_id: order_id,
        handler: async function (response) {
          const data = {
            orderCreationId: order_id,
            razorpayPaymentId: response.razorpay_payment_id,
            razorpayOrderId: response.razorpay_order_id,
            razorpaySignature: response.razorpay_signature,
          };

          const result = await axios.post(apiUrl.apiUrl + "/payment/success", data);

          console.log(result);

          if (result.data.msg == 'success') {
        
            window.grecaptcha.reset();

            window.localStorage.removeItem("DonateCaptcha");



            self.setState({ amount: '1000' });
            self.setState({ fname: '' });
            self.setState({ lname: '' });
            self.setState({ email: '' });
            self.setState({ mobile: '' });
            document.getElementById("myModal").style.display = "block";
          }
        },
        prefill: {
          name: self.state.fname + " " + self.state.lname,
          email: self.state.email,
          contact: self.state.mobile,
        },

        theme: {
          color: "#61dafb",
        },
      };

      const paymentObject = new window.Razorpay(options);
      paymentObject.open();

    }
  }

  onlyAlphabets = evt => {
    try {
      if (window.event) {
        var charCode = window.event.keyCode;
      }
      else if (evt) {
        var charCode = evt.which;
      }
      else { return true; }
      if ((charCode > 64 && charCode < 91) || (charCode > 96 && charCode < 123))
        return true;
      else
        evt.preventDefault();;
    }
    catch (err) {
      alert(err.Description);
    }
  }

  closeModalDialog() {
    document.getElementById("myModal").style.display = "none";
  }

  render() {
    return (
      <Fragment>
        {/* Donate Banner Section */}
        <Container fluid className="bannerSec donateBanner" style={this.state.media_type == 'image' ? {
          backgroundImage: `url("` + this.state.imageURL + `")`
        } : {}}>
          <div className="overlay"></div>

          {this.state.media_type == 'video' ?
            <video src={this.state.imageURL} playsInline="playsInline" autoPlay="autoPlay" muted="muted" loop="loop">

            </video>
            : ''

          }
          <div className="bannerHeight wrap">
            <Container fluid className="donate-container">
              <Row>
                <Col lg={5} className="banner-card-col">
                  <div className="banner-card" data-aos='fade-up'>
                    <h6 className="fonsecaBold text-uppercase">DONATE NOW</h6>
                    <p>Choose an amount to give</p>
                    <div className="donate-form">
                      <Form>
                        <Form.Group className="donateForm">
                          <span className="give-currency-symbol">₹</span>
                          <Form.Control className="donateText" type="text" onKeyPress={this.validate} value={this.state.amount} onChange={this.onChangeAmount} />


                          <span className="form-error">
                            {" "}
                            {this.state.errors["amount"]}{" "}
                          </span>
                          <br /> <br />
                        </Form.Group>
                      </Form>
                      <ul className="amounts-list">
                        <li><Button onClick={() => this.setState({ amount: '1000' })}>₹ 1000</Button></li>
                        <li><Button onClick={() => this.setState({ amount: '2000' })}>₹ 2000</Button></li>
                        <li><Button onClick={() => this.setState({ amount: '5000' })}>₹ 5000</Button></li>
                        <li><Button onClick={() => this.setState({ amount: '' })}>Custom Amount</Button></li>
                      </ul>
                    </div>
                    <div className="personal-info-div donate-banner-form-div">
                      <div className="personal-info-form row">
                        <div className="form-group col-md-6">
                          <input type="text" className="form-control" placeholder="First Name" id="fname" onKeyPress={this.onlyAlphabets} onChange={this.onChangeFname} value={this.state.fname} />


                          <span className="form-error">
                            {" "}
                            {this.state.errors["fname"]}{" "}
                          </span>
                        </div>
                        <div className="form-group col-md-6">
                          <input type="text" className="form-control" placeholder="Last Name" id="lname" onKeyPress={this.onlyAlphabets} onChange={this.onChangeLname} value={this.state.lname} />

                          <span className="form-error">
                            {" "}
                            {this.state.errors["lname"]}{" "}
                          </span>
                        </div>
                      </div>
                      <div className="personal-info-form row">
                        <div className="form-group col-md-6">
                          <input type="text" className="form-control" placeholder="Phone" id="phone" onKeyPress={this.validate} onChange={this.onChangeMobile} value={this.state.mobile} />


                          <span className="form-error">
                            {" "}
                            {this.state.errors["mobile"]}{" "}
                          </span>
                        </div>
                        <div className="form-group col-md-6">
                          <input type="text" className="form-control" placeholder="Email" id="email" onChange={this.onChangeEmail} value={this.state.email} />


                          <span className="form-error">
                            {" "}
                            {this.state.errors["email"]}{" "}
                          </span>
                        </div>

                        <div className="form-group col-md-6">

                          <ReCAPTCHA
                            sitekey="6LdUt3QgAAAAABa0w8ctOTdSBSqQWop2WIoVHJkm"
                            onChange={this.onChange}


                          />
                          <span className="form-error">{this.state.errors["Captcha"]}</span>
                        </div>
                      </div>
                    </div>
                    <Button className="yellowColorBtn" onClick={this.displayRazorpay}>Donate</Button>
                  </div>

                </Col>
              </Row>
            </Container>
          </div>
        </Container>


        <Container>
          <Row>
            <div
              className="modal form-modal "
              tabIndex="-1"
              role="dialog"
              id="myModal"
            >
              <div className="modal-dialog" role="document">
                <div className="modal-content">
                  <div className="modal-header">
                    <button
                      type="button"
                      className="close"
                      onClick={this.closeModalDialog}
                      data-dismiss="modal"
                      aria-label="Close"
                    >
                      <span aria-hidden="true">&times;</span>
                    </button>
                  </div>
                  <div className="modal-body">
                    <h2 className="canelaMedium secHeading">
                      Thank you for your contribution to help us fulfil our mission.
                    </h2>
                  </div>
                  <div className="modal-footer">
                    <button
                      type="button"
                      className="yellowColorBtn"
                      data-dismiss="modal"
                      onClick={this.closeModalDialog}
                    >
                      Close
                    </button>
                  </div>
                </div>
              </div>
              <div className="modal-backdrop"></div>
            </div>
          </Row>
        </Container>

      </Fragment>
    )
  }
}


export default DonateBanner