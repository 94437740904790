import React, {Fragment, PureComponent} from 'react'; 
import { Container, Col, Row } from 'bootstrap-4-react';
import axios from 'axios';
import * as apiUrl from '../../apiUrl';


class PrivacypolicyDescription extends PureComponent {

//constructor
constructor(props) {
    super(props);

    //variables
    this.state = {
        section_title: "",
        editor: "",


        errors: {},




        data: {},
    };

    

}

//componentDidMount
componentDidMount() {
    axios.post(apiUrl.apiUrl + "/privacy/edit_privacy_page_second_section/1").then((res) =>
        this.setState({


            section_title: res.data[0].section_title,
            editor: res.data[0].section_description,
        })
    );

    console.log(this.state);
}

    render () {
        return (
            <Fragment>
                <Container fluid>
                    <Row>
                        <Col md={12}>
                            <div className="privacy-policy-sec">
                                <h3 className="main-heading fonsecaBold" data-aos='fade-up'>{this.state.section_title}</h3>
                                <div dangerouslySetInnerHTML={{ __html: this.state.editor }} /> 

 











                            </div>

                        </Col>
                    </Row>
                </Container>
            </Fragment>
        )
    }
}
export default PrivacypolicyDescription