import React, { PureComponent, Fragment } from "react";
import { Container, Col, Row, Button } from "bootstrap-4-react";
import Form from "react-bootstrap/Form";
import axios from "axios";
import { FaFileUpload } from "react-icons/fa";
import * as apiUrl from '../../../apiUrl';
import ReCAPTCHA from "react-google-recaptcha";

class CareerForm extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      firstname: "",
      lastname: "",
      email: "",
      mobile: "",
      area: "",
      mailSent: false,
      error: null,
      selectedFiles: undefined,
      currentFile: undefined,
      imageURL: "",
      errors: {},
      section_title4: "",
      Filename: "",
    };
  }

  onChange(value) {



    window.localStorage.setItem('CareerCaptcha', value);




    let errors = this.state.errors;
    errors["Captcha"] = "";

    this.setState({ errors: errors });


  }

  onChangeFirstName = (event) => {
    this.setState({ firstname: event.target.value });
    let errors = this.state.errors;
    errors["firstname"] = "";
    this.setState({ errors: errors });
  };

  onChangeLastname = (event) => {
    this.setState({ lastname: event.target.value });
    let errors = this.state.errors;
    errors["lastname"] = "";
    this.setState({ errors: errors });
  };

  onChangeEmail = (event) => {
    this.setState({ email: event.target.value });
    let errors = this.state.errors;
    errors["email"] = "";
    this.setState({ errors: errors });
  };
  onChangeMobile = (event) => {
    this.setState({ mobile: event.target.value });
    let errors = this.state.errors;
    errors["mobile"] = "";
    this.setState({ errors: errors });
  };

  onChangeArea = (event) => {
    this.setState({ area: event.target.value });
    let errors = this.state.errors;
    errors["area"] = "";
    this.setState({ errors: errors });
  };

  onChangeMessage = (event) => {
    this.setState({ message: event.target.value });
    let errors = this.state.errors;
    errors["message"] = "";
    this.setState({ errors: errors });
  };

  onChangeFile = (e) => {
    let errors = this.state.errors;
    errors["headerlogo"] = "";




    var ext = e.target.files[0].name.split('.').pop();
    if (ext == "pdf" || ext == "docx" || ext == "doc") {
      let reader = new FileReader();
      reader.readAsDataURL(e.target.files[0]);

      var self = this;

      reader.onloadend = function (e) {





        self.setState({
          imageURL: reader.result,
        });
      };

      this.setState({
        selectedFiles: e.target.files,
        Filename: e.target.files[0].name+ ' Added '
      });

    } else {


      let errors = {};
      errors["headerlogo"] = "Accept only Pdf, doc, docx";
      this.setState({
       
        Filename: ''
      });

      this.setState({ errors });

      document.getElementById("file").value = "";


      return false;

    }


  };

  handleValidation(e) {
    let firstname = this.state.firstname;
    let lastname = this.state.lastname;
    let email = this.state.email;
    let mobile = this.state.mobile;
    let message = this.state.message;
    let area = this.state.area;
    let currentFile = this.state.selectedFiles;

    let errors = {};
    let formIsValid = true;

    let token = window.localStorage.getItem('CareerCaptcha');





    if (token === null || token === 'null') {
      formIsValid = false;
      errors["Captcha"] = "*Please Select The Captcha";
    }

    if (currentFile == undefined && this.state.imageURL == "") {
      formIsValid = false;
      errors["headerlogo"] = "Please Choose a File";
    }

    if (firstname === "") {
      formIsValid = false;
      errors["firstname"] = "*Please Enter Your First Name";
    }
    if (lastname === "") {
      formIsValid = false;
      errors["lastname"] = "*Please Enter Your Last Name";
    }

    if (email === "") {
      formIsValid = false;
      errors["email"] = "*Please Enter Your Email";
    }

    const regex =
      /^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i;

    if (!this.state.email || regex.test(this.state.email) === false) {
      errors["email"] = "*Please Enter a Valid Email Address";
      this.setState({
        errors: errors,
      });
      formIsValid = false;
    }
    if (mobile === "") {
      formIsValid = false;
      errors["mobile"] = "*Please Enter Your Mobile Number";
    }
    var a = /^\d{10}$/.test(mobile);

    if (a === false) {
      formIsValid = false;
      errors["mobile"] = "*Please Enter a Valid Mobile Number";
    }

    if (area === "") {
      formIsValid = false;
      errors["area"] = "*Please Enter Your Area Name";
    }

    if (message === "") {
      formIsValid = false;
      errors["message"] = "*Please Enter Your Message";
    }

    this.setState({ errors: errors });
    return formIsValid;
  }


  upload = (e) => {
    if (this.handleValidation(e)) {
      let formData = new FormData();

      let firstname = this.state.firstname;
      let lastname = this.state.lastname;
      let email = this.state.email;
      let mobile = this.state.mobile;
      let area = this.state.area;

      let currentFile = this.state.selectedFiles[0];

      let token = window.localStorage.getItem('CareerCaptcha');

      formData.append("firstname", firstname);
      formData.append("lastname", lastname);
      formData.append("email", email);
      formData.append("mobile", mobile);
      formData.append("area", area);
      formData.append("background_image", currentFile);
      formData.append("captcha", token);


      axios({
        method: "post",
        url: apiUrl.apiUrl + '/getinvolvedcareers/save_career_form',
        headers: { "content-type": "application/json" },
        data: formData,
      })
        .then((result) => {
          document.getElementById("myModal").style.display = "block";

          window.localStorage.removeItem("CareerCaptcha");
          window.grecaptcha.reset();
          this.setState({
            mailSent: result.data.sent,
          });
        })
        .catch((error) => this.setState({ error: error.message }));

      this.setState({
        firstname: "",
        lastname: "",
        email: "",
        mobile: "",
        area: "",
        selectedFiles: undefined,
        imageURL: "",
        Filename:"",
      });

      this.setState({ redirect: true });
      //e.preventDefault();
    }
  };

  closeModalDialog() {
    document.getElementById("myModal").style.display = "none";
  }

  validate = (evt) => {
    var theEvent = evt || window.event;

    // Handle paste
    if (theEvent.type === "paste") {
      key = evt.clipboardData.getData("text/plain");
    } else {
      // Handle key press
      var key = theEvent.keyCode || theEvent.which;
      key = String.fromCharCode(key);
    }
    var regex = /[0-9]|\./;
    if (!regex.test(key)) {
      theEvent.returnValue = false;
      if (theEvent.preventDefault) theEvent.preventDefault();
    }
  };

  onlyAlphabets = (evt) => {
    try {
      if (window.event) {
        var charCode = window.event.keyCode;
      } else if (evt) {
        var charCode = evt.which;
      } else {
        return true;
      }
      if ((charCode > 64 && charCode < 91) || (charCode > 96 && charCode < 123))
        return true;
      else evt.preventDefault();
    } catch (err) {
      alert(err.Description);
    }
  };

  //componentDidMount
  componentDidMount() {
    axios.post(apiUrl.apiUrl + "/getinvolvedcareers/edit_getinvolved_careers_page_second_section/1").then((res) =>
      this.setState({



        section_title4: res.data[0].section_title4,



      })
    );

    console.log(this.state);
  }


  render() {
    return (
      <Fragment>
        <section className="get-involved-page-sec career-form-sec">
          <Container fluid className="wrap">
            <div className="getinvolved-form">
              <Row>
                <Col md={12}>
                  <h6 className="text-center poppinsBold" data-aos="fade-up">
                    {this.state.section_title4}
                  </h6>
                  <Form className="row" data-aos="fade-up">
                    <Form.Group className="mb-3 col-md-6">
                      <Form.Label>First Name *</Form.Label>
                      <Form.Control
                        type="text"
                        placeholder="First Name"
                        id="firstname"
                        value={this.state.firstname}
                        onKeyPress={this.onlyAlphabets}
                        onChange={this.onChangeFirstName}
                      />
                      <span className="form-error">
                        {this.state.errors["firstname"]}
                      </span>
                    </Form.Group>
                    <Form.Group className="mb-3 col-md-6">
                      <Form.Label>Last Name *</Form.Label>
                      <Form.Control
                        type="text"
                        placeholder="Last Name"
                        id="lastname"
                        value={this.state.lastname}
                        onChange={this.onChangeLastname}
                        onKeyPress={this.onlyAlphabets}
                      />
                      <span className="form-error">
                        {this.state.errors["lastname"]}
                      </span>
                    </Form.Group>
                    <Form.Group className="mb-3 col-md-6">
                      <Form.Label>Email *</Form.Label>
                      <Form.Control
                        type="email"
                        placeholder="Email"
                        id="email"
                        value={this.state.email}
                        onChange={this.onChangeEmail}
                      />
                      <span className="form-error">
                        {this.state.errors["email"]}
                      </span>
                    </Form.Group>
                    <Form.Group className="mb-3 col-md-6">
                      <Form.Label>Phone *</Form.Label>
                      <Form.Control
                        type="text"
                        placeholder="Phone"
                        id="mobile"
                        value={this.state.mobile}
                        onKeyPress={this.validate}
                        onChange={this.onChangeMobile}
                      />
                      <span className="form-error">
                        {this.state.errors["mobile"]}
                      </span>
                    </Form.Group>
                    <Form.Group
                      className="mb-3 col-md-12"
                    >
                      <Form.Label>Explain your interest in the area</Form.Label>
                      <Form.Control
                        as="textarea"
                        rows={5}
                        placeholder="Explain your interest in the area"
                        id="area"
                        value={this.state.area}
                        onChange={this.onChangeArea}
                      />
                      <span className="form-error">
                        {this.state.errors["area"]}
                      </span>
                    </Form.Group>
                    <Form.Group className="mb-3 col-md-12 custom-input-file">
                      <Form.Label>Upload Resume*</Form.Label>
                      <div className="custom-input-file">
                        <label for="file" className="yellowColorBtn">
                          <i class="fa fa-cloud-upload"></i> Choose File
                        </label>
                        <input type="file" placeholder="Upload Resume" name="file"
                          id="file"
                          onChange={this.onChangeFile} />

                        <span className="Filename">{this.state.Filename} </span>

                        <span className="form-error">
                          {this.state.errors["headerlogo"]}
                        </span>
                      </div>
                    </Form.Group>

                    <Form.Group
                      className="mb-3 col-md-12"
                    >

                      <ReCAPTCHA
                        sitekey="6LdUt3QgAAAAABa0w8ctOTdSBSqQWop2WIoVHJkm"
                        onChange={this.onChange}


                      />



                    </Form.Group>
                    <span className="form-error captcha-error">{this.state.errors["Captcha"]}</span>
                  </Form>
                </Col>
              </Row>
              <Row>
                <Col md={12} className="text-center">

                  <Button className="yellowColorBtn" onClick={this.upload} >APPLY</Button>
                </Col>
              </Row>
            </div>
          </Container>


          <Container>
            <Row>
              <div
                className="modal form-modal "
                tabIndex="-1"
                role="dialog"
                id="myModal"
              >
                <div className="modal-dialog" role="document">
                  <div className="modal-content">
                    <div className="modal-header">
                      <button
                        type="button"
                        className="close"
                        onClick={this.closeModalDialog}
                        data-dismiss="modal"
                        aria-label="Close"
                      >
                        <span aria-hidden="true">&times;</span>
                      </button>
                    </div>
                    <div className="modal-body">
                      <h2 className="canelaMedium secHeading">
                        Thank you. We will get back to you shortly.
                      </h2>
                    </div>
                    <div className="modal-footer">
                      <button
                        type="button"
                        className="yellowColorBtn"
                        data-dismiss="modal"
                        onClick={this.closeModalDialog}
                      >
                        Close
                      </button>
                    </div>
                  </div>
                </div>
                <div className="modal-backdrop"></div>
              </div>
            </Row>
          </Container>


        </section>
      </Fragment>
    );
  }
}

export default CareerForm;
