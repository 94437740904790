import React, { PureComponent, Fragment } from 'react';
import { Container, Col, Row } from 'bootstrap-4-react';   
class TheJoyProjectDescription extends PureComponent {
    render() { 
        return (
          <Fragment>  
         <section className="media-inner-description-sec">
             
                  <Container fluid className="wrap"> 
                      <Row>
                           <Col md={12}>
                               <div className="media-inner-des-smallhead"> 

                               <h6 className="media-inner-desc-heading" data-aos='fade-up'>Why we need to help:</h6>
                               <p className="common-para" data-aos='fade-right'>Due to their economic background, women below the poverty line seldom have access to modern medical facilities and proper nutritional care. Maternal under-nutrition, anemia, pediatric HIV infection, and low birth weight are just some of the serious issues that these women are at risk of.</p>
 
                               <p className="common-para" data-aos='fade-right'>By providing access to good healthcare institutions, we can greatly reduce the chances of life-threatening pregnancies and also facilitate safe and hygienic deliveries and protect the health of mothers and their babies. Supporting mothers-to-be through a happy, stress-free pregnancy, will help them relish the joys of motherhood even more!</p>
 
 
 
<h6 className="media-inner-desc-heading" data-aos='fade-up'>How we help:</h6>
<p className="common-para" data-aos='fade-right'>We provide prenatal care and post-delivery support for new mothers and newborns.
Our 2-year program provides holistic support to mothers, with round the clock professional medical care, a constant supply of nutrition and thyroid care. To ensure a safe pregnancy, we take in expecting mothers at the start of their second trimester and provide them with nutritious food, good healthcare and financial help until the baby turns one. </p> 
 
 
<p className="common-para" data-aos='fade-right'>Our efforts not only facilitate a happy, stress-free pregnancy and early motherhood, but also healthy babies with proper physical and cognitive development.</p>
 




                                </div>  
 
                           </Col>
                      </Row>
                    </Container>   
             </section>
          
          </Fragment>
        )
      }
}


export default TheJoyProjectDescription