import React, { PureComponent, Fragment } from "react";
import { Container, Col, Row } from "bootstrap-4-react";
import purpleImg1 from "../../assets/purple-section-img-1.jpg";
import purpleImg2 from "../../assets/purple-section-img-2.jpg";

import * as apiUrl from '../../apiUrl';
import axios from "axios";


class AboutUsPurpleSection extends PureComponent {

//constructor
constructor(props) {
  super(props);

  //variables
  this.state = {
    media_type: "",
    selectedFiles: undefined,
    currentFile: undefined,
    section_title: "",
    section_description: "",

    errors: {},
    imageURL: "",
    data: {

    },
    media_type1: "",
    selectedFiles1: undefined,
    currentFile1: undefined,
    imageURL1: "",

    section_title1: "",
    section_description1: "",


    section_title2: "",
    section_description2: "",


  };









}

//componentDidMount
componentDidMount() {

  axios.post(apiUrl.apiUrl + '/about/edit_about_page_second_section/1')
    .then(res =>

      this.setState({
        section_title: res.data[0].section_title,
        section_description: res.data[0].section_description,
        media_type: res.data[0].media_type,
        media_type1: res.data[0].media_type1,
        imageURL: apiUrl.apiUrl + "/mvd/AboutPageSecondSection/" + res.data[0].section_background_image,
        imageURL1: apiUrl.apiUrl + "/mvd/AboutPageSecondSection/" + res.data[0].second_image,

        section_title1: res.data[0].section_title1,
        section_description1: res.data[0].section_description1,


        section_title2: res.data[0].section_title2,
        section_description2: res.data[0].section_description2,
      })

    );




}

  render() {
    return (
      <Fragment>
        <section className="about-us-purple-section common-sec">
          <Container fluid>
            <div className="about-green-container">
              <Row>
                <Col md={6}>
                  <div className="image-container" data-aos='fade-right'>
                  {this.state.media_type == 'video' ?
                      <video src={this.state.imageURL} playsInline="playsInline" autoPlay="autoPlay" muted="muted" loop="loop">

                      </video>
                      : <img src={this.state.imageURL} alt="" className="image-1 img-fluid" />

                    }

                    {this.state.media_type1 == 'video' ?
                      <video src={this.state.imageURL1} playsInline="playsInline" autoPlay="autoPlay" muted="muted" loop="loop">

                      </video>
                      : <img src={this.state.imageURL1} alt="" className="image-2 img-fluid" />

                    }
                  </div>
                </Col>
                <Col md={6} className="d-flex flex-column justify-content-center about-green-content">

                  <h2 className="fonsecaBold" data-aos='fade-left'>
                    {this.state.section_title}
                  </h2>
                  <p className="common-para" data-aos='fade-left'> {this.state.section_description} </p>

                  <h2 className="fonsecaBold" data-aos='fade-left'>
                  {this.state.section_title1}
                  </h2>
                  <p className="common-para" data-aos='fade-left'>{this.state.section_description1} </p>

                  <h2 className="fonsecaBold" data-aos='fade-left'>
                  {this.state.section_title2}
                  </h2>
                  <p className="common-para" data-aos='fade-left'>{this.state.section_description2} </p>



                </Col>
              </Row>
            </div>
          </Container>
        </section>
      </Fragment>
    );
  }
}

export default AboutUsPurpleSection
