import React, { Fragment, PureComponent } from 'react';
import Header from './../common/header';
import Footer from './../common/footer';
import TermsConditionsBanner from './terms-conditions-banner';
import TermsconditionsDescription from './terms-conditions-descriptions';
import * as apiUrl from '../../apiUrl';
import axios from "axios";
import { Helmet } from "react-helmet";


class TermsconditionsPage extends PureComponent {

    constructor(props) {
        super(props);
        this.state = {

            terms_meta_title: "",
            terms_meta_description: "",
        }
    }

    componentDidMount() {

        axios.post(apiUrl.apiUrl + "/seometa/edit_seo_meta/1").then((res) => {
            this.setState({
                terms_meta_title: res.data[0].terms_meta_title,
                terms_meta_description: res.data[0].terms_meta_description,


            })


        }
        );


    }


    render() {
        return (
            <Fragment>
                <Header />

                <Helmet>
                    <title>{this.state.terms_meta_title}</title>
                    <meta name="description" content={this.state.terms_meta_description} />
                </Helmet>
                <TermsConditionsBanner />
                <TermsconditionsDescription />
                <Footer />
            </Fragment>
        )
    }
}
export default TermsconditionsPage