import React, { PureComponent, Fragment } from 'react';
import { Link } from 'react-router-dom';
import { Navbar, Container, Nav } from 'react-bootstrap';
import { NavLink } from 'react-router-dom';
// import Logo from './../../assets/Logo.svg'; 
// import Logo from './../../assets/RCT_Logo-01.svg'; 
import Logo from './../../assets/RCT Horizontal Logo.gif';
import Logo1 from './../../assets/RCT-Horizontal-Logo-trans.gif';
import { FaHeart } from "react-icons/fa";
import axios from 'axios';
import * as apiUrl from '../../apiUrl';


class Header extends PureComponent {
    constructor(props) {
        super(props);
        this.state = {
            addClass: false,
            navclassName: "",
            menu_items: [],
            imageURL1: "",
            imageURL2: "",
        }
    }

    async componentDidMount() {
        window.addEventListener("scroll", this.handleScroll);

        axios
            .post(apiUrl.apiUrl + "/menu/fetch_header_menu_items")
            .then((res) => {

                this.setState({ menu_items: res.data })
                console.log(res.data);

            });


        axios.post(apiUrl.apiUrl + '/footeraddr/edit_social_links/1')
            .then(res =>

                this.setState({


                    imageURL1:
                        apiUrl.apiUrl +
                        "/mvd/SocialLinksSection/" +
                        res.data[0].header_logo,

                    imageURL2:
                        apiUrl.apiUrl +
                        "/mvd/SocialLinksSection/" +
                        res.data[0].horizontal_logo,
                })

            );
    }

    handleScroll = () => {
        if (window.pageYOffset > 200) {
            if (!this.state.navclassName) {
                this.setState({ navclassName: "navactive" });
            }
        } else {
            if (this.state.navclassName) {
                this.setState({ navclassName: "" });
            }
        }

    }
    render() {

        console.log(this.state.menu_items);


        return (
            <Fragment>
                {/* Header Start */}
                <Navbar fixed="top" bg="light" expand="lg" className={`rahul-navbar ${this.state.navclassName}`}>
                    <Container className="rahul-navBG">
                        <Navbar.Brand>
                            <Link to="/">
                                <img src={this.state.imageURL2} className="img-fluid bgLogo" alt="Rahul-logo" />
                                <img src={this.state.imageURL1} className="img-fluid transparentLogo" alt="Rahul-logo" />
                            </Link>

                        </Navbar.Brand>
                        <Nav.Item className="mobile-donate-button">

                            {


                                this.state.menu_items.map((item, i) => (

                                    i === this.state.menu_items.length - 1 ?
                                        <NavLink to={{ pathname: `${item.menu_link}` }} className="nav-link yellowColorBtn" activeClassName="active">  {item.menu_text} <FaHeart /> </NavLink>
                                        : ''

                                ))




                            }
                        </Nav.Item>
                        <Navbar.Toggle aria-controls="basic-navbar-nav" />

                        <Navbar.Collapse className="justify-content-end" id="basic-navbar-nav">


                            {


                                this.state.menu_items.map((item, i) => (





                                    i === this.state.menu_items.length - 1 ?

                                        <Nav.Item>
                                            <NavLink to={{ pathname: `${item.menu_link}` }} className="nav-link yellowColorBtn" activeClassName="active"> {item.menu_text} <FaHeart /> </NavLink>
                                        </Nav.Item>
                                        : <Nav.Item>
                                            <NavLink to={{ pathname: `${item.menu_link}` }} className="nav-link" activeClassName="active"> {item.menu_text} </NavLink>
                                        </Nav.Item>



                                ))




                            }






                        </Navbar.Collapse>



                    </Container>
                </Navbar>
            </Fragment>

        )
    }
}


export default Header

