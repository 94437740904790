import React, { PureComponent, Fragment } from 'react';
import { Container, Col, Row, Button } from 'bootstrap-4-react';
import { Link } from 'react-router-dom';
import Logo from './../../assets/Logo.svg';
import Form from 'react-bootstrap/Form';
import { FaFacebookF, FaInstagram, FaLinkedinIn, FaTwitter, FaYoutube } from "react-icons/fa";
import axios from "axios";
import * as apiUrl from '../../apiUrl';


class Footer extends PureComponent {



  constructor(props) {
    super(props);
    this.state = {
      email123: "",
      mailSent: false,
      error: null,
      errors: {},
      footer_menu_items: [],
      phone_no: "",
      email_address: "",
      facebook_link: "",
      instagram_link: "",
      twitter_link: "",
      linkedin_link: "",
      youtube_link: "",
      address: "",
      subscribe_text: "",
      all_rights_text: "",
    };
  }

  async componentDidMount() {
    axios
      .post(apiUrl.apiUrl + "/menu/fetch_footer_menu_items")
      .then((res) => this.setState({ footer_menu_items: res.data }));



    axios.post(apiUrl.apiUrl + '/footeraddr/edit_social_links/1')
      .then(res =>

        this.setState({


          imageURL:
            apiUrl.apiUrl +
            "/mvd/SocialLinksSection/" +
            res.data[0].footer_logo,



          phone_no: res.data[0].phone_no,
          email_address: res.data[0].email_address,
          facebook_link: res.data[0].facebook_link,
          instagram_link: res.data[0].instagram_link,
          twitter_link: res.data[0].twitter_link,
          linkedin_link: res.data[0].linkedin_link,
          youtube_link: res.data[0].youtube_link,
          address: res.data[0].address,



          subscribe_text: res.data[0].subscribe_text,
          all_rights_text: res.data[0].all_rights_text,



        })

      );

  }

  onChangeEmail = (event) => {
    this.setState({ email123: event.target.value });
    let errors = this.state.errors;
    errors["email123"] = "";
    this.setState({ errors: errors });
  };

  handleValidation() {
    let email123 = this.state.email123;
    let errors = {};
    let formIsValid = true;
    if (email123 == "") {
      formIsValid = false;
      errors["email123"] = "*Please Enter Your Email";
    }


    this.setState({ errors: errors });
    return formIsValid;
  }

  upload = (e) => {

    e.preventDefault();
    
    if (this.handleValidation()) {
 
      let formData = new FormData();
      let email123 = this.state.email123;
      formData.append("email", email123);
      axios({
        method: "post",
        url: apiUrl.apiUrl + '/footeraddr/save_footer_form',
        headers: { "content-type": "application/json" },
        data: formData,
      })
        .then((result) => {
          document.getElementById("myModal").style.display = "block";
          this.setState({
            mailSent: result.data.sent,
          });
        })
        .catch((error) => this.setState({ error: error.message }));

      this.setState({
        email123: "",
      });


      // e.preventDefault();
    }
  };

  closeModalDialog() {
    document.getElementById("myModal").style.display = "none";
  }

  render() {

    return (
      <Fragment>
        <section className="rct-footer-sec blurColorBG">
          <Container fluid className="rct-footer-div">
            <Row className="d-flex justify-content-around">
              <Col lg={3} md={4}>
                <div className="footer-address-div">
                  <Link to="/about">
                    <img
                      src={this.state.imageURL}
                      className="img-fluid"
                      alt="Rahul-logo"
                    />
                  </Link>
                  <p>
                    {this.state.address}
                  </p>
                  <p>
                    <a href={"tel:" + this.state.phone_no}>{this.state.phone_no}</a>
                  </p>
                  <p>
                    <a href={"mailto:" + this.state.email_address}>
                      {this.state.email_address}
                    </a>
                  </p>
                  <div className="footer-social-links mt-4">
                    <a
                      href={this.state.facebook_link}
                      alt="Facebook"
                      target="_blank"
                      className="icon-facebook"
                    >
                      <FaFacebookF />
                    </a>
                    <a
                      href={this.state.instagram_link}
                      alt="Instagram"
                      target="_blank"
                      className="icon-instagram"
                    >
                      <FaInstagram />
                    </a>
                    <a
                      href={this.state.youtube_link}
                      target="_blank"
                      className="icon-instagram"
                    >
                      <FaYoutube />
                    </a>
                    <a
                      href={this.state.linkedin_link}
                      alt="linkedIn"
                      target="_blank"
                      className="icon-linkedIn"
                    >
                      <FaLinkedinIn />
                    </a>
                    <a
                      href={this.state.twitter_link}
                      alt="Twitter"
                      target="_blank"
                      className="icon-twitter"
                    >
                      <FaTwitter />
                    </a>
                  </div>
                </div>
              </Col>
              <Col lg={2} md={2} className="footer-links-div">
                <div className="footer-links">

                  {


                    this.state.footer_menu_items.map((item, i) => (





                      i === this.state.footer_menu_items.length-1 ?
                        <p>
                          <Link to={{ pathname: `${item.menu_link}` }}>


                            <Button className="yellowColorBtn">   {item.menu_text}</Button>
                          </Link>
                        </p>
                        :


                        <p>
                          <Link to={{ pathname: `${item.menu_link}` }}>{item.menu_text}</Link>
                        </p>


                    ))




                  }


                </div>
              </Col>
              <Col lg={6} md={5} className="subscribe-flex">
                <div className="subscribe-form">
                  <h2>
                    {this.state.subscribe_text}
                  </h2>
                  <Form>
                    <Form.Group className="subscribeForm">
                      <Form.Control
                        className="subscribeText"
                        type="text"
                        placeholder="Please Enter your Email"
                        name="email"
                        id="email"
                        value={this.state.email123}
                        onChange={this.onChangeEmail}
                      />
                      <span className="form-error">
                        {this.state.errors["email123"]}
                      </span>
                      <Button className="subscribeButton canelaMedium" onClick={(e) => this.upload(e)}>
                        SUBSCRIBE
                      </Button>
                    </Form.Group>
                  </Form>
                </div>
              </Col>
            </Row>
          </Container>
          <Container fluid className="rct-footer-copyright lightgrey">
            <Row>
              <Col md={6} className="text-left">
                <p>
                  {this.state.all_rights_text}{" "}
                </p>
              </Col>
              <Col md={6} className="text-right rct-footer-right">
                <Link
                  to="/terms-condition"
                  className="links leftborder-links"
                >
                  {" "}
                  <p> Terms and Conditions </p>
                </Link>
                <Link
                  to="/privacy-policy"
                  className="links leftborder-links"
                >
                  <p> Privacy Policy</p>
                </Link>
              </Col>
            </Row>
          </Container>
        </section>

        <nav className="social">
          <ul>
            <li>
              <a
                href={this.state.facebook_link}
                target="_blank"
              >
                <i className="">
                  {" "}
                  <FaFacebookF />{" "}
                </i>{" "}
                <span>Facebook</span>{" "}
              </a>
            </li>
            <li>
              <a
                href={this.state.instagram_link}
                target="_blank"
              >
                {" "}
                <i className="">
                  {" "}
                  <FaInstagram />{" "}
                </i>{" "}
                <span>Instagram</span>{" "}
              </a>
            </li>
            <li>
              <a
                href={this.state.linkedin_link}
                target="_blank"
              >
                {" "}
                <i className="">
                  {" "}
                  <FaLinkedinIn />{" "}
                </i>{" "}
                <span>LinkedIn</span>{" "}
              </a>
            </li>
            <li>
              <a href={this.state.twitter_link} target="_blank">
                {" "}
                <i className="">
                  {" "}
                  <FaTwitter />{" "}
                </i>{" "}
                <span>Twitter</span>{" "}
              </a>
            </li>
            <li>
              <a
                href={this.state.youtube_link}
                target="_blank"
              >
                {" "}
                <i className="">
                  {" "}
                  <FaYoutube />{" "}
                </i>{" "}
                <span>Youtube</span>{" "}
              </a>
            </li>
          </ul>
        </nav>


        <Container>
          <Row>
            <div
              className="modal form-modal "
              tabIndex="-1"
              role="dialog"
              id="myModal"
            >
              <div className="modal-dialog" role="document">
                <div className="modal-content">
                  <div className="modal-header">
                    <button
                      type="button"
                      className="close"
                      onClick={this.closeModalDialog}
                      data-dismiss="modal"
                      aria-label="Close"
                    >
                      <span aria-hidden="true">&times;</span>
                    </button>
                  </div>
                  <div className="modal-body">
                    <h2 className="canelaMedium secHeading">
                      Thank you for subscribing to our notifications.
                    </h2>
                  </div>
                  <div className="modal-footer">
                    <button
                      type="button"
                      className="yellowColorBtn"
                      data-dismiss="modal"
                      onClick={this.closeModalDialog}
                    >
                      Close
                    </button>
                  </div>
                </div>
              </div>
              <div className="modal-backdrop"></div>
            </div>
          </Row>
        </Container>


      </Fragment>
    );
  }
}


export default Footer