import React, { PureComponent, Fragment } from "react";
import { Container, Col, Row } from "bootstrap-4-react";
import aboutUsImg from "../../assets/about-us.jpg";
import * as apiUrl from '../../apiUrl';
import axios from "axios";


class AboutUsAbout extends PureComponent {


  //constructor
  constructor(props) {
    super(props);

    //variables
    this.state = {
      media_type: "",
      selectedFiles: undefined,
      currentFile: undefined,
      section_title: "",
      section_description: "",

      errors: {},
      imageURL: "",
      data: {

      },

    };


  }


  //componentDidMount
  async componentDidMount() {

    axios.post(apiUrl.apiUrl + '/about/edit_about_page_fourth_section/1')
      .then(res =>

        this.setState({
          section_title: res.data[0].section_title,
          section_description: res.data[0].section_description,
          media_type: res.data[0].media_type,
          imageURL: apiUrl.apiUrl + "/mvd/AboutPageFourthSection/" + res.data[0].section_background_image,

        })

      );




  }

  render() {

    let section_description = this.state.section_description.split("\n");

    return (
      <Fragment>
        <section className="about-us-about-section" >
          <Container fluid>
            <Row>
              <Col md={6}>

                {
                  this.state.media_type == 'video' ?
                    <video src={this.state.imageURL} playsInline="playsInline" autoPlay="autoPlay" muted="muted" loop="loop">

                    </video>
                    : <img src={this.state.imageURL} className="img-fluid about-image" alt="" data-aos='fade-right' />

                }


              </Col>
              <Col md={6} className="d-flex align-items-center justify-content-center flex-direction-column ">
                <div className="aboutHeadingDiv">

                  <h1 className="common-heading" data-aos='fade-left'>{this.state.section_title}</h1>

                  <p className="common-para" data-aos='fade-left'>



                    {


                      section_description.map((item, i) => (

                        <span key={"desc2_" + i}>
                          {item}<br />
                        </span>

                      ))
                    }

                  </p>

                  {/*    <p className="common-para" data-aos='fade-left'> Building an enabling environment for the youth and women to realize their dreams through skill training and livelihood development.</p>

                  <p className="common-para" data-aos='fade-left'>  Promoting preventive healthcare practices for underprivileged women and helping them have access to nutrition and medical care.</p>

                  <p className="common-para" data-aos='fade-left'>  Ensuring the fundamental rights of dropout children by bringing them back to mainstream education and guiding them to pursue higher studies.</p> */}


                  {/* <h1 className="common-heading" data-aos='fade-left'>
              Becoming the <span className="greenColor">beacon of change</span> in every corner
              </h1>
              <p className="common-para" data-aos='fade-left'>We strongly believe in building effective strategies and implementing them to bring about economic growth and address social needs including education, health, social protection, and job opportunities.</p>

              <p className="common-para" data-aos='fade-left'>We focus on social development through meaningful transformations. </p>

              <p className="common-para" data-aos='fade-left'>We implement high-impact programs to bring positive changes in the lives of deprived children, youth, and women in our society and thus help them break out of poverty. </p>

              <p className="common-para" data-aos='fade-left'>Our programs are aligned with the United Nations Sustainable Development Goals (2015-2030). </p> */}




                  {/* <p className="common-para" data-aos='fade-right'>
              RCT works as a resource group for several grassroots organizations and schools all over the State. While many villages throughout India lack basic needs, there are a few villages where the deprivation exists in a deeper concentration. 
              </p>
              <p className="common-para" data-aos='fade-right'> 
              The main objective of our organisation is to analyse the work of several organizations and schools. We campaign to implement the best programs for medical care, education, food, disaster relief and the general upliftment of marginalised communities. 
              </p> */}
                </div>
              </Col>

            </Row>
          </Container>
        </section>
      </Fragment>
    );
  }
}

export default AboutUsAbout;
