import React, { PureComponent, Fragment } from 'react';
import 'bootstrap-4-react';
import Header from './../../common/header';
import Footer from './../../common/footer';
import TheAspireProjectBanner from './the-aspire-project-banner'; 
import TheAspireProjectForm from './the-aspire-project-form';
import TheAspireProjectDescription from './the-aspire-project-description';
import TheAspireProjectLatestPrograms from './the-aspire-project-latest-programs';   

class TheAspireProjectPage extends PureComponent{

    render(){

        return(
            <Fragment> 
            <Header /> 
            <TheAspireProjectBanner/>
            <TheAspireProjectForm />
            <TheAspireProjectDescription />
            <TheAspireProjectLatestPrograms/>  
            <Footer />
        </Fragment>
        )

    }
}

export default TheAspireProjectPage