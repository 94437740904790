import React, { Fragment, PureComponent, useRef } from 'react';
import { Container, Col, Row, Button } from 'bootstrap-4-react';
import Form from 'react-bootstrap/Form';
import axios from 'axios';
import * as apiUrl from '../../apiUrl';
import ReCAPTCHA from "react-google-recaptcha";




class ContactForm extends PureComponent {



    constructor(props) {
        super(props);
        this.state = {
            section_title: "",
            firstname: "",
            lastname: "",
            email: "",
            mobile: "",
            message: "",
            errors: {},

            data: {

            },
            Captcha: ""


        };



    }

    validate = evt => {
        var theEvent = evt || window.event;

        // Handle paste
        if (theEvent.type === 'paste') {
            key = evt.clipboardData.getData('text/plain');
        } else {
            // Handle key press
            var key = theEvent.keyCode || theEvent.which;
            key = String.fromCharCode(key);
        }
        var regex = /[0-9]|\./;
        if (!regex.test(key)) {
            theEvent.returnValue = false;
            if (theEvent.preventDefault) theEvent.preventDefault();
        }
    }


    onlyAlphabets = evt => {
        try {
            if (window.event) {
                var charCode = window.event.keyCode;
            }
            else if (evt) {
                var charCode = evt.which;
            }
            else { return true; }
            if ((charCode > 64 && charCode < 91) || (charCode > 96 && charCode < 123))
                return true;
            else
                evt.preventDefault();;
        }
        catch (err) {
            alert(err.Description);
        }
    }

    onChange(value) {



        window.localStorage.setItem('ContactCaptcha', value);




        let errors = this.state.errors;
        errors["Captcha"] = "";

        this.setState({ errors: errors });


    }



    async componentDidMount() {

        axios.post(apiUrl.apiUrl + '/contact/edit_contact_page_form_section/1')
            .then(res =>

                this.setState({
                    section_title: res.data[0].section_title,

                })

            );

    }

    onChangeFirstName = event => {


        this.setState({ firstname: event.target.value });


        let errors = this.state.errors;
        errors["firstname"] = "";

        this.setState({ errors: errors });

    }

    onChangeLastname = event => {


        this.setState({ lastname: event.target.value });


        let errors = this.state.errors;
        errors["lastname"] = "";

        this.setState({ errors: errors });

    }

    onChangeEmail = event => {


        this.setState({ email: event.target.value });


        let errors = this.state.errors;
        errors["email"] = "";

        this.setState({ errors: errors });

    }
    onChangeMobile = event => {


        this.setState({ mobile: event.target.value });


        let errors = this.state.errors;
        errors["mobile"] = "";

        this.setState({ errors: errors });

    }

    onChangeMessage = event => {


        this.setState({ message: event.target.value });


        let errors = this.state.errors;
        errors["message"] = "";

        this.setState({ errors: errors });

    }


    handleValidation(e) {


        let firstname = this.state.firstname;
        let lastname = this.state.lastname;
        let email = this.state.email;
        let mobile = this.state.mobile;
        let message = this.state.message;

        let errors = {};
        let formIsValid = true;


        let token = window.localStorage.getItem('ContactCaptcha');


  


        if (token === null || token === 'null') {
            formIsValid = false;
            errors["Captcha"] = "*Please Select The Captcha";
        }

        if (firstname == "") {
            formIsValid = false;
            errors["firstname"] = "*Please Enter Your First Name";
        }

        if (lastname == "") {
            formIsValid = false;
            errors["lastname"] = "*Please Enter Your Last Name";
        }


        if (email == "") {
            formIsValid = false;
            errors["email"] = "*Please Enter Your Email";
        }

        var re = /\S+@\S+\.\S+/;




        if (re.test(email) == false) {

            formIsValid = false;
            errors["email"] = "*Please Enter a Valid Email Address";

        }

        if (mobile == "") {
            formIsValid = false;
            errors["mobile"] = "*Please Enter Your Mobile Number";
        }

        var a = /^(1\s|1|)?((\(\d{3}\))|\d{3})(\-|\s)?(\d{3})(\-|\s)?(\d{4})$/.test(mobile);


        if (a == false) {
            formIsValid = false;
            errors["mobile"] = "*Please Enter a Valid Mobile Number";
        }



        if (message == "") {
            formIsValid = false;
            errors["message"] = "*Please Enter Your Message";
        }


        this.setState({ errors: errors });
        return formIsValid;
    }


    upload = (e) => {

        if (this.handleValidation(e)) {


            let formData = new FormData();



            let firstname = this.state.firstname;
            let lastname = this.state.lastname;
            let email = this.state.email;
            let mobile = this.state.mobile;
            let message = this.state.message;


            let token = window.localStorage.getItem('ContactCaptcha');


            formData.append("firstname", firstname);
            formData.append("lastname", lastname);
            formData.append("email", email);
            formData.append("mobile", mobile);
            formData.append("message", message);
            formData.append("captcha", token);



            axios.post(apiUrl.apiUrl + "/forms/save_contact_form", formData, {
                headers: {
                    "Content-Type": "multipart/form-data",
                },

            }).then(response => {




                document.getElementById('myModal').style.display = 'block';
                window.localStorage.removeItem("ContactCaptcha");
                window.grecaptcha.reset();


            });

            this.setState({


                firstname: "",
                lastname: "",
                email: "",
                mobile: "",
                message: "",
            });





            this.setState({ redirect: true });


            //e.preventDefault();
        }

    }


    closeModalDialog() {
        document.getElementById('myModal').style.display = 'none';
    }





    render() {
        return (
            <Fragment>
                <section className="get-involved-page-sec">

                    <Container fluid className="wrap">
                        <div className="getinvolved-form">
                            <Row>
                                <Col md={12}>
                                    {/*           <h6 className="text-center poppinsBold" data-aos='fade-up'>{this.state.section_title}</h6> */}
                                    <Form className="row">
                                        <Form.Group className="mb-3 col-md-6" data-aos='fade-right'>
                                            <Form.Label>First Name *</Form.Label>
                                            <Form.Control type="text" placeholder="First Name*" id="firstname" value={this.state.firstname}
                                                onChange={this.onChangeFirstName} onKeyPress={this.onlyAlphabets} />
                                            <span className="form-error">{this.state.errors["firstname"]}</span>
                                        </Form.Group>
                                        <Form.Group className="mb-3 col-md-6" data-aos='fade-left'>
                                            <Form.Label>Last Name *</Form.Label>
                                            <Form.Control type="text" placeholder="Last Name*" id="lastname" value={this.state.lastname}
                                                onChange={this.onChangeLastname} onKeyPress={this.onlyAlphabets} />
                                            <span className="form-error">{this.state.errors["lastname"]}</span>
                                        </Form.Group>
                                        <Form.Group className="mb-3 col-md-6" data-aos='fade-right'>
                                            <Form.Label>Email  *</Form.Label>
                                            <Form.Control type="text" placeholder="Email*" id="email" value={this.state.email}
                                                onChange={this.onChangeEmail} />
                                            <span className="form-error">{this.state.errors["email"]}</span>
                                        </Form.Group>
                                        <Form.Group className="mb-3 col-md-6" data-aos='fade-left'>
                                            <Form.Label>Phone  *</Form.Label>
                                            <Form.Control type="text" placeholder="Mobile*" id="mobile" value={this.state.mobile}
                                                onChange={this.onChangeMobile} onKeyPress={this.validate} />
                                            <span className="form-error">{this.state.errors["mobile"]}</span>
                                        </Form.Group>
                                        <Form.Group className="mb-3 col-md-12" data-aos='fade-left'>
                                            <Form.Label>Enter your message</Form.Label>
                                            <Form.Control as="textarea" rows={5} placeholder="Message" id="message" value={this.state.message}
                                                onChange={this.onChangeMessage} />

                                            <span className="form-error">{this.state.errors["message"]}</span>
                                        </Form.Group>
                                        <Form.Group className="mb-3 col-md-12" data-aos='fade-left'>




                                            <ReCAPTCHA
                                                sitekey="6LdUt3QgAAAAABa0w8ctOTdSBSqQWop2WIoVHJkm"
                                                onChange={this.onChange}


                                            />


                                         
                                        </Form.Group>

                                        <span className="form-error captcha-error">{this.state.errors["Captcha"]}</span>
                                    </Form>
                                </Col>
                            </Row>
                            <Row>
                                <Col md={12} className="text-center">
                                    <Button className="yellowColorBtn" onClick={this.upload}>Send</Button>
                                </Col>
                            </Row>
                        </div>
                    </Container>

                    <Container>
                        <Row>
                            <div className="modal form-modal " tabIndex="-1" role="dialog" id="myModal">
                                <div className="modal-dialog" role="document">
                                    <div className="modal-content">
                                        <div className="modal-header">
                                            <button type="button" className="close" onClick={this.closeModalDialog} data-dismiss="modal" aria-label="Close"><span aria-hidden="true">&times;</span></button>
                                        </div>
                                        <div className="modal-body">
                                            <h2 className="canelaMedium secHeading">Thank you, your response was sent</h2>
                                        </div>
                                        <div className="modal-footer">
                                            <button type="button" className="yellowColorBtn" data-dismiss="modal" onClick={this.closeModalDialog}>Close</button>
                                        </div>
                                    </div>
                                </div>
                                <div className="modal-backdrop"></div>
                            </div>
                        </Row>
                    </Container>
                </section>

            </Fragment>

        )
    }
}
export default ContactForm