import React, { PureComponent, Fragment, useState } from 'react';
import { Container, Col, Row, Button } from 'bootstrap-4-react';
import { Link } from 'react-router-dom';
import Header from './../../common/header';
import Footer from './../../common/footer';
import { FaArrowLeft, FaPlay } from "react-icons/fa";
import NewsInnerBanner from './news-inner-banner';

import videonewsImage1 from '../../../assets/press-july/video-bg-img1.png';
import videonewsImage2 from '../../../assets/press-july/video-bg-img2.png';
import videonewsImage3 from '../../../assets/press-july/video-bg-img3.png';
import videonewsImage4 from '../../../assets/press-july/video-bg-img4.png';
import videonewsImage5 from '../../../assets/press-july/video-bg-img5.png';
import videonewsImage6 from '../../../assets/press-july/video-bg-img6.png';

import pressvideo1 from '../../../assets/press-july/news-video1.mp4';
import pressvideo2 from '../../../assets/press-july/news-video2.mp4';
import pressvideo3 from '../../../assets/press-july/news-video3.mp4';
import pressvideo4 from '../../../assets/press-july/news-video4.mp4';
import pressvideo5 from '../../../assets/press-july/news-video5.mp4';
import pressvideo6 from '../../../assets/press-july/news-video6.mp4';


// News Images

import newsImage1 from '../../../assets/press-july/news-img1.jpg';
// import newsImage2 from '../../../assets/press-july/news-img2.jpg';  
import newsImage3 from '../../../assets/press-july/news-img3.jpg';
import newsImage4 from '../../../assets/press-july/news-img4.jpg';
import newsImage5 from '../../../assets/press-july/news-img5.jpg';


import modalnewsImage1 from '../../../assets/press-july/news-imgs1.png';
// import modalnewsImage2 from '../../../assets/press-july/news-imgs2.png';  
import modalnewsImage3 from '../../../assets/press-july/news-imgs3.jpg';
import modalnewsImage4 from '../../../assets/press-july/news-imgs4.jpg';
import modalnewsImage5 from '../../../assets/press-july/news-imgs5.jpg';

import ReactImageVideoLightbox from "react-image-video-lightbox";


const data = [

  {
    index: 0,
    url: pressvideo1,
    thumbnail: videonewsImage1,
    type: "video",
    title: "Vasanth Live News",
    class: 'someimage',
  },
  {
    index: 1,
    url: pressvideo2,
    thumbnail: videonewsImage2,
    type: "video",
    title: "Mathi Mugam News",
  },
  // {
  //   index: 2,
  //   url: pressvideo3, 
  //   thumbnail: videonewsImage3,
  //   type: "video",
  //   title: "some other vide56o",
  // },
  {
    index: 2,
    url: pressvideo4,
    thumbnail: videonewsImage4,
    type: "video",
    title: "Nesapakkam News",
  },
  // {
  //   index: 3,
  //   url: pressvideo5, 
  //   thumbnail: videonewsImage5,
  //   type: "video"
  // },
  {
    index: 3,
    url: pressvideo6,
    thumbnail: videonewsImage6,
    type: "video",
    title: "Velicham News",
  },

  {
    index: 4,
    url: modalnewsImage1,
    thumbnail: newsImage1,
    type: "photo",
    title: "Thanthi",
  },
  // {
  //   index: 7,
  //   url: modalnewsImage2, 
  //   thumbnail: newsImage2,
  //   type: "video"
  // },
  {
    index: 5,
    url: modalnewsImage3,
    thumbnail: newsImage3,
    type: "photo",
    title: "Murasu",
  },
  {
    index: 6,
    url: modalnewsImage4,
    thumbnail: newsImage4,
    type: "photo",
    title: "Dhinakaran",
  },
  {
    index: 7,
    url: modalnewsImage5,
    thumbnail: newsImage5,
    type: "photo",
    title: "Dhinakaran",
  },



];


export default function NewsGallery() {

  const [open, setopen] = useState(false);
  const [currentIndex, setCurrentIndex] = useState(0);

  const openlightbox = (index) => {


    document.body.classList.add("myClass");
    console.log(index);
    setCurrentIndex(index);
    setopen(true);

  };

  return (
    <Fragment>
      <Header />
      <NewsInnerBanner />
      <section className="media-inner-description-sec1 media-inner-description-sec">


        <Container fluid className="wrap">
          {/* <Row>
                      <Col md={12}>

                     
                      </Col>
                    </Row> */}
          <Row>
            <Col md={12}>
              <div className="pb-3 pb-lg-3">
                <Button className="bottomBorder" > <Link to="/news-inner"><FaArrowLeft /> Back to News </Link></Button>
              </div>

              <div className="media-inner-des-smallhead">
                <h6 className="media-inner-desc-heading" >Nutritional Support Program for Pregnant Women from BPL families</h6>
                <Container fluid>
                  <Row>
                    <Col md={12}>

                      <div><h1>Hello world</h1></div>
                      <div className='row news-inner-images'> 
                        
                            {
                              data.map((item, index) => {

                                if (item.type == 'video')
                                  return (<div className='col-md-4 news-inner-col videoClass'>
                                    <div class="video-img-div"><div class="video-icon">
                                  <a onClick={() => openlightbox(index)} class="video-fa-icon"><FaPlay/></a></div><img className="img-fluid" src={item.thumbnail} onClick={() => openlightbox(index)} /></div>
                                    <div className="news-gal-title">{item.title}</div></div>)


                                return (<div className='col-md-4 news-inner-col'><img className="img-fluid" src={item.thumbnail} onClick={() => openlightbox(index)} /> <div className="news-gal-title">{item.title}</div></div>)



                              })
                            }
  

                      </div>

                      {open && (
                        <div className="lightBox">
                          <ReactImageVideoLightbox className="lightbox-modal"
                            data={data}
                            startIndex={currentIndex}
                            showResourceCount={true}
                            onCloseCallback={() => {
                              setopen(false)
                              document.body.classList.remove("myClass");

                            }}
                            onNavigationCallback={(currentIndex) =>
                              console.log(`Current index: ${currentIndex}`)
                            }
                          />
                        </div>
                      )}

                    </Col>
                  </Row>
                </Container>



              </div>

            </Col>

          </Row>
        </Container>
      </section>
      <Footer />
    </Fragment>
  );
}


