import React, { Fragment, PureComponent} from 'react';
import { Container, Col, Row } from 'bootstrap-4-react';
import axios from 'axios';
import * as apiUrl from '../../apiUrl';


class TermsConditionsBanner extends PureComponent {

    constructor(props) {
        super(props);
        //variables
        this.state = {
            section_title: "",

            selectedFiles: undefined,
            currentFile: undefined,

            errors: {},
            imageURL: "",

            data: {},
        };

    }

    //componentDidMount
    componentDidMount() {
        axios.post(apiUrl.apiUrl + "/terms/edit_terms_page_banner_section/1").then((res) =>
            this.setState({


                section_title: res.data[0].section_title,

                imageURL:
                    apiUrl.apiUrl +
                    "/mvd/TermsPageBannerSection/" +
                    res.data[0].section_background_image,


            })
        );

        console.log(this.state);
    }


    render() {
        return(

            <Fragment>
                <Container fluid className="bannerSec terms-conditions-banner" style={{
                    backgroundImage: `url("` + this.state.imageURL + `")`
                }}>
                    <div className="overlay"></div>
                        <div className="bannerHeight wrap">
                            <Row className="bannerRow text-center">  
                                <Col lg={8} md={7} className="bannerDiv">   
                                <h1 className="common-heading" data-aos='fade-up'>{this.state.section_title}</h1>
                         
                                </Col>  
                            </Row> 
                        </div>   
                  </Container> 
            </Fragment>
           
        )
    }
}

export default TermsConditionsBanner