import React, { PureComponent, Fragment } from "react";
import { Container, Row, Col } from "bootstrap-4-react";
import { Swiper, SwiperSlide } from 'swiper/react';

import SwiperCore, { Mousewheel, Autoplay, Navigation } from 'swiper';

// swiper core styles
import 'swiper/css';
import founderpic1 from "../../assets/about-founder1.jpg";
import founderpic2 from "../../assets/about-founder2.jpg";
import founderpic3 from "../../assets/about-founder3.jpg";

import * as apiUrl from '../../apiUrl';
import axios from "axios";
// import founderpic1 from "../../assets/Timeline_Assets/RCT About_Timeline-02.jpg"; 
// import founderpic2 from "../../assets/Timeline_Assets/RCT About_Timeline-03.jpg";   
// import founderpic3 from "../../assets/Timeline_Assets/RCT About_Timeline-04.jpg";  
// import founderpic4 from "../../assets/Timeline_Assets/RCT About_Timeline-05.jpg";  
// import founderpic5 from "../../assets/Timeline_Assets/RCT About_Timeline-06.jpg";  
// import founderpic6 from "../../assets/Timeline_Assets/RCT About_Timeline-07.jpg";  
// import founderpic7 from "../../assets/Timeline_Assets/RCT About_Timeline-08.jpg";  
// import founderpic8 from "../../assets/Timeline_Assets/RCT About_Timeline-09.jpg";  
// import founderpic9 from "../../assets/Timeline_Assets/RCT About_Timeline-10.jpg";  
// import founderpic10 from "../../assets/Timeline_Assets/RCT About_Timeline-11.jpg";  
// import founderpic11 from "../../assets/Timeline_Assets/RCT About_Timeline-12.jpg";  
SwiperCore.use([Autoplay, Mousewheel, Navigation]);
class FounderStory extends PureComponent {
  //constructor
  constructor(props) {
    super(props);

    //variables
    this.state = {
      media_type: "",
      selectedFiles: undefined,
      currentFile: undefined,
      section_title: "",
      section_description: "",
      section_description1: " ",
      section_description2: "",
      errors: {},
      imageURL: "",
      imageURL1: "",
      imageURL2: "",
      data: {

      },

    };


  }


  //componentDidMount
  async componentDidMount() {

    axios.post(apiUrl.apiUrl + '/about/edit_about_page_founder_section/1')
      .then(res =>

        this.setState({
          section_title: res.data[0].section_title,
          section_description: res.data[0].section_description,
          section_description1: res.data[0].section_description1,
          section_description2: res.data[0].section_description2,
          media_type: res.data[0].media_type,
          imageURL: apiUrl.apiUrl + "/mvd/AboutPageFounderSection/" + res.data[0].first_image,
          imageURL1: apiUrl.apiUrl + "/mvd/AboutPageFounderSection/" + res.data[0].second_image,
          imageURL2: apiUrl.apiUrl + "/mvd/AboutPageFounderSection/" + res.data[0].third_image,

        })

      );




  }
  render() {

    let section_description2 = this.state.section_description2.split("\n");


    return (
      <Fragment>
        <section className="founder-story">
          <Container fluid className="padding-zero">
            <Row>
              <Col md={12}>
                <div className="founder-story-section" data-aos='fade-up'>
                  <Swiper id="founder-story" spaceBetween={35} slidesPerView={1} loop={false} speed={1000} roundLengths={true} grabCursor={true} navigation={true} freeMode={true}
                    mousewheel={{
                      // invert: true,
                      releaseOnEdges: true
                    }}
                    breakpoints={{
                      320: {
                        slidesPerView: 1,
                        autoHeight: true,
                      },
                      420: {
                        slidesPerView: 1,
                        autoHeight: true,
                      },
                      768: {
                        slidesPerView: 1,
                        autoHeight: true,
                      },
                      1024: {
                        slidesPerView: 0.9,
                      },
                      1280: {
                        slidesPerView: 0.9,
                      }
                    }} className="founderSwipers">
                    <SwiperSlide id="silder-1">
                      <Row>
                        <Col md={4}>
                          <div className="founder-story-description">
                            {/* <h5 className="fonsecaRegular">About our Founder</h5> */}
                            <h2 className="common-heading text-uppercase">
                              {this.state.section_title}

                            </h2>
                            <p className="common-para">
                              {this.state.section_description}
                            </p>
                          </div>
                        </Col>
                        <Col md={2}>
                          <div className="row founder-circle-row">
                            <div className="col-6">
                              <div className="founder-circle-div">
                                <div className="founder-circle">
                                  <span>Explore</span>
                                </div>
                              </div>
                            </div>
                            <div className="col-6">
                              <div className="scroll-line"></div>
                            </div>
                          </div>

                        </Col>
                        <Col md={6}>
                          <div className="founder-pic">
                            {/* <img src={founderpic1} className="img-fluid" alt="" /> */}
                            {/* <h2>CHARTING THE FOUNDER'S STORY</h2> */}
                            {
                              this.state.media_type == 'video' ?
                                <video src={this.state.imageURL} playsInline="playsInline" autoPlay="autoPlay" muted="muted" loop="loop">

                                </video>
                                : <img src={this.state.imageURL} className="img-fluid about-image" alt="" data-aos='fade-right' />

                            }
                          </div>
                        </Col>
                      </Row>
                    </SwiperSlide>
                    <SwiperSlide id="silder-1">
                      <Row>
                        <Col md={4}>
                          <div className="founder-story-description">
                            <p className="common-para">
                              {this.state.section_description1}
                            </p>
                          </div>
                        </Col>
                        <Col md={1}></Col>
                        <Col md={6}>
                          <div className="founder-pic">
                            {/* <img src={founderpic2} className="img-fluid" alt="" />  */}
                            {
                              this.state.media_type == 'video' ?
                                <video src={this.state.imageURL1} playsInline="playsInline" autoPlay="autoPlay" muted="muted" loop="loop">

                                </video>
                                : <img src={this.state.imageURL1} className="img-fluid about-image" alt="" data-aos='fade-right' />

                            }
                          </div>
                        </Col>
                      </Row>
                    </SwiperSlide>
                    <SwiperSlide id="silder-1">
                      <Row>
                        <Col md={4}>
                          <div className="founder-story-description">
                            <p className="common-para">
                              {/*  {this.state.section_description2} */}
                              {


                                section_description2.map((item, i) => (

                                  <span key={"section_description2_" + i}>
                                    {item}<br />
                                  </span>

                                ))
                              }

                            </p>
                          </div>
                        </Col>
                        <Col md={1}></Col>
                        <Col md={6}>
                          <div className="founder-pic">
                            {/* <img src={founderpic3} className="img-fluid" alt="" />  */}
                            {
                              this.state.media_type == 'video' ?
                                <video src={this.state.imageURL2} playsInline="playsInline" autoPlay="autoPlay" muted="muted" loop="loop">

                                </video>
                                : <img src={this.state.imageURL2} className="img-fluid about-image" alt="" data-aos='fade-right' />

                            }
                          </div>
                        </Col>
                      </Row>
                    </SwiperSlide>
                    {/* <SwiperSlide id="slider-2">  
                                <Row> 
                                  <Col md={3}>
                                    <div className="founder-story-description"> 
                                      <h2 className="common-heading text-uppercase">
                                      1983-1997
                                      </h2> 
                                    </div>
                                  </Col>
                                  <Col md={3}>
                                  <div className="founder-story-description1"> 
                                      <img src={founderpic1} className="img-fluid" alt="" />
                                      <h2 className="common-heading text-uppercase">
                                      School - Arasu Nadu Nilai Palli
                                      </h2> 
                                     <p>He began his foray into education at a government school. Jothi’s family struggled to put food on the table for his family. He recalls going to school on Sundays just to eat one square meal that day. </p>
                                    </div>
                                  </Col>
                                  <Col md={6}>
                                    <div className="founder-pic">
                                      <img src={founderpic2} className="img-fluid" alt="" />
                                      <h2>CHARTING THE FOUNDER'S STORY</h2>
                                    </div>
                                  </Col>
                                </Row>
                          </SwiperSlide>
                          <SwiperSlide id="slider-3">  
                              <Row> 
                                  <Col md={3}>
                                    <div className="founder-story-description2"> 
                                      <h2 className="common-heading text-uppercase">
                                      1997 - 2001
                                      </h2> 
                                      <img src={founderpic3} className="img-fluid" alt="" />
                                    </div>
                                  </Col>
                                  <Col md={3}>
                                  <div className="founder-story-description1">  
                                      <h2 className="common-heading text-uppercase">
                                      College - R.M.K Engineering College
                                      </h2> 
                                     <p>Earning a degree in Mechanical Engineering, he thrived for the first time in his life and was the top student in his batch. His thirst for knowledge inspired him to improve his fluency in the English language, using an Easy English guide he bought.</p>
                                    </div>
                                  </Col>
                                  <Col md={6}>
                                    <div className="founder-pic">
                                      <img src={founderpic4} className="img-fluid" alt="" /> 
                                    </div>
                                  </Col>
                                </Row>
                          </SwiperSlide>
                          <SwiperSlide id="slider-4">  
                              <Row> 
                                  <Col md={3}>
                                    <div className="founder-story-description3"> 
                                      <h2 className="common-heading text-uppercase">
                                      Career
                                      </h2>  
                                      <p>He held several jobs in his life and was even employed at his former college for a while, before joining Maestro Steel Detailing, a steel fabricating & contracting company started by his friend. He slowly climbed his way to the top of the company and is presently the director.</p>
                                    </div>
                                  </Col>
                                  <Col md={3}>
                                  <div className="founder-story-description4">  
                                  <img src={founderpic5} className="img-fluid" alt="" />
                                    </div>
                                  </Col>
                                  <Col md={6}>
                                    <div className="founder-story-description5">
                                      <div className="founder-pic1">  
                                        <img src={founderpic6} className="img-fluid" alt="" /> 
                                      </div>
                                      </div>
                                  </Col>
                                </Row>
                          </SwiperSlide> */}
                    {/* <SwiperSlide id="slider-5">  
                              <Row> 
                                  <Col md={3}>
                                    <div className="founder-story-description3"> 


                                    <h2 className="common-heading text-uppercase">
                                    2019
                                      </h2> 
                                      <h5 className=" font-weight-bold fonsecaBold text-uppercase">
                                      ENTRY INTO POLITICS
                                      </h5>  
                                      <p>He wanted to bring forth a cosmic change in society and
soon realised that with a political career, comes the power
to make a true difference in our community. He considered
politics to be a crutch with which he can bring about
reforms to improve the lives of people in need.</p>
                                    
                              
                                    </div>
                                  </Col>
                                  <Col md={3}>
                                  <div className="founder-story-description3">  
                                  <img src={founderpic7} className="img-fluid" alt="" />
                                    </div>
                                  </Col>
                                  <Col md={6}>
                                    <div className="founder-story-description5">
                                      <div className="founder-pic">  
                                        <img src={founderpic8} className="img-fluid" alt="" /> 
                                      </div>
                                      </div>
                                  </Col>
                                </Row>
                          </SwiperSlide> */}
                    {/* <SwiperSlide id="slider-6">  
                              <Row> 
                                  <Col md={3}>
                                    <div className="founder-story-description3"> 
                                    <h5 className=" font-weight-bold fonsecaBold text-uppercase">
                                    Rahul Charitable Trust (2019 - present)

                                      </h5>  
                                      <p>Jothiponnambalam started RCT to bring forth a cosmic change in society to uplift underprivileged people by providing education, food, healthcare and the chance to learn the skills needed to attract better employment opportunities, like fluency in spoken and written English, technical, personal and interpersonal skills.  </p>
                                    </div>
                                  </Col>
                                  <Col md={6}>
                                  <div className="founder-story-description3">  
                                  <img src={founderpic9} className="img-fluid" alt="" />
                                    </div>
                                  </Col>
                                  <Col md={3}>
                                    <div className="founder-story-description5">
                                      <div className="founder-pic">  
                                        <img src={founderpic10} className="img-fluid" alt="" /> 
                                      </div>
                                      </div>
                                  </Col>
                                </Row>
                          </SwiperSlide>
                          <SwiperSlide id="slider-7">  
                              <Row> 
                                 
                                  <Col md={3}>
                                  <div className="founder-story-description-final1">  
                                  <img src={founderpic10} className="img-fluid" alt="" />
                                    </div>
                                  </Col>
                                  <Col md={5}>
                                    <div className="founder-story-description-final2">
                                      <div className="founder-pic">  
                                        <img src={founderpic11} className="img-fluid" alt="" /> 
                                      </div>
                                      </div>
                                  </Col>
                                  <Col md={4} lg={3}>
                                    <div className="founder-story-description-final3"> 
                                    <h2 className="common-heading text-uppercase">
                                      At present
                                      </h2>  
                                      <p>Jothi carved his way into a hardened society that was never by his side. Following his motto ‘Curiosity travels faster than courage’, he let his keen and inquiring mind guide him on his arduous journey to a place of stability and fortune. A truly self-made man, he credits his mother with shaping the man that he is today.</p>
                                    </div>
                                  </Col> 
                                </Row>
                          </SwiperSlide> */}

                  </Swiper>
                </div>
              </Col>
            </Row>
          </Container>
        </section>
      </Fragment>
    );
  }
}

export default FounderStory;
