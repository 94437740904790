 
import React, { Fragment,PureComponent } from "react";   
import 'locomotive-scroll/dist/locomotive-scroll.css';  
// Router Links
import {  Routes, Route } from "react-router-dom";  
import '../styles/styles.scss';
import 'bootstrap-4-react';
import 'react-bootstrap';
import 'react-icons/fa'; 
 

// AOS ANIMATION
import AOS from 'aos';
import 'aos/dist/aos.css';

import ScrollToTop from './scrolltotop';
// Pages Links Here 
  
import HomePage from './home-component/home-page';
import AboutPage from './aboutus-component/about-page'; 
// Media and Media inner page
import MediaPage from './media-component/media-page'; 
import MediaInnerPage from './media-component/media-inner/media-inner-page';
// Programs and programs inner page
import ProgramsPage from './programs-component/programs-page'; 
// Programs Inner pages
import TheHopeProject from './programs-component/the-hope-project/the-hope-project-page';
import TheJoyProjectPage from './programs-component/the-joy-project/the-joy-project-page';
import TheEmpowerProjectPage from './programs-component/the-empower-project/the-empower-project-page';
import TheAspireProjectPage from './programs-component/the-aspire-project/the-aspire-project-page';
import ProgramsInnerPage from './programs-component/programs-inner/programs-inner-page'; 
// Get Involved Page 
import GetVolunteerspage from './getinvolved-component/volunteers-component/getinvolved-volunteers-page';
import GetInvolvedCareerpage from './getinvolved-component/careers-component/getinvolved-careers-page';
import GetInvolvedFellowshippage from './getinvolved-component/fellowship-component/getinvolved-fellowship-page';
import GetInvolvedPartnerspage from './getinvolved-component/partners-component/getinvolved-partners-page';
// Stories Page
import StoriesPage from './stories-component/stories-page';
// import StoriesInnerPage from './stories-component/stories-inner/stories-inner-page';

import MuthuselviPage from './stories-component/muthuselvi/muthuselvi-page'; 
import AmuthavalliPage from './stories-component/amuthavalli/amuthavalli-page';
import LokeshwariPage from './stories-component/lokeshwari/lokeshwari-page';
// Donate Page
import DonatePage from './donate-component/donate-page';
// Contact Us Page
import ContactPage from './../components/contactus-component/contact-page';
// Privacy Policy page
import PrivacypolicyPage from './../components/privacy-policy-component/privacy-policy-page';
// Terms Conditions page
import TermsconditionsPage from './../components/terms-conditions-component/terms-conditions-page';
// Not Found 404 Page
import NotfoundPage from './notfound-page-component/404-page'; 

import StoriesInnerPage from './stories-component/stories-inner/stories-inner-page';

import NewsPage from './news-component/news-page';
import NewsInnerPage from './news-component/news-inner/news-inner-page';
import NewsGallery from './news-component/news-inner/news-gallery';

// import ReactLoading from "react-loading";
// import styled from "tachyons-components";



// export const Article = styled('div')`
// loading-image items-center justify-center flex flex-column flex-wrap`;
 

class App extends PureComponent { 




// constructor(props) {
//   super(props);
//   this.state = {
//     isLoading: true
//   }
// }



componentDidMount() {
  AOS.init({
    duration : '1500',   
    once: false,
    mobile:false
  });
  AOS.refresh();
  // this.setState({ isLoading: true }); 
  // setTimeout(() => {
  //   this.setState({ isLoading: false });  
  // }, 5000);

  // let url = window.location.href;
  // document.body.addEventListener('click', ()=>{
  //     requestAnimationFrame(()=>{
  //       if(url!== window.location.href){
  //         console.log('url changed');
  //         url =  window.location.href;
  //         this.onRouteChanged();
  //       }
  //     });
  // }, true);
 
}


// onRouteChanged() { 
//   this.setState({ isLoading: true }); 
//   setTimeout(() => {
//     this.setState({ isLoading: false });
//   }, 5000);
// }


  render (){ 
   

   
    
    return (

//       this.state.isLoading ?
// <Fragment>
//           <Article key="spin" >
//           <ReactLoading type="spin" color="#000" className="loading-img" /> 
//         </Article>
//         </Fragment>

//         :

              <Fragment >    
                <ScrollToTop />   
                    <Routes>
                        <Route path="/">
                        <Route index element={<HomePage />} />
                        <Route exact path="home" element={<HomePage />} />
                        <Route exact path="about" element={<AboutPage />} /> 
                        <Route exact path="blogs" element={<MediaPage />} />
                        <Route exact path="blogs-inner/:slug" element={<MediaInnerPage/>} />
                        <Route exact path="programs" element={<ProgramsPage />} />
                        <Route exact path="programs-inner/:slug" element={<ProgramsInnerPage/>} /> 
                        <Route exact path="the-hope-project" element={<TheHopeProject/>} />
                        <Route exact path="the-joy-project" element={<TheJoyProjectPage/>} />
                        <Route exact path="the-empower-project" element={<TheEmpowerProjectPage />} />
                        <Route exact path="the-aspire-project" element={<TheAspireProjectPage />} />
                        <Route exact path="getinvolved-volunteers" element={<GetVolunteerspage/>} />
                        <Route exact path="getinvolved-career" element={<GetInvolvedCareerpage/>} />
                        <Route exact path="getinvolved-fellowship" element={<GetInvolvedFellowshippage/>} />
                        <Route exact path="getinvolved-partners" element={<GetInvolvedPartnerspage/>} />
                        <Route exact path="stories" element={<StoriesPage/>} />
                        {/* <Route exact path="stories-inner" element={<StoriesInnerPage/>} /> */}
                        <Route exact path="stories-inner-muthuselvi" element={<MuthuselviPage/>} />
                        <Route exact path="stories-inner-amuthavalli" element={<AmuthavalliPage/>} />
                        <Route exact path="stories-inner-lokeshwari" element={<LokeshwariPage/>} /> 
                        <Route exact path="donate" element={<DonatePage />} />
                        <Route exact path="contact" element={<ContactPage/>} />
                        <Route exact path="privacy-policy" element={<PrivacypolicyPage/>} />
                        <Route exact path="terms-condition" element={<TermsconditionsPage/>} />
                        <Route exact path="*" element={<NotfoundPage />} />
                        <Route exact path="stories-inner/:slug" element={<StoriesInnerPage/>} />  
                        <Route exact path="news" element={<NewsPage/>} />
                        <Route exact path="news-inner" element={<NewsInnerPage/>} />
                        <Route exact path="news-gallery" element={<NewsGallery/>} />
                        </Route>
                      
                    </Routes> 
              </Fragment> 
          )
        }
  }
  
  
  export default App




// import React, { Fragment, useRef } from "react";  
// import { LocomotiveScrollProvider } from "react-locomotive-scroll";
// import 'locomotive-scroll/dist/locomotive-scroll.css'; 
// import ScrollTriggerProxy from './../components/ScrollTriggerProxy'; 
// // Router Links
// import {  Routes, Route } from "react-router-dom";  
// import '../styles/styles.scss';
// import 'bootstrap-4-react';
// import 'react-bootstrap';
// import 'react-icons/fa'; 
// // Pages Links Here
  
// import HomePage from './home-component/home-page';
// import AboutPage from './aboutus-component/about-page'; 

// import MediaPage from './media-component/media-page'; 
// import MediaInnerPage from './media-component/media-inner/media-inner-page';

// import ProgramsPage from './programs-component/programs-page'; 
// import ProgramsInnerPage from './programs-component/programs-inner/programs-inner-page';

// import GetinvolvedPage from './getinvolved-component/getinvolved-page';  

// import NotfoundPage from './notfound-page-componet/404-page'; 

// function App() {
//   const containerRef = useRef(null);

//   // const [setLoaded] = useState(false);

//   // useEffect(() => {
//   //   setTimeout(() => {
//   //     setLoaded(true);
//   //   }, 3000);
//   // }, [])
  

//   return (   
//               <Fragment>     
//                     <Routes>
//                         <Route path="/">
//                         <Route index element={<HomePage />} />
//                         <Route exact path="home" element={<HomePage />} />
//                         <Route exact path="about" element={<AboutPage />} /> 
//                         <Route exact path="media" element={<MediaPage />} />
//                         <Route exact path="media-inner" element={<MediaInnerPage/>} />
//                         <Route exact path="programs" element={<ProgramsPage />} />
//                         <Route exact path="programs-inner" element={<ProgramsInnerPage/>} />
//                         <Route exact path="getinvolved" element={<GetinvolvedPage />} />
//                         <Route exact path="*" element={<NotfoundPage />} />  
//                         </Route>
                      
//                     </Routes> 
//               </Fragment> 
//   );
// }

// export default App;

