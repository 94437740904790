
import React, { PureComponent, Fragment } from 'react';
import { Container, Col, Row, Button } from 'bootstrap-4-react';
import { Link } from 'react-router-dom';

import * as apiUrl from '../../apiUrl';
import axios from "axios";

class HomeLatestNews extends PureComponent {

    convert(str) {
        var date = new Date(str),
          mnth = ("0" + (date.getMonth() + 1)).slice(-2),
          day = ("0" + date.getDate()).slice(-2);
        return [day, mnth ,date.getFullYear()].join("-");
      }

    constructor(props) {
        super(props);
        this.state = {
            data: [],
            section_title: "",

            read_more_link: "",
            read_more_text: "",
            type:"",
        };
    }

    componentDidMount() {

        axios.post(apiUrl.apiUrl + "/home/edit_home_page_latest_news/1").then((res) =>
            this.setState({
                section_title: res.data[0].section_title,

                read_more_link: res.data[0].read_more_link,
                read_more_text: res.data[0].read_more_text,
                type: res.data[0].type,




            })
        );

        axios
            .post(apiUrl.apiUrl + "/news/fetch_news")
            .then((res) => { 

                this.setState({ data: res.data });
                
                console.log(this.state.data);

            })

    }
    render() {
        return (
            <Fragment>
               {this.state.type === 'on' ?
                <section className="latestnews-sec greenColorBG whiteColor">
                    <Container fluid className="wrap">
                        <div className="newsHeadingDiv">
                            <Row className="newsHeadingRow">
                                <Col md={6} className="whiteColor">
                                    <h1 className="common-heading" data-aos='fade-right'>{this.state.section_title}</h1>
                                </Col>
                                <Col md={6} className="buttonDIv">
                                <Link to={{ pathname: `/blogs` }}> <Button className="whiteColorBtn" data-aos='fade-left'>{this.state.read_more_text}</Button></Link>
                                </Col>
                            </Row>
                        </div>
                    </Container>
                    <Container fluid className="wrap">
                        <Row>

                        {


this.state.data.map((item, i) => ( 
                            <Col md={4}>
                                <Link to={{ pathname: `/blogs-inner/${item.slug}` }}>
                                    <div className="latest-news whiteColor" data-aos='fade-up'>
                                        <div className="news-img">
                                            <img src={apiUrl.apiUrl+'/mvd/LatestNews/'+item.section_background_image} className="img-fluid" alt="newsimage" />
                                        </div>

                                        <div className="news-content">
                                            <Row className="newsDateRow">
                                                <Col md={6}>
                                                    <p className="text-uppercase">{item.main_title}</p>
                                                </Col>
                                                <Col md={6} className="newsDate">
                                                    <p>{this.convert(item.date)}</p>
                                                </Col>
                                            </Row>
                                            <h5 className="sub-heading">{item.main_description.substring(0,50)}  </h5>
                                            <p>{item.main_description2.substring(0,150)}</p>
                                            <Button className="whiteColorBtn">KNOW MORE</Button>
                                        </div>
                                    </div>
                                </Link>
                            </Col>
        ))


    }                    
                            
                        </Row>
                    </Container>
                </section>
               : ''
               }

            </Fragment>
        )
    }
}


export default HomeLatestNews