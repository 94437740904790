
import React, { PureComponent, Fragment } from 'react';
import { Container, Col, Row, Button } from 'bootstrap-4-react';
import { Link } from 'react-router-dom'; 
// import { ProgressBar  } from 'react-bootstrap'; 
import { Swiper, SwiperSlide } from 'swiper/react';

import SwiperCore, { Autoplay } from 'swiper'; 

// swiper core styles
import 'swiper/css';

import cardImage1 from '../../../assets/OurProgramsthehopeproject.jpg'; 
import cardImage2 from '../../../assets/OurProgramstheaspireprojet.jpg'; 
import cardImage3 from '../../../assets/OurProgramsempowerproject.jpg';  

SwiperCore.use([Autoplay]);
class TheJoyProjectLatestPrograms extends PureComponent {
    render() { 
        return (
          <Fragment> 
         {/* Latestnews Section */}
         <section className="latestnews-sec programs-sec lightGreenBG latest-news-inner">
                    <Container fluid className="wrap"> 
                        <div className="commonHeadingDiv">
                            <Row className="commonHeadingRow text-center">  
                                <Col md={9}>   
                                <h1 className="common-heading" data-aos='fade-up'>Similar Programs</h1> 
                                </Col>  
                            </Row> 
                        </div>  
                  </Container>    
                  <Container fluid className="wrap programs-div">
                      <Row>
                            <Col md={12}>

                            <Swiper
                          className="programSlider"
                          spaceBetween={50}
                          slidesPerView={3}
                          loop={true}
                          autoplay={true}
                          draggable={true}
                          breakpoints={{
                            // when window width is >= 640px
                            320: {
                              slidesPerView: 1,
                            },
                            420:{
                                slidesPerView: 1,
                            },
                            768:{
                                slidesPerView: 3,
                            }
                          }}
                        >

<SwiperSlide>
                                  <Link to="/the-hope-project">
                                    <div className="programs-card" data-aos='fade-up'>
                                      <div className="card-top-stripe">The Hope Project</div>
                                      <div className="card-img">
                                        <img
                                          src={cardImage1}
                                          className="img-fluid"
                                          alt="cardimage"
                                        />
                                      </div>
                                      <div className="card-content"> 
                                        <h5 className="font-weight-bold">Employability Skill Training for the Youth from vulnerable backgrounds
                                        </h5>

                                        <p>Holistic training, skill development and job placement for youths who are unemployed and stuck with low-wage manual jobs.</p> 
                                        <Link to="/the-hope-project"><Button className="yellowColorBtn">Donate</Button></Link>
                                      </div>
                                    </div>
                                  </Link>
                                </SwiperSlide> 
                               <SwiperSlide>
                                  <Link to="/the-aspire-project">
                                    <div className="programs-card" data-aos='fade-up'>
                                      <div className="card-top-stripe">The Aspire Project</div>
                                      <div className="card-img">
                                        <img
                                          src={cardImage2}
                                          className="img-fluid"
                                          alt="cardimage"
                                        />
                                      </div>
                                      <div className="card-content"> 
                                        <h5 className="font-weight-bold">Mainstream Education for Drop-out School Children
                                        </h5>

                                        <p>Bridge education and counselling for drop-out school children, paving them an avenue back to school.</p> 
                                        <Link to="/the-aspire-project"><Button className="yellowColorBtn">Donate</Button></Link>
                                      </div>
                                    </div>
                                  </Link>
                                </SwiperSlide> 
                           
                                 <SwiperSlide> 
                                    <Link to="/the-empower-project">
                                      <div className="programs-card" data-aos='fade-up'>
                                        <div className="card-top-stripe">The Empower Project</div>
                                        <div className="card-img">
                                          <img
                                            src={cardImage3}
                                            className="img-fluid"
                                            alt="cardimage"
                                          />
                                        </div>
                                        <div className="card-content"> 
                                          <h5 className="font-weight-bold">Income generation Program for Women from Low Income Groups
                                          </h5>

                                          <p>Skill training for women to help them with income generation opportunities so they can achieve financial independence.</p> 
                                          <Link to="/the-empower-project"><Button className="yellowColorBtn">Donate</Button></Link>
                                        </div>
                                      </div>
                                    </Link>
                                 </SwiperSlide>  
 
                            </Swiper>
                          </Col>
                          
                        </Row>
                      
                    </Container>    
             </section>
          
          </Fragment>
        )
      }
}


export default TheJoyProjectLatestPrograms