import React, { PureComponent, Fragment } from 'react';
import { Container, Col, Row, Button } from 'bootstrap-4-react';
import { Link } from 'react-router-dom';
import { FaFacebookF, FaInstagram, FaLinkedinIn, FaTwitter } from "react-icons/fa";
import * as apiUrl from '../../../apiUrl';
import axios from "axios";


class MediaInnerDescription extends PureComponent {

    convert(str) {
        var date = new Date(str),
          mnth = ("0" + (date.getMonth() + 1)).slice(-2),
          day = ("0" + date.getDate()).slice(-2);
        return [day, mnth ,date.getFullYear()].join("-");
      }

    //constructor
    constructor(props) {
        super(props);

        //member variables
        this.state = {
            main_title: "",
            main_description: "",
            slug: "",

            date: undefined,
            main_description2: "",
            media_type: "",
            selectedFiles: undefined,
            currentFile: undefined,
            imageURL: "",
            section_title1: "",
            section_description1: "",
            section_title2: "",
            section_description2: "",
            section_title3: "",
            section_description3: "",


            errors: {},



        };



    }

    //componentDidMount
    async componentDidMount() {



        var id = window.location.href.substring(window.location.href.lastIndexOf('/') + 1);
        axios.post(apiUrl.apiUrl + "/news/edit_news_by_slug/" + id).then((res) =>
            this.setState({
                main_title: res.data[0].main_title,
                main_description: res.data[0].main_description,
                section_title1: res.data[0].section_title1,
                section_description1: res.data[0].section_description1,
                slug: res.data[0].slug,
                section_title2: res.data[0].section_title2,
                section_description2: res.data[0].section_description2,
                section_title3: res.data[0].section_title3,
                section_description3: res.data[0].section_description3,
                date: res.data[0].date,
                main_description2: res.data[0].main_description2,
                media_type: res.data[0].media_type,

                imageURL:
                    apiUrl.apiUrl +
                    "/mvd/LatestNews/" +
                    res.data[0].section_background_image,




            })
        );


    }

    render() {

        let section_description1 = this.state.section_description1.split("\n");

        let section_description2 = this.state.section_description2.split("\n");

        let section_description3 = this.state.section_description3.split("\n");
        return (
            <Fragment>
                <section className="media-inner-description-sec">


                    <Container fluid className="wrap">
                        <div className="media-inner-head">
                            <Row className="media-inner-Row">
                                <Col md={12}>
                                    <div className="pb-3">
                                        <Button className="bottomBorder">Back to Blogs</Button>
                                    </div>
                                    <h1 className="common-heading" data-aos='fade-right'>{this.state.main_title}</h1>
                                {/*     <p className="common-para text-right" data-aos='fade-left'> {this.convert(this.state.date)}</p> */}
                                </Col>
                            </Row>
                        </div>
                    </Container>
                    <Container fluid className="wrap">
                        {/* <div className="social-sharing-buttons" data-aos='fade-up'>
                            <label className="share-label">Share</label>
                            <div className="footer-social-links mt-4">
                                <Link to='/about' alt="Facebook" target="_blank" className="fa fa-facebook"><FaFacebookF /></Link>
                                <Link to='/about' alt="Instagram" target="_blank" className="fa fa-instagram"><FaInstagram /></Link>
                                <Link to='/about' alt="Instagram" target="_blank" className="fa fa-linkedin"><FaLinkedinIn /></Link>
                                <Link to='/about' alt="Instagram" target="_blank" className="fa fa-twitter"><FaTwitter /></Link>
                            </div>
                        </div> */}
                        <Row>
                            <Col md={12}>
                                <div className="media-inner-des-smallhead pb-0">
                                    <h6 data-aos='fade-up'>{this.state.main_description}</h6>
                                    <p className="common-para" data-aos='fade-up'>{this.state.main_description2}
                                    </p>
                                </div>
                                <div className="media-inner-desc">
                                    <h6 className="media-inner-desc-heading" data-aos='fade-up'>{this.state.section_title1}</h6>
                                    <span className="common-para" data-aos='fade-up'>
                                        

                                        {


                                            section_description1.map((item, i) => (


                                                <p>{item}</p>





                                            ))


                                        }
                                     

                                    </span>
                                    <h6 className="media-inner-desc-heading" data-aos='fade-up'>{this.state.section_title2}</h6>
                                    <p className="common-para" data-aos='fade-up'>






                                        {


                                            section_description2.map((item, i) => ((


                                                <span> {item}  <br /></span>





                                            )))


                                        }
                                    </p>

                                    <h6 className="media-inner-desc-heading" data-aos='fade-up'>{this.state.section_title3}</h6>
                                    <p className="common-para" data-aos='fade-up'>



                                        {


                                            section_description3.map((item, i) => ((


                                                <span> {item}  <br /></span>





                                            )))


                                        }
                                    </p>

                                </div>

                            </Col>
                        </Row>
                    </Container>
                </section>

            </Fragment>
        )
    }
}


export default MediaInnerDescription