import React, { PureComponent, Fragment } from 'react';
import 'bootstrap-4-react';
import Header from './../common/header';
import Footer from './../common/footer';
import MediaBanner from './media-banner'; 
import MediaNews from './media-news';   

class MediaPage extends PureComponent{

    render(){

        return(
            <Fragment> 
            <Header /> 
            <MediaBanner/>
            <MediaNews/>  
            <Footer />
        </Fragment>
        )

    }
}

export default MediaPage