import React, { PureComponent, Fragment, useState } from 'react'; 
import { Container, Col, Row, Button} from 'bootstrap-4-react';  
import { Link } from 'react-router-dom'; 
import { FaArrowLeft, FaArrowRight, FaDownload } from "react-icons/fa";
import { Swiper, SwiperSlide } from 'swiper/react';

import SwiperCore, { Autoplay, Navigation, Pagination } from 'swiper'; 

// swiper core styles
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
SwiperCore.use([Autoplay, Navigation, Pagination]);

 


export default function NewsInnerDescription() {
 
 
  return (
      <Fragment>   
        
         <section className="media-inner-description-sec">
     
                
                  <Container fluid className="wrap"> 
                    {/* <Row>
                      <Col md={12}>

                     
                      </Col>
                    </Row> */}
                      <Row>
                           <Col md={12}>
                              <div className="pb-3 pb-lg-3">
                                <Button className="bottomBorder" > <Link to="/news"><FaArrowLeft /> Back to News </Link></Button>
                              </div> 
                               <div className="media-inner-des-smallhead"> 

                               {/* <div className="news-inner-banner-bottom"> 
                               <Swiper className="newsSlider" 
                                  slidesPerView={1}
                                  loop={true}
                                  autoplay={false}
                                  draggable={true} 
                                  navigation
                                  breakpoints={{ 
                                    320: {
                                      slidesPerView: 1,
                                    },
                                    420:{
                                        slidesPerView: 1,
                                    },
                                    768:{
                                        slidesPerView: 1,
                                    }
                                  }}
                                >

                                    <SwiperSlide>
                                        <div className="news-inner-banner-bottom-img">
                                            <img src={cardImage4} className="img-fluid" alt="cardimage" />
                                           
                                        </div> 
                                    </SwiperSlide>
                                    <SwiperSlide>
                                        <div className="news-inner-banner-bottom-video">
                                            <video playsinline="playsinline" autoplay="autoplay" muted="muted" loop="loop" controls>
                                                <source src={videoBanner} type="video/mp4" />
                                            </video>
                                          
                                        </div> 
                                    </SwiperSlide>
                                    <SwiperSlide>
                                        <div className="news-inner-banner-bottom-youtube">
                                          <iframe width="100%" height="315" src="https://www.youtube.com/embed/SjExas1grg0" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
                                         
                                        </div>
                                    </SwiperSlide>
                                </Swiper>
                               
                               </div> */}

                               <h6 className="media-inner-desc-heading" data-aos='fade-up'>Nutritional Support Program for Pregnant Women from BPL families</h6>
                               <p className="common-para" data-aos='fade-right'>Chennai-based Rahul Charitable Trust for Social Welfare organized/initiated a Nutrition Program for pregnant women on 2nd July 2022 in Virukampaakam, Chennai. This program supports pregnant women hailing from Below Poverty Line families in the slums of Chennai and rural areas of Chengalpattu, Kanchipuram, and Thiruvallur Districts, Tamil Nadu.</p>
                               <p className="common-para" data-aos='fade-right'>
                               There are 100 pregnant women enrolled in the programme and all of them would receive regular Nutrition support from the Trust. Founder Managing Trustee of RAHUL Charitable Trust for Social Welfare Mr Jothiponnambalam said, “A National Family Health Survey (NFHS) report released on 27th June 2019 by the Ministry of Health and Family Welfare states that in India 35.7% of children under 5 years of age are underweight and 38.4% are stunted due to malnutrition. This is a matter of grave concern and the recent Covid pandemic has made the crisis worse than ever. Therefore, as a responsible civil society organisation, we want to play an active role in eradicating malnutrition in our country. We all know that eating a nutritious diet during pregnancy is linked to good brain development and healthy birth weight for babies and can reduce the risk of many birth defects. A nutrition-rich diet will also reduce the risks of anaemia, as well as other unpleasant pregnancy symptoms such as fatigue and morning sickness. However, pregnant women living (BPL) Below the Poverty Line in the slums of Chennai and nearby villages do not have access to nutrition-rich food due to their poor economic status. We, RAHUL Charitable Trust for Social Welfare, provide pregnant women with  Nutrition Support in the form of fresh fruits and vegetables twice a month. Our Nutrition Kit for pregnant women includes Pomegranates, Date fruits, Almond, Eggs, Ghee, Gooseberry (Nellikkai), Lemons, Sweet Lemons, Multigrain Energy Powder, Drumstick leaves, Drumsticks, Carrot, and Curry leaf Powder. This programme starts from the 3rd month of their pregnancy and supports them until the day the newborn baby attains one year. This program actually supports 100 pregnant women and post-delivery, our nutrition support goes to 200 beneficiaries: the mothers and babies.
                               </p>
                            {/* PDF file news and events div */}
                   

                            <Container fluid className="news-inner-banner-bottom-pdf-container" data-aos='fade-up'>
                                <Row>
                                  <Col md={12}>
                                      <div className="news-inner-banner-bottom-pdf">
                                            <Row className="news-inner-banner-bottom-pdf-row">
                                              {/* <Col md={3}>
                                                  <div className="pdf-img">
                                                      <img src={pdfIcon} className="img-fluid" alt="cardimage" />
                                                  </div>

                                              </Col> */}
                                              <Col md={12}>
                                                  <div className="pdf-content">
                                                      <h6 className="media-inner-desc-heading">Nutritional Support Program for Pregnant Women from BPL families</h6>
                                                      <Link to="/news-gallery" className="yellowColorBtn"> View Gallery </Link>
                                                  </div>
                                              </Col>
                                            </Row>
                                      </div>
                                  </Col>
                                </Row> 
                            </Container>
                               <p className="common-para" data-aos='fade-right'>
                               The event was presided over by the Founder Managing Trustee of RAHUL charitable Trust Mr Jothiponnambalam. The Chief Guest of the event was Dr Viji Lakshmi, a famous gynaecologist in Chennai. Dr. Viji Lakshmi spoke on the importance of proper nutrition during pregnancy and precautionary methods to be followed for a healthy pregnancy and happy delivery. All the pregnant women who participated in the program received Nutrition kits. Advocate Ms. Priyadarshini anchored the event and Advocate Mr.Saravanan coordinated the event. Ms. Thasneem Masood, Director at RAHUL charitable Trust for Social Welfare, delivered a vote of thanks.
                               </p>
                               <p className="common-para" data-aos='fade-right'>
                               Rahul Charitable Trust for Social Welfare is a development-oriented Civil Society organization committed to serving poor communities in India through Education, Healthcare, Skill Development, and Livelihood support activities. It has been actively involved in many Social Development activities for the past three years.
                               </p>
  

                                </div>  
 
                           </Col>
                           {/* <Col md={12}>
                                  <div className="prev-next-btn">
                                      <Row>
                                        <Col className="text-left">
                                          <a href="#"><span className="prev-next-btn-link"><FaArrowLeft /> Previous</span></a>
                                        </Col>
                                        <Col className="text-right">
                                          <a href="#"><span className="prev-next-btn-link">Next <FaArrowRight /></span></a>
                                        </Col>
                                      </Row>
                                  </div>

                           </Col> */}
                      </Row>
                    </Container>   
             </section>
          
    </Fragment>
  );
}


 