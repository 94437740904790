import React, { PureComponent, Fragment } from 'react';
import { Container, Col, Row } from 'bootstrap-4-react';
// import { Pagination } from 'react-bootstrap';
import { Link } from 'react-router-dom';
// import {FaArrowRight}  from "react-icons/fa";
import newsImage1 from './../../assets/news1.png';
import newsImage2 from './../../assets/news2.png';
import * as apiUrl from '../../apiUrl';
import axios from "axios";


class MediaNews extends PureComponent {

    convert(str) {
        var date = new Date(str),
          mnth = ("0" + (date.getMonth() + 1)).slice(-2),
          day = ("0" + date.getDate()).slice(-2);
        return [day, mnth ,date.getFullYear()].join("-");
      }

    //constructor
    constructor(props) {
        super(props);

        //variables
        this.state = {
            section_title: "",
            section_description: "",





            data: [],
        };




    }

    //componentDidMount
    componentDidMount() {
        axios.post(apiUrl.apiUrl + "/news/edit_latest_news_second_section/1").then((res) =>
            this.setState({


                section_title: res.data[0].section_title,
                section_description: res.data[0].section_description,



            })
        );


        axios
            .post(apiUrl.apiUrl + "/news/fetch_news")
            .then((res) => this.setState({ data: res.data }));

    }

    render() {
        return (
            <Fragment>
                <section className="latestnews-sec media-news-sec">
                    <Container fluid className="wrap">
                        <div className="commonHeadingDiv">
                            <Row className="commonHeadingRow text-center">
                                <Col md={9}>
                                    <h1 className="common-heading" data-aos='fade-up'>{this.state.section_title} </h1>
                                    <p className="common-para" data-aos='fade-up'> {this.state.section_description}</p>
                                </Col>
                            </Row>
                        </div>
                    </Container>
                    <Container fluid className="wrap">
                        <Row>
                            {


                                this.state.data.map((item, i) => (

                                    i == 0 ?
                                        <Col md={4}>
                                            <Link to={{ pathname: `/blogs-inner/${item.slug}` }}>
                                                <div className="latest-news" data-aos='fade-up'>
                                                    <div className="news-top-circle">
                                                        <div className="news-top-circle-div greenColorBG">
                                                            <span>New!</span>
                                                        </div>
                                                    </div>
                                                    <div className="news-img">
                                                        <img src={apiUrl.apiUrl+'/mvd/LatestNews/'+item.section_background_image} className="img-fluid" alt="newsimage" />
                                                    </div>
                                                    <div className="news-content">
                                                        <Row className="newsDateRow">
                                                            <Col md={6}>
                                                                <p className="text-uppercase">{item.main_title}</p>
                                                            </Col>
                                                            <Col md={6} className="newsDate">
                                                                <p>{this.convert(item.date)}</p>
                                                            </Col>
                                                        </Row>
                                                        <h5 className="sub-heading">{item.main_description.substring(0,50)}</h5>
                                                        <p>{item.main_description2.substring(0,150)}</p>
                                                    </div>
                                                </div>
                                            </Link>
                                        </Col>
                                        :
                                        <Col md={4}>
                                            <Link to={{ pathname: `/blogs-inner/${item.slug}` }}>
                                                <div className="latest-news" data-aos='fade-up'>
                                                    <div className="news-img">
                                                        <img src={apiUrl.apiUrl+'/mvd/LatestNews/'+item.section_background_image} className="img-fluid" alt="newsimage" />
                                                    </div>
                                                    <div className="news-content">
                                                        <Row className="newsDateRow">
                                                            <Col md={6}>
                                                                <p className="text-uppercase">{item.main_title}</p>
                                                            </Col>
                                                            <Col md={6} className="newsDate">
                                                                <p>{this.convert(item.date)}</p>
                                                            </Col>
                                                        </Row>
                                                        <h5 className="sub-heading">{item.main_description.substring(0,50)}</h5>
                                                        <p>{item.main_description2.substring(0,150)}</p>
                                                    </div>
                                                </div>
                                            </Link>
                                        </Col>

                                ))


                            }

                        </Row>
                    </Container>
                    {/* <Container fluid>
                        <Row>
                            <Col md={12}>
                                <Pagination className="justify-content-center">  
                                    <Pagination.Item active>{1}</Pagination.Item> 
                                    <Pagination.Item>{2}</Pagination.Item>
                                    <Pagination.Item>{3}</Pagination.Item> 
                                    <Pagination.Ellipsis />
                                    <Pagination.Item>{7}</Pagination.Item>
                                    <Pagination.Next> NEXT <FaArrowRight/></Pagination.Next> 
                                </Pagination>
                            </Col>
                        </Row>
                        
                    </Container>  */}
                </section>

            </Fragment>
        )
    }
}


export default MediaNews