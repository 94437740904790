// import React, { Fragment, PureComponent} from 'react';
// import { Container, Col, Row } from 'bootstrap-4-react'; 
// import axios from 'axios';
// import * as apiUrl from '../../apiUrl';
// class ContactBanner extends PureComponent {
//     constructor(props) {
//         super(props);
//         this.state = {
    
//             selectedFiles: undefined,
//             currentFile: undefined,
//             section_title: "", 
    
//             errors: {},
//             imageURL: "",
//             data: {
    
//             }, 
    
//         };
    
//     }
//     componentDidMount() {
    
//         axios.post(apiUrl.apiUrl + '/contact/edit_contact_page_top_section/1') 
//             .then(res =>
      
//                 this.setState({
//                     section_title: res.data[0].section_title, 
      
//                     imageURL: apiUrl.apiUrl + "/mvd/ContactPageTopSection/" + res.data[0].section_background_image,
//                 })
      
//             ); 
      
//       }
//     render() {
//         return(

//             <Fragment>
//                 <Container fluid className="bannerSec contact-banner" style={{ backgroundImage: `url(` + this.state.imageURL + `)` }}>
//                     <div className="overlay"></div>
//                         <div className="bannerHeight wrap">
//                             <Row className="bannerRow text-center">  
//                                 <Col lg={8} md={7} className="bannerDiv">   
//                                 <h1 className="common-heading" data-aos='fade-up'>{this.state.section_title}</h1>
                         
//                                 </Col>  
//                             </Row> 
//                         </div>   
//                   </Container> 
//             </Fragment>
           
//         )
//     }
// }

// export default ContactBanner


import React, { PureComponent, Fragment } from 'react';
import { Container, Col, Row } from 'bootstrap-4-react';
import * as apiUrl from '../../apiUrl';
import axios from "axios";

class ContactBanner extends PureComponent {

    //constructor
    constructor(props) {
        super(props);

        //variables
        this.state = {
            media_type: "",
            selectedFiles: undefined,
            currentFile: undefined, 
            section_title: "",
            errors: {},
            imageURL: "",
            data: {

            },

        };


    }


    async componentDidMount() {



        var self = this;
        axios.post(apiUrl.apiUrl + '/contact/edit_contact_page_top_section/1')
            .then(res =>

                self.setState({ 
                    section_title: res.data[0].section_title,
                    media_type: res.data[0].media_type,
                    imageURL: apiUrl.apiUrl + "/mvd/ContactPageTopSection/" + res.data[0].section_background_image,
                })

            );




    }



    render() {

        let section_title = this.state.section_title.split(" ");

        return (
            <Fragment>

                <Container fluid className="bannerSec contact-banner" style={this.state.media_type == 'image' ? {
                    backgroundImage: `url("` + this.state.imageURL + `")`
                } : {}}>
                    <div className="overlay"></div>

                    {this.state.media_type == 'video' ?
                        <video src={this.state.imageURL} playsInline="playsInline" autoPlay="autoPlay" muted="muted" loop="loop">

                        </video>
                        : ''

                    }
                    <div className="bannerHeight wrap">
                        <Row className="bannerRow text-center">
                            <Col lg={8} md={7} className="bannerDiv">
                            <h1 className="common-heading" data-aos='fade-up'>

                                    <span>
                                        {


                                            section_title.map((item, i) => (
                                                (
                                                    i == 2 ?
                                                        <span key={"section_title_programs_" + i}>
                                                            {item} &nbsp;
                                                        </span> : <span key={"section_title_programs_" + i}>
                                                            {item} &nbsp;
                                                        </span>


                                                )

                                            ))


                                        }

                                    </span>

                            </h1>
                                {/* <h1 className="common-heading" data-aos='fade-up'> <span dangerouslySetInnerHTML={{ __html: dataxx.join(" ") }} /></h1> */}
                            </Col>
                        </Row>
                    </div>
                </Container>
            </Fragment>
        )
    }
}


export default ContactBanner