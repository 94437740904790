import React, { PureComponent, Fragment } from 'react';
import { Container, Col, Row } from 'bootstrap-4-react';   
class TheAspireProjectDescription extends PureComponent {
    render() { 
        return (
          <Fragment>  
         <section className="media-inner-description-sec">
             
                  <Container fluid className="wrap"> 
                      <Row>
                           <Col md={12}>
                               <div className="media-inner-des-smallhead"> 
                                    <h6 className="media-inner-desc-heading" data-aos='fade-up'>Why we need to help:</h6>
                                    <p className="common-para" data-aos='fade-right'>We believe that education plays an important role in building equity among society and making us stronger together. But unfortunately, thousands of children from families below the poverty line drop out of school every year, some of them to become child labourers. They resort to working in tea shops, welding units, petty shops, and other daily wage establishments, just to help support their family.</p>

                                    <p className="common-para" data-aos='fade-right'>With the right support and encouragement, we believe can help these children and their families break out of the generational cycle of poverty and set them on the path to success. 
                                    </p>

                                    <h6 className="media-inner-desc-heading" data-aos='fade-up'>How we help:</h6>
                                    <p className="common-para" data-aos='fade-right'>We identify children who have dropped out of school. We then give them bridge education or preparatory classes to compensate for the interval in their education. With motivation and counseling when they are done with their classes, we enroll them back into school. The children are now ready to get back to mainstream education.</p>
 
                                    <p className="common-para" data-aos='fade-right'>Through this initiative, we ensure every child has an avenue back to school. </p>
  




                                </div>  
 
                           </Col>
                      </Row>
                    </Container>   
             </section>
          
          </Fragment>
        )
      }
}


export default TheAspireProjectDescription