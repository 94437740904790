import React, { PureComponent, Fragment } from 'react';
import { Container } from 'bootstrap-4-react';
  
class LokeshwariBanner extends PureComponent {
    render() { 
        return ( 
            <Fragment>  
                {/* Media Banner Section */}
                  <Container fluid className="bannerSec lokeshwari-banner">
                    <div className="overlay"></div>   
                  </Container>  
          </Fragment> 
        )
      }
}


export default LokeshwariBanner