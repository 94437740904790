import React, { PureComponent, Fragment } from 'react';
import { Container, Col, Row } from 'bootstrap-4-react';
import Accordion from 'react-bootstrap/Accordion';
import * as apiUrl from '../../../apiUrl';
import axios from "axios";


class FellowshipFaq extends PureComponent {

  constructor(props) {
    super(props);
    this.state = {
      data: [],
      section_title3: "",

    };
  }

  componentDidMount() {
    axios
      .post(apiUrl.apiUrl + "/getinvolvedfellowship/fetch_faq")
      .then((res) => this.setState({ data: res.data }));


    axios.post(apiUrl.apiUrl + "/getinvolvedfellowship/edit_getinvolved_fellowship_page_second_section/1").then((res) =>
      this.setState({


        section_title3: res.data[0].section_title3,



      })
    );

    console.log(this.state);
  }

  render() {
    return (
      <Fragment>
        <section className="faqSec lightGreenBG">
          <Container fluid className="wrap">
            <div className="newsHeadingDiv">
              <Row className="newsHeadingRow">
                <Col md={12} className="text-center">
                  <h1 className="common-heading" data-aos='fade-up'>{this.state.section_title3}</h1>
                </Col>
              </Row>
            </div>
            <div className="faqDescriptions">

              <Row>
                <Col md={12}>
                  <div className="faqContentDiv">

                    <div className="faqaccordionDiv">
                      <Accordion defaultActiveKey="0" flush>
                        <span>
                          {


                            this.state.data.map((item, i) => (
                              (



                                <Accordion.Item eventKey={i} data-aos='fade-up'>
                                  <Accordion.Header className="fonsecaBold">{item.faq_title}</Accordion.Header>
                                  <Accordion.Body>
                                    <div className="accordionBodyInner">
                                      <p dangerouslySetInnerHTML={{__html: item.faq_description}}></p>
                                    </div>

                                  </Accordion.Body>
                                </Accordion.Item>


                              )

                            ))


                          }

                        </span>
                      </Accordion>
                    </div>
                  </div>
                </Col>


              </Row>
            </div>
          </Container>
        </section>

      </Fragment>
    )
  }
}


export default FellowshipFaq