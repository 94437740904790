import React, { PureComponent, Fragment } from 'react';
import 'bootstrap-4-react';
import Header from './../common/header';
import Footer from './../common/footer';
import StoriesBanner from './stories-banner';
// import StoriesVideo from './stories-video'; 
import StoriesLists from './stories-lists';   
import * as apiUrl from '../../apiUrl';
import axios from "axios";
import { Helmet } from "react-helmet";

class StoriesPage extends PureComponent{

    constructor(props) {
        super(props);
        this.state = {
          
            stories_meta_title:"",
            stories_meta_description:"",
        }
      }
    
      componentDidMount() { 
    
        axios.post(apiUrl.apiUrl + "/seometa/edit_seo_meta/1").then((res) => {
          this.setState({
            stories_meta_title: res.data[0].stories_meta_title,
            stories_meta_description: res.data[0].stories_meta_description,
    
      
          })
    
    
      }
      );
    
    
    }

    render(){

        return(
            <Fragment> 
            <Header /> 

            <Helmet> 
                <title>{this.state.stories_meta_title}</title>
                <meta name="description" content={this.state.stories_meta_description} />
            </Helmet> 

            <StoriesBanner /> 
            {/* <StoriesVideo/> */}
            <StoriesLists/>  
            <Footer />
        </Fragment>
        )

    }
}

export default StoriesPage