import React, { PureComponent, Fragment } from 'react';
import 'bootstrap-4-react';
import Header from './../../common/header';
import Footer from './../../common/footer';
import GetinvolvedCareersBanner from './getinvolved-careers-banner';
import CareerBanner from './careers-banner';
import NavTabs from './../nav-tabs';
import CareerBecomeagent from './become-an-agent';
import CareerForm from './career-form';
// import JobOpenings from './job-openings'; 
import scrollToComponent from 'react-scroll-to-component';
import * as apiUrl from '../../../apiUrl';
import axios from "axios";
import { Helmet } from "react-helmet";


class GetInvolvedCareerpage extends PureComponent {

  constructor(props) {
    super(props);
    this.state = {

      get_involved_career_meta_title: "",
      get_involved_career_meta_description: "",
    }
  }

  componentDidMount() {

    axios.post(apiUrl.apiUrl + "/seometa/edit_seo_meta/1").then((res) => {
      this.setState({
        get_involved_career_meta_title: res.data[0].get_involved_career_meta_title,
        get_involved_career_meta_description: res.data[0].get_involved_career_meta_description,


      })


    }
    );


    scrollToComponent(this.Blue, { offset: 0, align: 'middle', duration: 250, ease: 'inCirc' });
  }

  render() {

    return (
      <Fragment>
        <Header />

        <Helmet>
          <title>{this.state.get_involved_career_meta_title}</title>
          <meta name="description" content={this.state.get_involved_career_meta_description} />
        </Helmet>


        <GetinvolvedCareersBanner />
        <CareerBanner />
        <NavTabs ref={(section) => { this.Blue = section; }} />
        <CareerBecomeagent />
        {/* <JobOpenings/>  */}
        <CareerForm />

        <Footer />
      </Fragment>
    )

  }
}

export default GetInvolvedCareerpage