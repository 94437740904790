import React, { PureComponent, Fragment } from 'react';
import { Container, Col, Row } from 'bootstrap-4-react';
import getInvolvedImage from './../../../assets/group-of-happy-volunteers.jpg';
import * as apiUrl from '../../../apiUrl';
import axios from "axios";

class FellowshipBanner extends PureComponent {


    //constructor
    constructor(props) {
        super(props);

        //variables
        this.state = {
            section_title: "",
            section_description: "",
            media_type: "",
            selectedFiles: undefined,
            currentFile: undefined,

            errors: {},
            imageURL: "",

            media_type1: "",
            selectedFiles1: undefined,
            currentFile1: undefined,
            imageURL1: "",

            
            section_title2: "",
            section_title3: "",
            data: {},
        };

        
    }

    //componentDidMount
    componentDidMount() {
        axios.post(apiUrl.apiUrl + "/getinvolvedfellowship/edit_getinvolved_fellowship_page_second_section/1").then((res) =>
            this.setState({

                section_title: res.data[0].section_title,
                section_description: res.data[0].section_description,

                

                section_title2: res.data[0].section_title2,
                section_title3: res.data[0].section_title3,


                media_type: res.data[0].media_type,
                media_type1: res.data[0].media_type1,
                imageURL:
                    apiUrl.apiUrl +
                    "/mvd/GetinvolvedFellowshipSecondSection/" +
                    res.data[0].section_background_image,

                imageURL1:
                    apiUrl.apiUrl +
                    "/mvd/GetinvolvedFellowshipSecondSection/" +
                    res.data[0].front_image,
            })
        );

        console.log(this.state);
    }
 
  
    render() { 
        return (
          <Fragment>  
               <section className="fellowship-banner">
                    <Container fluid className="wrap get-involved-header "> 
                        <div className="commonHeadingDiv">
                            <Row className="commonHeadingRow text-center">  
                                <Col md={9}>   
                                <h1 className="common-heading" data-aos='fade-right'>{this.state.section_title}</h1>
                                <p className="common-para" data-aos='fade-left'> {this.state.section_description}
 </p>   
                                </Col>  
                            </Row> 
                        </div>  
                  </Container>  
                  <Container fluid className="wrap">
                      <Row className="get-involved-inner-banner">
                          <Col md={10} className="get-involved-image">
                            <img src={this.state.imageURL} className="img-fluid" alt="getinvolvedimage" data-aos='fade-up' /> 
                          </Col>
                      </Row>
                  </Container>
                  
             </section> 
          </Fragment>
        )
      }
}


export default FellowshipBanner;