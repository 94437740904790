import React, { PureComponent, Fragment } from 'react';
import { Container, Col, Row } from 'bootstrap-4-react';
import * as apiUrl from '../../apiUrl';
import axios from "axios";

class AboutBanner extends PureComponent {

    //constructor
    constructor(props) {
        super(props);

        //variables
        this.state = {
            media_type: "",
            selectedFiles: undefined,
            currentFile: undefined,
            section_title: "",
            section_description: "",

            errors: {},
            imageURL: "",
            data: {

            },

        };


    }


    async componentDidMount() {



        var self = this;
        axios.post(apiUrl.apiUrl + '/about/edit_about_page_top_section/1')
            .then(res =>

                self.setState({
                    section_title: res.data[0].section_title,
                    section_description: res.data[0].section_description,
                    media_type: res.data[0].media_type,
                    imageURL: apiUrl.apiUrl + "/mvd/AboutPageTopSection/" + res.data[0].section_background_image,
                })

            );




    }



    render() {

        var dataxx = this.state.section_title.split(" ");

        return (
            <Fragment>

                <Container fluid className="bannerSec aboutUsBanner" style={this.state.media_type == 'image' ? {
                    backgroundImage: `url("` + this.state.imageURL + `")`
                } : {}}>
                    <div className="overlay"></div>

                    {this.state.media_type == 'video' ?
                        <video src={this.state.imageURL} playsInline="playsInline" autoPlay="autoPlay" muted="muted" loop="loop">

                        </video>
                        : ''

                    }
                    <div className="bannerHeight wrap">
                        <Row className="bannerRow text-center">
                            <Col lg={8} md={7} className="bannerDiv">
                                <h1 className="common-heading" data-aos='fade-up'>



                                    {


                                        dataxx.map((item, i) => (
                                            (
                                                i == 2 || i == 4 || i == 5 ?
                                                    <span key={"section_title_dataxx" + i}>
                                                        {item} &nbsp;
                                                    </span> : <span key={"section_title_dataxx" + i}>
                                                        {item} &nbsp;
                                                    </span>


                                            )

                                        ))


                                    }




                                </h1>
                            </Col>
                        </Row>
                    </div>
                </Container>
            </Fragment>
        )
    }
}


export default AboutBanner