import React, { PureComponent, Fragment } from 'react';
import 'bootstrap-4-react';
import Header from './../../common/header';
import Footer from './../../common/footer';
import HopeProjectBanner from './the-hope-project-banner'; 
import HopeProjectForm from './the-hope-project-form';
import HopeProjectDescription from './the-hope-project-description';
import TheHopeLatestPrograms from './the-hope-project-latest-programs';   

class TheHopeProject extends PureComponent{

    render(){

        return(
            <Fragment> 
            <Header /> 
            <HopeProjectBanner/>
            <HopeProjectForm />
            <HopeProjectDescription />
            <TheHopeLatestPrograms/>  
            <Footer />
        </Fragment>
        )

    }
}

export default TheHopeProject