
import React, { PureComponent, Fragment } from 'react';
import { Container, Col, Row, Button } from 'bootstrap-4-react';
import { Link } from 'react-router-dom';
import { Swiper, SwiperSlide } from 'swiper/react';

import SwiperCore, { Autoplay } from 'swiper';

// swiper core styles
import 'swiper/css';
import storiesImage1 from './../../assets/Muthuselvi.JPG';
import storiesImage2 from './../../assets/amudha.JPG';
import storiesImage3 from './../../assets/lokeshwari.jpg';

import * as apiUrl from '../../apiUrl';
import axios from "axios";


SwiperCore.use([Autoplay]);
class DonateStories extends PureComponent {


  //constructor
  constructor(props) {
    super(props);

    //variables
    this.state = {
      section_title: "",
      stories: [],



    };




  }

  //componentDidMount
  componentDidMount() {
    axios.post(apiUrl.apiUrl + "/donate/edit_donate_page_fifth_section/1").then((res) =>
      this.setState({


        section_title: res.data[0].section_title,

      })
    );

    console.log(this.state);

    axios
      .post(apiUrl.apiUrl + "/stories/fetch_stories")
      .then((res) => {
        let stories = [];

        stories = res.data;
        this.setState({ stories });

        console.log(this.state.stories);

      });
  }

  render() {
    return (
      <Fragment>
        {/* Smilar Stories Section */}

        <section className="latestnews-sec programs-sec lightGreenBG latest-news-inner">
          <Container fluid className="wrap">
            <div className="commonHeadingDiv">
              <Row className="commonHeadingRow text-center">
                <Col md={9}>
                  <h1 className="common-heading" data-aos='fade-up'>{this.state.section_title}</h1>
                </Col>
              </Row>
            </div>
          </Container>
          <Container fluid className="wrap programs-div">
            <Row>
              <Col md={12}>
                <Swiper
                  className="smilarStoriesSlider"
                  spaceBetween={50}
                  slidesPerView={3}
                  loop={true}
                  autoplay={false}
                  draggable={true}
                  breakpoints={{
                    // when window width is >= 640px
                    320: {
                      slidesPerView: 1,
                    },
                    420: {
                      slidesPerView: 1,
                    },
                    768: {
                      slidesPerView: 3,
                    }
                  }}
                >
                  {


                    this.state.stories.map((item, i) => (


                      <SwiperSlide key={"stories-inner-" + i}>
                        <Link to={{ pathname: `/stories-inner/${item.slug}` }}>
                          <div className="our-stories" data-aos='fade-up'>
                            <div className="stories-img">
                              <img src={apiUrl.apiUrl + '/mvd/ourStoriesPost/' + item.section_background_image} className="img-fluid" alt="storiesimage" />
                            </div>
                            <div className="stories-content">
                              <h6 className="sub-heading">{item.main_title}</h6>
                              <p>{item.main_description.substring(0,200)}  ... </p>
                              <Button className="bottomBorder">Know More</Button>
                            </div>
                          </div>
                        </Link>
                      </SwiperSlide>

                    ))


                  }










                </Swiper>
              </Col>
            </Row>
          </Container>
        </section>
      </Fragment>
    )
  }
}


export default DonateStories