import React, { PureComponent, Fragment } from 'react';
import 'bootstrap-4-react';
import Header from './../../common/header';
import Footer from './../../common/footer';
import NewsInnerBanner from './news-inner-banner';  
import NewsInnerDescription from './news-inner-description'; 
import LatestNews from './latest-news';   

import * as apiUrl from '../../../apiUrl';
import axios from "axios";
import { Helmet } from "react-helmet";



class NewsInnerPage extends PureComponent{


    constructor(props) {
        super(props);
        this.state = {

            meta_title: "",
            meta_description: "",
        }
    }

    

    //componentDidMount
  async componentDidMount() {

    var slug = window.location.href.substring(window.location.href.lastIndexOf('/') + 1);
    axios.post(apiUrl.apiUrl + "/programs/edit_programs_by_slug/" + slug).then((res) =>
      this.setState({



        meta_title: res.data[0].meta_title,
        meta_description: res.data[0].meta_description,




      })
    );

    console.log(this.state);
  }

    render(){

        return(
            <Fragment> 
            <Header /> 

            <Helmet>
                    <title>{this.state.meta_title}</title>
                    <meta name="description" content={this.state.meta_description} />
                </Helmet>
            <NewsInnerBanner/> 
            <NewsInnerDescription /> 
            {/* <LatestNewsEvents/>   */}
            <Footer />
        </Fragment>
        )

    }
}

export default NewsInnerPage