import React, { PureComponent, Fragment } from 'react';
import { Container, Row, Col } from 'bootstrap-4-react';
import * as apiUrl from '../../apiUrl';
import axios from "axios";
  
class ProgramsBanner extends PureComponent {

//constructor
constructor(props) {
  super(props);

  //variables
  this.state = {
      media_type: "",
      section_title: "",
      
      selectedFiles: undefined,
      currentFile: undefined,

      errors: {},
      imageURL: "",

      data: {},
  };



}

//componentDidMount
componentDidMount() {
  axios.post(apiUrl.apiUrl + "/programs/edit_our_programs_banner_section/1").then((res) =>
      this.setState({


          section_title: res.data[0].section_title,
          
          media_type: res.data[0].media_type,
          imageURL:
              apiUrl.apiUrl +
              "/mvd/OurProgramsBannerSection/" +
              res.data[0].section_background_image,


      })
  );

  console.log(this.state);
}


    render() { 

      let section_title = this.state.section_title.split(" ");

        return ( 
            <Fragment>  
               
                  <Container fluid className="bannerSec programs-banner" style={this.state.media_type == 'image' ? {
                    backgroundImage: `url("` + this.state.imageURL + `")`
                } : {}}>
                    <div className="overlay"></div> 

                    {this.state.media_type == 'video' ?
                        <video src={this.state.imageURL} playsInline="playsInline" autoPlay="autoPlay" muted="muted" loop="loop">

                        </video>
                        : ''

                    }

                      <div className="bannerHeight wrap">
                            <Row className="bannerRow text-center">  
                                <Col lg={8} md={7} className="bannerDiv">    
                                <h1 className="common-heading" data-aos='fade-up'> 
                                
                                
                                <span>
                                        {


                                            section_title.map((item, i) => (
                                                (
                                                    i == 2 ?
                                                        <span key={"section_title_programs_" + i}>
                                                            {item} &nbsp;
                                                        </span> : <span key={"section_title_programs_" + i}>
                                                            {item} &nbsp;
                                                        </span>


                                                )

                                            ))


                                        }

                                    </span>
                                
                                </h1>
                                </Col>  
                            </Row> 
                        </div>  
                  </Container>  
          </Fragment> 
        )
      }
}


export default ProgramsBanner;