
import React, { PureComponent, Fragment } from 'react';
import { Container, Col, Row, Button } from 'bootstrap-4-react';
import { Link } from 'react-router-dom';
// import { ProgressBar  } from 'react-bootstrap'; 
import { Swiper, SwiperSlide } from 'swiper/react';

import SwiperCore, { Autoplay } from 'swiper';

// swiper core styles
import 'swiper/css';



import * as apiUrl from '../../../apiUrl';
import axios from "axios";

SwiperCore.use([Autoplay]);
class LatestPrograms extends PureComponent {

  //constructor
  constructor(props) {
    super(props);

    //variables
    this.state = {




      errors: {},


      data: {},
      programs: [],
    };



  }

  //componentDidMount
  async componentDidMount() {




    axios
      .post(apiUrl.apiUrl + "/programs/fetch_programs")
      .then((res) => {
        let programs = [];

        programs = res.data;
        this.setState({ programs });

        console.log(this.state.programs);

      });


  }





  render() {
    return (
      <Fragment>
        {/* Latestnews Section */}
        <section className="latestnews-sec programs-sec lightGreenBG latest-news-inner">
          <Container fluid className="wrap">
            <div className="commonHeadingDiv">
              <Row className="commonHeadingRow text-center">
                <Col md={9}>
                  <h1 className="common-heading" data-aos='fade-up'>Similar Programs</h1>
                </Col>
              </Row>
            </div>
          </Container>
          <Container fluid className="wrap programs-div">
            <Row>
              <Col md={12}>

                <Swiper
                  className="programSlider"
                  spaceBetween={50}
                  slidesPerView={3}
                  loop={true}
                  autoplay={true}
                  draggable={true}
                  breakpoints={{
                    // when window width is >= 640px
                    320: {
                      slidesPerView: 1,
                    },
                    420: {
                      slidesPerView: 1,
                    },
                    768: {
                      slidesPerView: 3,
                    }
                  }}
                >

                  {


                    this.state.programs.map((item, i) => (



                      <SwiperSlide key={"programs-card-" + i}>
                        <a href={ `/programs-inner/${item.slug}` }>
                          <div className="programs-card" data-aos='fade-up'>
                            <div className="card-top-stripe">{item.main_title}</div>
                            <div className="card-img">
                              <img
                                src={apiUrl.apiUrl + '/mvd/ourPrograms/' + item.section_background_image}
                                className="img-fluid"
                                alt="cardimage"
                              />
                            </div>
                            <div className="card-content">
                              <h5 className="font-weight-bold">{item.section_title}
                              </h5>


                              <div dangerouslySetInnerHTML={{ __html: '<p>' + item.main_description + '</p>' }}></div>
                              <Button className="yellowColorBtn">Donate</Button>
                            </div>
                          </div>
                        </a>
                      </SwiperSlide>

                    ))


                  }


                </Swiper>
              </Col>

            </Row>

          </Container>
        </section>

      </Fragment>
    )
  }
}


export default LatestPrograms