import React, { PureComponent, Fragment } from "react";
import { Container, Col, Row } from "bootstrap-4-react";
import empowerImage from "../../assets/Empower.svg";
import educateImage from "../../assets/Educate.svg";
import upliftImage from "../../assets/Uplift.svg";
import changeImage from "../../assets/Change.svg";

import * as apiUrl from '../../apiUrl';
import axios from "axios";

class OurValues extends PureComponent {

  constructor(props) {
    super(props)
    this.state = {
      formValues: [{ section_id: "", slider_image: [], slider_title: "", slider_description: "", imageURL: "" }],
      errors: [{ section_id: "", slider_image: "", slider_title: "", slider_description: "" }],
      errors2: { section_title: "" },
      formValues2: { section_title: "" }


    };

  }

  async componentDidMount() {



    var self = this;
    axios.post(apiUrl.apiUrl + '/about/edit_about_page_fifth_section')
      .then(res => {



        var formValues2 = self.state.formValues2;
        formValues2.section_title = res.data[0].section_title;






        self.setState({ formValues2 });



        var children = res.data[0].child;

        

        var formValues = [];

        children.forEach(element => {


          var dataxx = [];







          dataxx.section_id = element.id;

          dataxx.imageURL = apiUrl.apiUrl + "/mvd/AboutPageFifthSection/" + element.slider_image;


          dataxx.slider_title = element.slider_title;
          dataxx.slider_description = element.slider_description;

          formValues.push(dataxx);




        });



        self.setState({ formValues });







      });













  }



  render() {

    console.log(this.state.formValues);

    return (
      <Fragment>
        <section className="our-values">
          <Container fluid>
            <h2 className="common-heading text-center whiteColor" data-aos='fade-up'>
              {this.state.formValues2.section_title}
            </h2>
            <div className="value-card-section d-flex justify-content-center">
              <Row>

                {


                  this.state.formValues.map((item, i) => (
                    <Col md={6} key={"formValues_"+i}>
                      <div className="value-card" data-aos='fade-right'>
                        <div className="value-img">
                          <img src={item.imageURL} className="img-fluid" alt="empowerimage" />
                        </div>
                        <h2 className="fonsecaBold">{item.slider_title} </h2>
                        <p className="common-para">{item.slider_description}  </p>
                      </div>
                    </Col>

                  ))
                }
                {/* <Col md={6}>
                  <div className="value-card" data-aos='fade-left'>
                    <div className="value-img">
                      <img src={educateImage} className="img-fluid" alt="empowerimage" />
                    </div>
                    <h2 className="fonsecaBold">Be Compassionate</h2>
                    <p className="common-para">We extend a helping hand to anyone in need.</p>
                  </div>
                </Col>
                <Col md={6}>
                  <div className="value-card" data-aos='fade-right'>
                    <div className="value-img">
                      <img src={upliftImage} className="img-fluid" alt="empowerimage" />
                    </div>

                    <h2 className="fonsecaBold">Collaboration and Teamwork</h2>
                    <p className="common-para poppinsRegular">We believe collaboration brings people together.
                    </p>
                  </div>
                </Col>
                <Col md={6}>
                  <div className="value-card" data-aos='fade-left'>
                    <div className="value-img">
                      <img src={changeImage} className="img-fluid" alt="empowerimage" />
                    </div>
                    <h2 className="fonsecaBold">Inclusivity</h2>
                    <p className="common-para"> We value diversity and believe everyone makes a difference.</p>
                  </div>
                </Col> */}
              </Row>
            </div>
          </Container>
        </section>
      </Fragment>
    );
  }
}

export default OurValues;
