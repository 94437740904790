import React, { PureComponent, Fragment } from 'react';
import { Container, Col, Row, Button } from 'bootstrap-4-react'; 
import involvedImage1 from './../../assets/Get-Involved_1.gif'; 
import involvedImage2 from './../../assets/RCT Partner animation.gif'; 
import involvedImage3 from './../../assets/RCT Felloships animation.gif'; 
import { Link } from 'react-router-dom';
import * as apiUrl from '../../apiUrl';
import axios from "axios";


class HomeGetinvolved extends PureComponent {

   //constructor
   constructor(props) {
    super(props);

    //variables
    this.state = {

      section_title: "",

      section_description: "",
      know_more_text: "",
      know_more_link: "",

      errors: {},


      data: {},
      getinvolved:[],
    };

    //member functions
    this.inputRef = React.createRef();

  }

  //componentDidMount
  async componentDidMount() {
    axios.post(apiUrl.apiUrl + "/home/edit_home_page_get_involved_section/1").then((res) =>
      this.setState({
        section_title: res.data[0].section_title,

        section_description: res.data[0].section_description,

         know_more_text : res.data[0].know_more_text,
         know_more_link : res.data[0].know_more_link,




      })
    );

    axios
      .post(apiUrl.apiUrl + "/getinvolved/fetch_getinvolved")
      .then((res) => 
      
      {
        this.setState({ getinvolved: res.data })
        console.log(this.state.getinvolved);
      } 

      
        
        );

   
  }
 
    render() { 
        return (
          <Fragment> 
              <section className="getinvolved-sec">
                  <Container fluid className="wrap"> 
                        <div className="commonHeadingDiv">
                            <Row className="commonHeadingRow text-center">  
                                <Col md={9}>   
                                <h1 className="common-heading"  data-aos='fade-right'>{this.state.section_title}</h1>
                                <p className="common-para"  data-aos='fade-left'> {this.state.section_description} </p>    
                                </Col>  
                            </Row> 
                        </div>  
                  </Container>  
                  <Container fluid className="involved-sec"> 
    
                        <div className="involved-div">
                            <Row>  
                                 
                            {


this.state.getinvolved.map((item, i) => ( 
  
                                <Col md={4}>    
                                    <div className="involved-card" >
                                      <div className="involved-card-img">
                                        <img src={apiUrl.apiUrl+'/mvd/GetInvolved/'+item.section_background_image} className="img-fluid" alt="cardimage"  data-aos='fade-up' /> 
                                        <div className="involved-card-content">
                                          <h6  data-aos='fade-up'>{item.main_title}</h6>
                                        </div>
                                      </div>
                                      <div className="involved-para-content">
                                        <p  data-aos='fade-up'><span dangerouslySetInnerHTML={{ __html: item.main_description }} /></p>
                                      </div>
                                    </div>
                                </Col>   

))


}
                            </Row> 
                            <Row className="getInvolvedButtonrow text-center pt-3">  
                              <Col md={12}>    
                                <Link to={{ pathname: `${this.state.know_more_link}` }}><Button className="yellowColorBtn" >{this.state.know_more_text}</Button> </Link>
                              </Col>  
                            </Row> 
                        </div>  
                  </Container>   
             </section>
          </Fragment>
        )
      }
}


export default HomeGetinvolved