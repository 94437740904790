
import React, { PureComponent, Fragment } from 'react';
import { Container, Col, Row, Button } from 'bootstrap-4-react';
import { Link } from 'react-router-dom'; 
// import { ProgressBar  } from 'react-bootstrap'; 
import { Swiper, SwiperSlide } from 'swiper/react';

import SwiperCore, { Autoplay } from 'swiper'; 

// swiper core styles
import 'swiper/css';

// import cardImage1 from './../../../assets/OurProgramsthehopeproject.JPG'; 
import cardImage2 from './../../../assets/OurProgramstheaspireprojet.jpg'; 
import cardImage3 from './../../../assets/OurProgramsempowerproject.jpg';  
import cardImage4 from './../../../assets/OurProgramsjoyproject.jpg'; 

SwiperCore.use([Autoplay]);
class TheHopeLatestPrograms extends PureComponent {
    render() { 
        return (
          <Fragment> 
         {/* Latestnews Section */}
         <section className="latestnews-sec programs-sec lightGreenBG latest-news-inner">
                    <Container fluid className="wrap"> 
                        <div className="commonHeadingDiv">
                            <Row className="commonHeadingRow text-center">  
                                <Col md={9}>   
                                <h1 className="common-heading" data-aos='fade-up'>Similar Programs</h1> 
                                </Col>  
                            </Row> 
                        </div>  
                  </Container>    
                  <Container fluid className="wrap programs-div">
                      <Row>
                            <Col md={12}>

                            <Swiper
                          className="programSlider"
                          spaceBetween={50}
                          slidesPerView={3}
                          loop={true}
                          autoplay={true}
                          draggable={true}
                          breakpoints={{
                            // when window width is >= 640px
                            320: {
                              slidesPerView: 1,
                            },
                            420:{
                                slidesPerView: 1,
                            },
                            768:{
                                slidesPerView: 3,
                            }
                          }}
                        >
 
                               <SwiperSlide>
                                  <Link to="/the-aspire-project">
                                    <div className="programs-card" data-aos='fade-up'>
                                      <div className="card-top-stripe">The Aspire Project</div>
                                      <div className="card-img">
                                        <img
                                          src={cardImage2}
                                          className="img-fluid"
                                          alt="cardimage"
                                        />
                                      </div>
                                      <div className="card-content"> 
                                        <h5 className="font-weight-bold">Mainstream Education for Drop-out School Children
                                        </h5>

                                        <p>Bridge education and counselling for drop-out school children, paving them an avenue back to school.</p> 
                                        <Link to="/the-aspire-project"><Button className="yellowColorBtn">Donate</Button></Link>
                                      </div>
                                    </div>
                                  </Link>
                                </SwiperSlide> 
                           
                                 <SwiperSlide> 
                                    <Link to="/the-empower-project">
                                      <div className="programs-card" data-aos='fade-up'>
                                        <div className="card-top-stripe">The Empower Project</div>
                                        <div className="card-img">
                                          <img
                                            src={cardImage3}
                                            className="img-fluid"
                                            alt="cardimage"
                                          />
                                        </div>
                                        <div className="card-content"> 
                                          <h5 className="font-weight-bold">Income generation Program for Women from Low Income Groups
                                          </h5>

                                          <p>Skill training for women to help them with income generation opportunities so they can achieve financial independence.</p> 
                                          <Link to="/the-empower-project"><Button className="yellowColorBtn">Donate</Button></Link>
                                        </div>
                                      </div>
                                    </Link>
                                 </SwiperSlide> 
                                 <SwiperSlide>

                               
                                 <Link to="/the-joy-project">
                                    <div className="programs-card" data-aos='fade-up'>
                                      <div className="card-top-stripe">The Joy Project</div>
                                      <div className="card-img">
                                        <img
                                          src={cardImage4}
                                          className="img-fluid"
                                          alt="cardimage"
                                        />
                                      </div>
                                      <div className="card-content"> 
                                        <h5 className="font-weight-bold">Nutritional Support program for Pregnant women from families Below Poverty Line 
                                        </h5>

                                        <p>Maternity care for pregnant women. We assist them from the 2<sup>nd</sup> trimester until delivery and provide medical and nutritional support until the child turns 1. </p> 
                                        <Link to="/the-joy-project"><Button className="yellowColorBtn">Donate</Button></Link>
                                      </div>
                                    </div>
                                  </Link>
                                 </SwiperSlide> 
 
                            </Swiper>
                          </Col>
                          
                        </Row>
                      
                    </Container>    
             </section>
          
          </Fragment>
        )
      }
}


export default TheHopeLatestPrograms