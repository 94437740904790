import React, { PureComponent, Fragment } from 'react';
import { Container, Col, Row } from 'bootstrap-4-react';   
class MuthuselviDescription extends PureComponent {
    render() { 
        return (
          <Fragment>  
        <section className="media-inner-description-sec">
             
             <Container fluid className="wrap"> 
                 <Row>
                      <Col md={12}>
                          <div className="media-inner-des-smallhead"> 



                          <h6 className="media-inner-desc-heading" data-aos='fade-up'> College is but a dream</h6>
                          <p className="common-para" data-aos='fade-right'>This is Muthuselvi. She has always been determined to pursue her higher education. She did not give up even when she didn’t meet the academic criteria to join a government college. She got into a private college. </p>
                          <p className="common-para" data-aos='fade-right'>Unfortunately, this is when the pandemic hit. Her father, who worked as a driver, lost his job. They could no longer afford her college fees. They were on the verge of accepting it as the fate of being a girl – none of the women in her family went to college anyway. </p>
                          <p className="common-para" data-aos='fade-right'>But Muthuselvi, with her zealous spirit, refused to give up even then. She had heard about Rahul Charitable Trust for Social Welfare and reached out to Jo, the founder. Without any hesitation, he made sure her fees was taken care of. </p>
                          <p className="common-para" data-aos='fade-right'>Here she is now, in her 2nd year of college. When she graduates next year, she will be the first young girl in her family to go to college, and she doesn’t plan to stop there either! She has vowed to make sure that all the other girls in her family are also given the opportunity to pursue their higher education. </p>
 
                           </div>  

                      </Col>
                 </Row>
               </Container>   
        </section>
          
          </Fragment>
        )
      }
}


export default MuthuselviDescription