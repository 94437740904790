import React, { PureComponent, Fragment } from 'react';
import { Container, Col, Row, Button } from 'bootstrap-4-react';
// import { ProgressBar  } from 'react-bootstrap'; 
import axios from "axios";
import Logo from './../../../assets/RCT Horizontal Logo.gif';
import * as apiUrl from '../../../apiUrl';


class TheAspireProjectForm extends PureComponent {


  constructor(props) {
    super(props);
    this.state = {
      amount: "1000",
      //payment_method: "",
      fname: "",
      lname: "",
      email: "",
      mobile: "",
      customhide: true,
      errors: {},
    }


    this.displayRazorpay = this.displayRazorpay.bind(this, true);



  }

  //amount
  onChangeAmount = event => {

    this.setState({ amount: event.target.value });

    let errors = this.state.errors;
    errors["amount"] = "";

    this.setState({ errors: errors });


  }

  //amount
  onChangeFname = event => {

    this.setState({ fname: event.target.value });

    let errors = this.state.errors;
    errors["fname"] = "";

    this.setState({ errors: errors });


  }

  //amount
  onChangeLname = event => {

    this.setState({ lname: event.target.value });

    let errors = this.state.errors;
    errors["lname"] = "";

    this.setState({ errors: errors });


  }

  //email
  onChangeEmail = event => {

    this.setState({ email: event.target.value });

    let errors = this.state.errors;
    errors["email"] = "";

    this.setState({ errors: errors });


  }

  //amount
  onChangeMobile = event => {

    this.setState({ mobile: event.target.value });


    let errors = this.state.errors;
    errors["mobile"] = "";

    this.setState({ errors: errors });


  }

  handleValidation() {




    let amount = this.state.amount;
    let fname = this.state.fname;

    let lname = this.state.lname;
    let email = this.state.email;
    let mobile = this.state.mobile;

    //let payment_method = this.state.payment_method;

    const regex = /^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i;
    let errors = {};
    let formIsValid = true;


    if (!this.state.email || regex.test(this.state.email) === false) {

      errors['email'] = "Email is not valid";
      this.setState({
        errors: errors
      });
      formIsValid = false;
    }




    if (amount == "") {
      formIsValid = false;
      errors["amount"] = "Please Enter The Amount";
    }

    if (fname == "") {
      formIsValid = false;
      errors["fname"] = "Please Enter The First Name";
    }

    if (lname == "") {
      formIsValid = false;
      errors["lname"] = "Please Enter The Last Name";
    }

    if (email == "") {
      formIsValid = false;
      errors["email"] = "Please Enter The Email Address";
    }

    if (mobile == "") {
      formIsValid = false;
      errors["mobile"] = "Please Enter The Mobile Number";
    }

    /* if (payment_method == "") {
      formIsValid = false;
      errors["payment_method"] = "Please Enter The Payment Method";
    }
 */



    this.setState({ errors: errors });
    return formIsValid;
  }


  async displayRazorpay() {


    if (this.handleValidation()) {
      let src = "https://checkout.razorpay.com/v1/checkout.js";


      const res = new Promise((resolve) => {
        const script = document.createElement("script");
        script.src = src;
        script.onload = () => {
          resolve(true);
        };
        script.onerror = () => {
          resolve(false);
        };
        document.body.appendChild(script);
      });

      if (!res) {
        alert("Razorpay SDK failed to load. Are you online?");
        return;
      }


      var self = this;
      const result = await axios.post(apiUrl.apiUrl + "/razorpay/index.php", { amount: this.state.amount * 100, fname: self.state.fname, lname: self.state.lname, email: self.state.email, mobile: self.state.mobile });

      if (!result) {
        alert("Server error. Are you online?");
        return;
      }

      const { amount: amount, order_id: order_id, currency } = result.data;



      const options = {
        key: "rzp_live_3ExgbNxv2rmgSq", // Enter the Key ID generated from the Dashboard
        amount: amount.toString(),
        currency: currency,

        description: "Towards RCT Donation",
        image: { Logo },
        order_id: order_id,
        handler: async function (response) {
          const data = {
            orderCreationId: order_id,
            razorpayPaymentId: response.razorpay_payment_id,
            razorpayOrderId: response.razorpay_order_id,
            razorpaySignature: response.razorpay_signature,
          };

          const result = await axios.post(apiUrl.apiUrl + "/razorpay/success.php", data);



          if (result.data == 1) {
            
            document.getElementById("myModal").style.display = "block";
            self.setState({ amount: '1000' });
            self.setState({ fname: '' });
            self.setState({ lname: '' });
            self.setState({ email: '' });
            self.setState({ mobile: '' });
            self.setState({ payment_method: '' });
            self.setState({ customhide: true });

          }
        },
        prefill: {
          name: self.state.fname + " " + self.state.lname,
          email: self.state.email,
          contact: self.state.mobile,
        },

        theme: {
          color: "#61dafb",
        },
      };

      const paymentObject = new window.Razorpay(options);
      paymentObject.open();

    }
  }

  validate = evt => {
    var theEvent = evt || window.event;

    // Handle paste
    if (theEvent.type === 'paste') {
      key = evt.clipboardData.getData('text/plain');
    } else {
      // Handle key press
      var key = theEvent.keyCode || theEvent.which;
      key = String.fromCharCode(key);
    }
    var regex = /[0-9]|\./;
    if (!regex.test(key)) {
      theEvent.returnValue = false;
      if (theEvent.preventDefault) theEvent.preventDefault();
    }
  }

  onlyAlphabets = evt => {
    try {
      if (window.event) {
        var charCode = window.event.keyCode;
      }
      else if (evt) {
        var charCode = evt.which;
      }
      else { return true; }
      if ((charCode > 64 && charCode < 91) || (charCode > 96 && charCode < 123))
        return true;
      else
        evt.preventDefault();;
    }
    catch (err) {
      alert(err.Description);
    }
  }

  closeModalDialog() {
    document.getElementById("myModal").style.display = "none";
  }

  render() {
    return (
      <Fragment>
        <section className="programs-inner-form-sec">
          <div className="programs-inner-form-card" data-aos='fade-up'>
            <Container fluid className="wrap">
              <Row>
                <Col md={12}>
                  <div className="pb-3">
                    <Button className="bottomBorder">Back to Programs</Button>
                  </div>
                  <h4 className="fonsecaBold" >Mainstream Education For Drop-Out School Children</h4>

                  {/* <p className="font-weight-bold program-price"><span className="yellowColor fonsecaBold">₹ 50,000</span> of <span className="greenColor fonsecaBold">₹ 1,00,000</span> raised</p>  
                            <div className="progress-div">
                              <ProgressBar now={60} />
                            </div> */}
                  <div className="price-radio-button pt-3">
                    <input type="radio" id="one" name="price-radio" value="One" onClick={() => this.setState({ amount: '1000', customhide: true })} checked={this.state.amount == '1000'} onChange={e => { }} />
                    <label htmlFor="one">₹ 1000</label>
                    <input type="radio" id="two" name="price-radio" value="Two" onClick={() => this.setState({ amount: '2000', customhide: true })} checked={this.state.amount == '2000'} onChange={e => { }} />
                    <label htmlFor="two">₹ 2000</label>
                    <input type="radio" id="three" name="price-radio" value="Three" onClick={() => this.setState({ amount: '5000', customhide: true })} checked={this.state.amount == '5000'} onChange={e => { }} />
                    <label htmlFor="three">₹ 5000</label>
                    <input type="radio" id="four" name="price-radio" value="Four" onClick={() => this.setState({
                      amount: '',
                      customhide: false
                    })} checked={this.state.amount == ''} onChange={e => { }} />
                    <label htmlFor="four">Custom Amount</label>
                  </div>

                  {
                    this.state.customhide ? ''

                      : <div className="personal-info-form">
                        <div className="form-group col-md-4 padding-zero">
                          <label htmlFor="customamount">Custom Amount</label>
                          <input type="text" onKeyPress={this.validate} className="form-control"  placeholder="Enter Amount" id="customamount" value={this.state.amount} onChange={this.onChangeAmount} />
                        </div>

                        <span className="form-error">
                          {" "}
                          {this.state.errors["amount"]}{" "}
                        </span>
                      </div>
                  }

                  {/* <div className="select-payment-method">
                    <h6 className="fonsecaBold">Select Payment Method</h6>
                    <div>
                      <input type="radio" id="offline" name="payment-radio" value={this.state.payment_method} onClick={() => this.setState({ payment_method: 'offline' })} checked={this.state.payment_method == 'offline'} onChange={e => { }} />
                      <label htmlFor="offline">Offine Donation</label>
                      <input type="radio" id="online" name="payment-radio" value={this.state.payment_method} onClick={() => this.setState({ payment_method: 'online' })} checked={this.state.payment_method == 'online'} onChange={e => { }} />
                      <label htmlFor="online">Online Donation</label>

                      <span className="form-error">
                        {" "}
                        {this.state.errors["payment_method"]}{" "}
                      </span>
                    </div>
                  </div> */}
                  <div className="personal-info-div">
                    <h6 className="fonsecaBold">Personal Info</h6>
                    <div className="personal-info-form row">
                      <div className="form-group col-md-4">
                        <label htmlFor="fname">First Name *</label>
                        <input type="text" className="form-control" onKeyPress={this.onlyAlphabets} placeholder="First Name" id="fname" onChange={this.onChangeFname} value={this.state.fname} />

                        <span className="form-error">
                          {" "}
                          {this.state.errors["fname"]}{" "}
                        </span>
                      </div>
                      <div className="form-group col-md-4">
                        <label htmlFor="lname">Last Name *</label>
                        <input type="text" className="form-control" onKeyPress={this.onlyAlphabets} placeholder="Last Name" id="lname" onChange={this.onChangeLname} value={this.state.lname} />

                        <span className="form-error">
                          {" "}
                          {this.state.errors["lname"]}{" "}
                        </span>
                      </div>
                    </div>

                    <div className="personal-info-form row">
                      <div className="form-group col-md-4">
                        <label htmlFor="fname">Email Address *</label>
                        <input type="text" className="form-control" placeholder="Email Address" id="email" onChange={this.onChangeEmail} value={this.state.email} />

                        <span className="form-error">
                          {" "}
                          {this.state.errors["email"]}{" "}
                        </span>
                      </div>
                      <div className="form-group col-md-4">
                        <label htmlFor="lname">Mobile Number *</label>
                        <input type="text" className="form-control" placeholder="Mobile Number" id="mobile" onKeyPress={this.validate} onChange={this.onChangeMobile} value={this.state.mobile} />
                        <span className="form-error">
                          {" "}
                          {this.state.errors["mobile"]}{" "}
                        </span>
                      </div>
                    </div>
                  </div>
                  <div className="donation-total-div">
                    <h6 className="fonsecaBold">Donation Total: <span className="greenColor">₹ {this.state.amount}</span></h6>
                  </div>
                  <Button className="yellowColorBtn" onClick={this.displayRazorpay}>DONATE NOW</Button>
                </Col>
              </Row>
            </Container>
          </div>
        </section>

        <Container>
            <Row>
              <div
                className="modal form-modal "
                tabIndex="-1"
                role="dialog"
                id="myModal"
              >
                <div className="modal-dialog" role="document">
                  <div className="modal-content">
                    <div className="modal-header">
                      <button
                        type="button"
                        className="close"
                        onClick={this.closeModalDialog}
                        data-dismiss="modal"
                        aria-label="Close"
                      >
                        <span aria-hidden="true">&times;</span>
                      </button>
                    </div>
                    <div className="modal-body">
                      <h2 className="canelaMedium secHeading">
                      Thank you for your contribution to help us fulfil our mission.
                      </h2>
                    </div>
                    <div className="modal-footer">
                      <button
                        type="button"
                        className="yellowColorBtn"
                        data-dismiss="modal"
                        onClick={this.closeModalDialog}
                      >
                        Close
                      </button>
                    </div>
                  </div>
                </div>
                <div className="modal-backdrop"></div>
              </div>
            </Row>
          </Container>
      </Fragment>
    )
  }
}


export default TheAspireProjectForm;