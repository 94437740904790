import React, { PureComponent, Fragment } from 'react';
import { Container, Col, Row } from 'bootstrap-4-react';   
class LokeshwariDescription extends PureComponent {
    render() { 
        return (
          <Fragment>  
        <section className="media-inner-description-sec">
             
             <Container fluid className="wrap"> 
                 <Row>
                      <Col md={12}>
                          <div className="media-inner-des-smallhead"> 

                          <h6 className="media-inner-desc-heading" data-aos='fade-up'>Listening and hearing are two different senses.</h6>
                          <p className="common-para" data-aos='fade-right'>Lokeswari, the elder of two sisters, was born with a hearing impairment. When she was just a little girl, she was examined by a medical professional and was prescribed a pocket hearing aid machine. It was expensive, but her family pitched in all their resources to get her one. </p>
                          <p className="common-para" data-aos='fade-right'>By the time she reached class 11, the machine started failing due to wear and tear. The doctor advised them to get a new one – but they could not afford it. </p>
                          <p className="common-para" data-aos='fade-right'>This greatly affected Lokeswari as she now depended on others for everything from crossing the road to listening in class at school. She started withdrawing from everyone around her. </p>
                          <p className="common-para" data-aos='fade-right'>But her family did not give up hope. They approached Rahul Charitable Trust.  They’d heard about some of the work the trust has done for the community around them. They asked to meet with Jo, the founder, and explained the situation they were facing with Lokeswari’s hearing aid. </p>
                          <p className="common-para" data-aos='fade-right'>They were ecstatic when Jo arranged for Lokeswari’s new hearing aid! She has since gone back to school as a self-assured, confident and independent young lady.  </p>

 

                           </div>  

                      </Col>
                 </Row>
               </Container>   
        </section>
          
          </Fragment>
        )
      }
}


export default LokeshwariDescription