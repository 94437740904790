import React, { PureComponent, Fragment } from 'react';
import Header from './../common/header';
import Footer from './../common/footer';
import AboutBanner from './about-us-banner';
import AboutUsAbout from './about-us-about';
import OurValues from './oru-values';
import AboutUsPurpleSection from './about-us-purple-section';
import DonationSection from './donation-section';
import FounderStory from './founder-story';
// import AnnualReports from './annual-reports';
import * as apiUrl from '../../apiUrl';
import axios from "axios";
import { Helmet } from "react-helmet";

class AboutPage extends PureComponent {

  constructor(props) {
    super(props);
    this.state = {
      
      about_meta_title:"",
      about_meta_description:"",
    }
  }

  componentDidMount() { 

    axios.post(apiUrl.apiUrl + "/seometa/edit_seo_meta/1").then((res) => {
      this.setState({
        about_meta_title: res.data[0].about_meta_title,
        about_meta_description: res.data[0].about_meta_description,

  
      })


  }
  );


}

 
    render() { 
        return (
          <Fragment>
            <Header /> 

            <Helmet> 
                <title>{this.state.about_meta_title}</title>
                <meta name="description" content={this.state.about_meta_description} />
            </Helmet> 


            <AboutBanner/>
            <AboutUsPurpleSection />
            <DonationSection />
            <AboutUsAbout />
            <OurValues/>
          
            <FounderStory />
            {/* <AnnualReports /> */}
            <Footer />
          </Fragment>
        )
      }
}


export default AboutPage