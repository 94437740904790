import React, { PureComponent, Fragment } from 'react';
import 'bootstrap-4-react';
import Header from './../../common/header';
import Footer from './../../common/footer';
import MuthuselviBanner from './muthuselvi-banner'; 
 import MuthuselviDescription from './muthuselvi-description';
import MuthuselviSmilarStories from './stories-inner-muthuselvi-smilarstories';   

class MuthuselviPage extends PureComponent{

    render(){

        return(
            <Fragment> 
            <Header /> 
            <MuthuselviBanner/>
            <MuthuselviDescription />
            <MuthuselviSmilarStories/>  
            <Footer />
        </Fragment>
        )

    }
}

export default MuthuselviPage