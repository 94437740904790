import React, { PureComponent, Fragment } from 'react';
import { Container, Col, Row, Button } from 'bootstrap-4-react';

import { Link } from 'react-router-dom';


import cardImage1 from './../../assets/OurProgramsthehopeproject.jpg';
import cardImage2 from '../../assets/OurProgramstheaspireprojet.jpg';
import cardImage3 from '../../assets/OurProgramsempowerproject.jpg';


import cardImage4 from '../../assets/OurProgramsjoyproject.jpg';

import * as apiUrl from '../../apiUrl';
import axios from "axios";

class ProgramsList extends PureComponent {

  //constructor
  constructor(props) {
    super(props);

    //variables
    this.state = {

      section_title: "",
      section_description: "",



      errors: {},


      data: {},
      programs: [],
    };



  }

  //componentDidMount
  async componentDidMount() {
    axios.post(apiUrl.apiUrl + "/programs/edit_our_programs_second_section/1").then((res) =>
      this.setState({


        section_title: res.data[0].section_title,
        section_description: res.data[0].section_description,



      })
    );



    axios
      .post(apiUrl.apiUrl + "/programs/fetch_programs")
      .then((res) => {
        let programs = [];

        programs = res.data;
        this.setState({ programs });

        console.log(this.state.programs);

      });


  }

  render() {
    return (
      <Fragment>

        <section className="programs-sec programs-page-sec">
          <Container fluid className="wrap">
            <div className="commonHeadingDiv">
              <Row className="commonHeadingRow text-center blackColor">
                <Col md={9}>
                  <h1 className="common-heading" data-aos='fade-right'>{this.state.section_title}</h1>
                  <p className="common-para" data-aos='fade-left'>{this.state.section_description} </p>
                </Col>
              </Row>
            </div>
          </Container>
          <Container fluid className="wrap programs-div">


            {


              this.state.programs.map((item, i) => (
                <>
                  {

                    i === 0 ?

                      <Link to={{ pathname: `/programs-inner/${item.slug}` }}>
                        <div className="programs-card" data-aos='fade-up'>
                          <Row className="margin-zero">
                            <Col md={6} className="card-bg-content">
                              <div className="card-top-stripe">{item.main_title}</div>
                              <div className="card-content">

                                <h5 className="font-weight-bold">{item.section_title}</h5>

                                <p>{item.main_description}
                                </p>
                                <Button className="yellowColorBtn">Donate</Button>
                              </div>
                            </Col>

                            <Col md={6} className="card-bg-img">

                              <div className="card-img">
                                <div className="card-bg-imgs">
                                  <img src={apiUrl.apiUrl + '/mvd/ourPrograms/' + item.section_background_image} className="img-fluid" alt="cardimage" />
                                </div>
                              </div>

                            </Col>
                          </Row>
                        </div>
                      </Link>
                      : ''


                  }
                </>

              ))


            }

            <Row>

              {


                this.state.programs.map((item, i) => (
                  <>
                    {



                      i > 0 ?
                        <Col md={4}>
                          <Link to={{ pathname: `/programs-inner/${item.slug}` }}>
                            <div className="programs-card" data-aos='fade-up'>
                              <div className="card-top-stripe">{item.main_title}</div>
                              <div className="card-img">
                                <img
                                  src={apiUrl.apiUrl + '/mvd/ourPrograms/' + item.section_background_image}
                                  className="img-fluid"
                                  alt="cardimage"
                                />
                              </div>
                              <div className="card-content">
                                <h5 className="font-weight-bold">
                                  {item.section_title}
                                </h5>
                                <div dangerouslySetInnerHTML={{ __html: '<p>' + item.main_description + '</p>' }}></div>


                                <Button className="yellowColorBtn">Donate</Button>
                              </div>
                            </div>
                          </Link>
                        </Col>
                        : ''


                    }
                  </>
                ))






              }

            </Row>
          </Container>
          {/* <Container fluid>
                        <Row>
                            <Col md={12}>
                                <Pagination className="justify-content-center">  
                                    <Pagination.Item active>{1}</Pagination.Item> 
                                    <Pagination.Item>{2}</Pagination.Item>
                                    <Pagination.Item>{3}</Pagination.Item> 
                                    <Pagination.Ellipsis />
                                    <Pagination.Item>{7}</Pagination.Item>
                                    <Pagination.Next> NEXT <FaArrowRight/> </Pagination.Next> 
                                </Pagination>
                            </Col>
                        </Row>
                        
                    </Container>  */}
        </section>



      </Fragment>
    )
  }
}


export default ProgramsList