import React, { PureComponent, Fragment } from 'react';
import 'bootstrap-4-react';
import Header from './../../common/header';
import Footer from './../../common/footer';
import StoriesInnerBanner from './stories-inner-banner';
import StoriesInnerDescription from './stories-inner-description';
import SmilarStories from './stories-inner-smilarstories';

import * as apiUrl from '../../../apiUrl';
import axios from "axios";
import { Helmet } from "react-helmet";


class StoriesInnerPage extends PureComponent {

    constructor(props) {
        super(props);
        this.state = {

            meta_title: "",
            meta_description: "",
        }
    }

    //componentDidMount
    async componentDidMount() {

        var slug = window.location.href.substring(window.location.href.lastIndexOf('/') + 1);
        axios.post(apiUrl.apiUrl + "/stories/edit_stories_by_slug/" + slug).then((res) =>
            this.setState({



                meta_title: res.data[0].meta_title,
                meta_description: res.data[0].meta_description,




            })
        );

        console.log(this.state);
    }



    render() {

        return (
            <Fragment>
                <Header />

                <Helmet>
                    <title>{this.state.meta_title}</title>
                    <meta name="description" content={this.state.meta_description} />
                </Helmet>
                <StoriesInnerBanner />
                <StoriesInnerDescription />
                <SmilarStories />
                <Footer />
            </Fragment>
        )

    }
}

export default StoriesInnerPage