import React, { PureComponent, Fragment } from 'react';
import { Container, Col, Row } from 'bootstrap-4-react';
import Progress from './progress';
import { Tab, Nav } from 'react-bootstrap';
import { NavLink } from 'react-router-dom';
import * as apiUrl from '../../apiUrl';
import axios from "axios";


class NavTabs extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      navclassName: "",
      section_title: "",
      section_description: "",
      media_type: "",
      selectedFiles: undefined,
      currentFile: undefined,

      errors: {},
      imageURL: "",
      media_type1: "",
      selectedFiles1: undefined,
      currentFile1: undefined,
      imageURL1: "",

      read_more_text1: "",
      read_more_link1: "",

      read_more_text2: "",
      read_more_link2: "",


      read_more_text3: "",
      read_more_link3: "",


      read_more_text4: "",
      read_more_link4: "",
      section_title2: "",
      section_title3: "",
      data: {},

    }

  }
  state = {
    scrollPostion: 0

  }


  listenToScrollEvent = () => {
    document.addEventListener("scroll", () => {
      requestAnimationFrame(() => {
        this.calculateScrollDistance();
      });
    });
  }

  calculateScrollDistance = () => {
    const scrollTop = window.pageYOffset; // how much the user has scrolled by
    const winHeight = window.innerHeight;
    const docHeight = this.getDocHeight();

    const totalDocScrollLength = docHeight - winHeight;
    const scrollPostion = Math.floor(scrollTop / totalDocScrollLength * 100)

    this.setState({
      scrollPostion,
    });
  }

  getDocHeight = () => {
    return Math.max(
      document.body.scrollHeight, document.documentElement.scrollHeight,
      document.body.offsetHeight, document.documentElement.offsetHeight,
      document.body.clientHeight, document.documentElement.clientHeight
    );
  }

  componentDidMount() {
    this.listenToScrollEvent();
    window.addEventListener("scroll", this.handleScroll);
  }


  handleScroll = () => {
    if (window.pageYOffset > 1500) {
      if (!this.state.navclassName) {
        this.setState({ navclassName: "navtabactive" });
      }
    } else {
      if (this.state.navclassName) {
        this.setState({ navclassName: "" });
      }
    }

  }




  //componentDidMount
  componentDidMount() {
    axios.post(apiUrl.apiUrl + "/getinvolvedvolunteers/edit_getinvolved_volunteers_page_second_section/1").then((res) =>
      this.setState({

        section_title: res.data[0].section_title,
        section_description: res.data[0].section_description,

        read_more_text1: res.data[0].read_more_text1,
        read_more_link1: res.data[0].read_more_link1,
        read_more_text2: res.data[0].read_more_text2,
        read_more_link2: res.data[0].read_more_link2,

        read_more_text3: res.data[0].read_more_text3,
        read_more_link3: res.data[0].read_more_link3,
        read_more_text4: res.data[0].read_more_text4,
        read_more_link4: res.data[0].read_more_link4,
        media_type: res.data[0].media_type,
        media_type1: res.data[0].media_type1,
        section_title2: res.data[0].section_title2,
        section_title3: res.data[0].section_title3,
        imageURL:
          apiUrl.apiUrl +
          "/mvd/GetinvolvedVolunteersSecondSection/" +
          res.data[0].section_background_image,

        imageURL1:
          apiUrl.apiUrl +
          "/mvd/GetinvolvedVolunteersSecondSection/" +
          res.data[0].front_image,
      })
    );

    console.log(this.state);
  }



  render() {
    return (
      <Fragment>

        <section  >
          <div className="">
            <div className={this.state.navclassName} >


              <Container fluid className="wrap">
                <Row className="get-involved-inner-banner">
                  <Col md={10} className="get-involved-image">
                    <Progress className="progress" scroll={this.state.scrollPostion + '%'} />
                    <div className="nav-wrap">

                      <div className="wrp-inner">
                        <Tab.Container defaultActiveKey="first">
                          <Nav variant="pills">
                            <Nav.Item>
                              <NavLink to={{ pathname: `/${this.state.read_more_link1}` }}> <span className="number">01</span>{this.state.read_more_text1}</NavLink>
                            </Nav.Item>
                            <Nav.Item>
                              <NavLink to={{ pathname: `/${this.state.read_more_link2}` }}><span className="number"> 02</span> {this.state.read_more_text2}</NavLink>
                            </Nav.Item>
                            <Nav.Item>
                              <NavLink to={{ pathname: `/${this.state.read_more_link3}` }}><span className="number">03</span> {this.state.read_more_text3}</NavLink>
                            </Nav.Item>
                            <Nav.Item>
                              <NavLink to={{ pathname: `/${this.state.read_more_link4}` }}><span className="number">04</span> {this.state.read_more_text4}</NavLink>
                            </Nav.Item>
                          </Nav>
                        </Tab.Container>
                      </div>
                    </div>
                  </Col>
                </Row>
              </Container>



            </div>
          </div>
        </section>
      </Fragment>
    )
  }
}


export default NavTabs