import React, { PureComponent, Fragment } from 'react';
import { Container, Col, Row } from 'bootstrap-4-react';
import getInvolvedImage from './../../../assets/happy-girl-and-volunteers.jpg';
import * as apiUrl from '../../../apiUrl';
import axios from "axios";

class CareerBecomeagent extends PureComponent {

    //constructor
    constructor(props) {
        super(props);

        //variables
        this.state = {
            section_title: "",
            section_description: "",
            media_type: "",
            selectedFiles: undefined,
            currentFile: undefined,

            errors: {},
            imageURL: "",

            media_type1: "",
            selectedFiles1: undefined,
            currentFile1: undefined,
            imageURL1: "",

            read_more_text1: "",
            read_more_link1: "",

            read_more_text2: "",
            read_more_link2: "",


            read_more_text3: "",
            read_more_link3: "",


            read_more_text4: "",
            read_more_link4: "",
            section_title2: "",
            section_title3: "",

            media_type2: "",
            selectedFiles2: undefined,
            currentFile2: undefined,
            imageURL2: "",

            section_title4: "",
            

            data: {},
        };


    }

    //componentDidMount
    componentDidMount() {
        axios.post(apiUrl.apiUrl + "/getinvolvedcareers/edit_getinvolved_careers_page_second_section/1").then((res) =>
            this.setState({

                section_title: res.data[0].section_title,
                section_description: res.data[0].section_description,

                read_more_text1: res.data[0].read_more_text1,
                read_more_link1: res.data[0].read_more_link1,
                read_more_text2: res.data[0].read_more_text2,
                read_more_link2: res.data[0].read_more_link2,

                read_more_text3: res.data[0].read_more_text3,
                read_more_link3: res.data[0].read_more_link3,
                read_more_text4: res.data[0].read_more_text4,
                read_more_link4: res.data[0].read_more_link4,

                section_title2: res.data[0].section_title2,
                section_title3: res.data[0].section_title3,

                section_title4: res.data[0].section_title4,
                section_description4: res.data[0].section_description4,

                media_type: res.data[0].media_type,
                media_type1: res.data[0].media_type1,

                media_type2: res.data[0].media_type2,


                imageURL:
                    apiUrl.apiUrl +
                    "/mvd/GetinvolvedCareersSecondSection/" +
                    res.data[0].section_background_image,

                imageURL1:
                    apiUrl.apiUrl +
                    "/mvd/GetinvolvedCareersSecondSection/" +
                    res.data[0].front_image,

                imageURL2:
                    apiUrl.apiUrl +
                    "/mvd/GetinvolvedCareersSecondSection/" +
                    res.data[0].third_image,
            })
        );

        console.log(this.state);
    }

    render() {
        return (
            <Fragment>
                <section className="become-an-agent-sec">
                    <Container fluid className="wrap">
                        <Row>
                            <Col lg={5} className="become-an-agent-row">
                                <div className="become-an-agent-description">
                                    <h4 data-aos='fade-right'>{this.state.section_title2}</h4>
                                    <p data-aos='fade-right'>{this.state.section_title3}</p>
                                </div>
                            </Col>
                            <Col lg={7}>
                                <div className="become-an-agent-image">
                                    <img src={this.state.imageURL1} className="img-fluid" alt="getinvolvedimage" data-aos='fade-left' />
                                </div>
                            </Col>

                        </Row>
                    </Container>

                </section>

            </Fragment>
        )
    }
}


export default CareerBecomeagent