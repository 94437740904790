import React, { PureComponent, Fragment } from 'react';
import 'bootstrap-4-react';
import Header from './../../common/header';
import Footer from './../../common/footer';
import TheJoyProjectBanner from './the-joy-project-banner'; 
import TheJoyProjectForm from './the-joy-project-form';
import TheJoyProjectDescription from './the-joy-project-description';
import TheJoyProjectLatestPrograms from './the-joy-project-latest-programs';   

class TheJoyProjectPage extends PureComponent{

    render(){

        return(
            <Fragment> 
            <Header /> 
            <TheJoyProjectBanner/>
            <TheJoyProjectForm />
            <TheJoyProjectDescription />
            <TheJoyProjectLatestPrograms/>  
            <Footer />
        </Fragment>
        )

    }
}

export default TheJoyProjectPage