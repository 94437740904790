import React, { PureComponent, Fragment } from 'react';
import { Container, Row, Col } from 'bootstrap-4-react';
import * as apiUrl from '../../apiUrl';
import axios from "axios";
  
class NewsBanner extends PureComponent {
 


    render() { 
 

        return ( 
            <Fragment>  
               
                  <Container fluid className="bannerSec programs-banner news-events-banner">
                    <div className="overlay"></div> 

                   

                      <div className="bannerHeight wrap">
                            <Row className="bannerRow text-center">  
                                <Col lg={8} md={7} className="bannerDiv">    
                                <h1 className="common-heading" data-aos='fade-up'> 
                                
                                
                                Stay up to date with our news

                                
                                </h1>
                                </Col>  
                            </Row> 
                        </div>  
                  </Container>  
          </Fragment> 
        )
      }
}


export default NewsBanner;