import React, { PureComponent, Fragment } from 'react';
import { Container, Col, Row } from 'bootstrap-4-react';
import * as apiUrl from '../../../apiUrl';
import axios from "axios";


class ProgramsInnerDescription extends PureComponent {

    constructor(props) {
        super(props);
        this.state = {
            main_title: "",
            main_description: "",
            slug: "",
            media_type: "",
            selectedFiles: undefined,
            currentFile: undefined,
            imageURL: "",
            section_title: "",
            section_title1: "",
            section_description1: "",
            section_title2: "",
            section_description2: "",


            errors: {},


        };



    }

    //componentDidMount
    async componentDidMount() {

        var slug = window.location.href.substring(window.location.href.lastIndexOf('/') + 1);
        axios.post(apiUrl.apiUrl + "/programs/edit_programs_by_slug/" + slug).then((res) =>
            this.setState({
                main_title: res.data[0].main_title,
                main_description: res.data[0].main_description,
                section_title1: res.data[0].section_title1,
                section_description1: res.data[0].section_description1,


                media_type: res.data[0].media_type,


                section_title: res.data[0].section_title,

                slug: res.data[0].slug,
                section_title2: res.data[0].section_title2,
                section_description2: res.data[0].section_description2,

                imageURL:
                    apiUrl.apiUrl +
                    "/mvd/ourPrograms/" +
                    res.data[0].section_background_image,




            })
        );

        console.log(this.state);
    }



    render() {

        let section_description1 = this.state.section_description1.split("\n");
        let section_description2 = this.state.section_description2.split("\n");

        return (
            <Fragment>
                <section className="media-inner-description-sec">

                    <Container fluid className="wrap">
                        <Row>
                            <Col md={12}>
                                <div className="media-inner-des-smallhead">

                                    <h6 className="media-inner-desc-heading" data-aos='fade-up'>{this.state.section_title1}</h6>
                                    <p className="common-para" data-aos='fade-right'>



                                        <span>
                                            {


                                                section_description1.map((item, i) => (
                                                    (

                                                        <span key={"section_description1_" + i}>
                                                            {item} <br />
                                                        </span>


                                                    )

                                                ))


                                            }

                                        </span>

                                    </p>





                                    <h6 className="media-inner-desc-heading" data-aos='fade-up'>{this.state.section_title2}</h6>
                                    <p className="common-para" data-aos='fade-right'>

                                        <span>
                                            {


                                                section_description2.map((item, i) => (
                                                    (

                                                        <span key={"section_description2_" + i}>
                                                            {item} <br />
                                                        </span>


                                                    )

                                                ))


                                            }

                                        </span>

                                    </p>





                                </div>

                            </Col>
                        </Row>
                    </Container>
                </section>

            </Fragment>
        )
    }


}


export default ProgramsInnerDescription