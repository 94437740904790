import styled from 'styled-components';

const  Progress  =  styled.div`
  position:  relative;
  background:  linear-gradient(
    to right,
    #00B398 ${props => props.scroll},
    #f6f7f8  0);
  width:  100%;
  max-width: 1240px;
  height:  5px;
  z-index:  3;
  margin:0 auto;
  border-radius:0px!important;
  left:0px;
  right:0px;
  top:0px; 
  -webkit-transition: all .1s;
  transition: all .1s; 
`;

export  default  Progress;