import React, { PureComponent, Fragment } from 'react';
import 'bootstrap-4-react';
import Header from './../../common/header';
import Footer from './../../common/footer';
import LokeshwariBanner from './lokeshwari-banner'; 
 import LokeshwariDescription from './lokeshwari-description';
import LokeshwariSmilarStories from './stories-inner-lokeshwari-smilarstories';   

class LokeshwariPage extends PureComponent{

    render(){

        return(
            <Fragment> 
            <Header /> 
            <LokeshwariBanner/>
            <LokeshwariDescription />
            <LokeshwariSmilarStories/>  
            <Footer />
        </Fragment>
        )

    }
}

export default LokeshwariPage