import React, { PureComponent, Fragment } from 'react';
import 'bootstrap-4-react';
import Header from './../common/header';
import Footer from './../common/footer';
import HomeBanner from './home-banner'; 
import HomeAbout from './home-about'; 
// import HomePartners from "./home-partners";
import HomePrograms from './home-programs'; 
import HomeParllax from './home-parallax';
import HomeLatestNews from './home-latestnews'; 
import HomeGetinvolved from './home-getinvolved';   

import ReactLoading from "react-loading";
import styled from "tachyons-components";
import * as apiUrl from '../../apiUrl';
import axios from "axios";
import { Helmet } from "react-helmet";

export const Article = styled('div')`
loading-image items-center justify-center flex flex-column flex-wrap`;


class HomePage extends PureComponent{

  constructor(props) {
    super(props);
    this.state = {
      isLoading: true,
      home_meta_title:"",
      home_meta_description:"",
    }
  }
  componentDidMount() { 

    axios.post(apiUrl.apiUrl + "/seometa/edit_seo_meta/1").then((res) => {
      this.setState({
        home_meta_title: res.data[0].home_meta_title,
        home_meta_description: res.data[0].home_meta_description,

  
      })


  }
  );




    this.setState({ isLoading: true }); 
    setTimeout(() => {
      this.setState({ isLoading: false });  
    }, 5000);
  
    let url = window.location.href;
    document.body.addEventListener('click', ()=>{
        requestAnimationFrame(()=>{
          if(url!== window.location.href){
            console.log('url changed');
            url =  window.location.href;
            this.onRouteChanged();
          }
        });
    }, true);
   
  } 

  onRouteChanged() { 
    this.setState({ isLoading: true }); 
    setTimeout(() => {
      this.setState({ isLoading: false });
    }, 5000);
  }
    
  render(){

      return(

        this.state.isLoading ?
        <Fragment>
                  <Article key="spin" >
                  <ReactLoading type="spin" color="#000" className="loading-img" /> 
                </Article>
                </Fragment>
        
                :
        <Fragment> 
            <Header />  

            <Helmet> 
                <title>{this.state.home_meta_title}</title>
                <meta name="description" content={this.state.home_meta_description} />
            </Helmet> 

            <HomeBanner/>
            <HomeAbout/>
          
            <HomePrograms/> 
           
            <HomeLatestNews/>
            <HomeParllax />
            <HomeGetinvolved/> 
            {/* <HomePartners /> */}
            <Footer />
        </Fragment>
      )

  }
}

export default HomePage