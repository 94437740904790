import React, { PureComponent, Fragment } from 'react';
import { Container, Col, Row, Button } from 'bootstrap-4-react';
import Header from './../common/header';
import Footer from './../common/footer';
import { Link } from 'react-router-dom'; 
class NotfoundPage extends PureComponent {
    render() { 
        return (
          <Fragment>  
            <Header/>
                {/* Home Banner Section */}
                  <Container fluid className="bannerSec notfoundBanner">
                    <div className="overlay"></div>
                        <div className="bannerHeight wrap">
                            <Row className="bannerRow text-center">  
                                <Col lg={8} md={7} className="bannerDiv">   
                                <h1 className="common-heading" data-aos='fade-up'>404</h1>
                                <p className="common-para" data-aos='fade-up'> SORRY, WE CAN’T FIND THE PAGE

YOU WERE LOOKING FOR </p> 
                                <Link  to="/home"><Button className="greenColorBtn" data-aos='fade-up'>BACK TO HOME</Button>  </Link>
                                </Col>  
                            </Row> 
                        </div>  
                  </Container>  
                  <Footer />
          </Fragment> 
        )
      }
}


export default NotfoundPage