import React, { PureComponent, Fragment } from 'react';
import { Container, Col, Row } from 'bootstrap-4-react';   
import * as apiUrl from '../../apiUrl';
import axios from "axios";

class HomeParllax extends PureComponent {


//constructor
constructor(props) {
    super(props);

    //variables
    this.state = {
      media_type: "",
      selectedFiles: undefined,
      currentFile: undefined,
      section_title: "",
      section_description: "",

      errors: {},
      imageURL: "",
      data: {},
    };

   
  }

  //componentDidMount
  componentDidMount() {
    axios.post(apiUrl.apiUrl + "/home/edit_home_page_education_section/1").then((res) =>
      this.setState({
        section_title: res.data[0].section_title,
        section_description: res.data[0].section_description,
        media_type: res.data[0].media_type,
        imageURL:
          apiUrl.apiUrl +
          "/mvd/HomePageEducationSection/" +
          res.data[0].section_background_image,
      })
    );

    console.log(this.state);
  }

    render() { 

        let section_title = this.state.section_title.split(" ");


        return (
          <Fragment>  
         <section className="home-parallax-sec">
            
                   
                    <Container fluid className="parallaxBG" style={this.state.media_type == 'image' ? {
                    backgroundImage: `url("` + this.state.imageURL + `")`
                } : {}}>
                        <div className="overlay"></div>
                        <Row className="justify-content-center">
                            <Col md={9}>
                                <div className="hero-text">
                                     {/*    <h2 className="poppinsRegular"  data-aos='fade-up'>"Education is a  <span className="greenColor">powerful</span> weapon to eradicate discrimination"</h2> */}

                                        <h2 className="poppinsRegular"  data-aos='fade-up'>
                                        <span>
                                        {


                                            section_title.map((item, i) => (
                                                (
                                                    i == 3 ?
                                                        <span key={"section_title_parallax_" + i}>
                                                            {item} &nbsp;
                                                        </span> : <span key={"section_title_parallax_" + i}>
                                                            {item} &nbsp;
                                                        </span>


                                                )

                                            ))


                                        }

                                    </span>
                                            
                                            </h2>
 
                                        <p  data-aos='fade-up'>{this.state.section_description}</p>
                                </div>
                            </Col>
                        </Row>
                    </Container>
 
             </section>
          
          </Fragment>
        )
      }
}


export default HomeParllax