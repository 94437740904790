import React, { PureComponent, Fragment } from 'react';
import { Container, Col, Row } from 'bootstrap-4-react';   
import * as apiUrl from '../../../apiUrl';
import axios from "axios";
class StoriesInnerDescription extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
        main_title: "",
        main_description: "",
        slug: "",
        media_type: "",
        selectedFiles: undefined,
        currentFile: undefined,
        imageURL: "", 


        errors: {},


    };
 
}
    //componentDidMount
    async componentDidMount() {

      var slug = window.location.href.substring(window.location.href.lastIndexOf('/') + 1);
      axios.post(apiUrl.apiUrl + "/stories/edit_stories_by_slug/" + slug).then((res) =>
          this.setState({
              main_title: res.data[0].main_title,
              main_description: res.data[0].main_description, 


              media_type: res.data[0].media_type,
 

              slug: res.data[0].slug, 

              imageURL:
                  apiUrl.apiUrl +
                  "/mvd/ourStoriesPost/" +
                  res.data[0].section_background_image,




          })
      );

      console.log(this.state);
  }

    render() { 
      let main_description = this.state.main_description.split("\n");
        return (
          <Fragment>  
        <section className="media-inner-description-sec">
             
             <Container fluid className="wrap"> 
                 <Row>
                      <Col md={12}>
                          <div className="media-inner-des-smallhead"> 



                          <h6 className="media-inner-desc-heading" data-aos='fade-up'> {this.state. main_title}</h6>
                          <div>
                          
                                            {


main_description.map((item, i) => (
                                                    (

                                                        <p className="common-para" data-aos='fade-right' key={"main_description" + i}>
                                                            {item}  
                                                        </p>


                                                    )

                                                ))


                                            }

                                        </div>
 
 
                           </div>  

                      </Col>
                 </Row>
               </Container>   
        </section>
          
          </Fragment>
        )
      }
}


export default StoriesInnerDescription