// import React, { PureComponent, Fragment } from 'react';
// import { Container } from 'bootstrap-4-react';
  
// class StoriesInnerBanner extends PureComponent {
//     render() { 
//         return ( 
//             <Fragment>  
//                 {/* Media Banner Section */}
//                   <Container fluid className="bannerSec muthuselvi-banner">
//                     <div className="overlay"></div>   
//                   </Container>  
//           </Fragment> 
//         )
//       }
// }


// export default StoriesInnerBanner

import React, { PureComponent, Fragment } from 'react';
import { Container } from 'bootstrap-4-react'; 
import * as apiUrl from '../../../apiUrl';
import axios from "axios";


class StoriesInnerBanner extends PureComponent {


  constructor(props) {
    super(props);
    this.state = { 
        slug: "",
        media_type: "",
        selectedFiles: undefined,
        currentFile: undefined,
        imageURL: "", 


        errors: {},


    };

    

}

//componentDidMount
async componentDidMount() {

  var slug = window.location.href.substring(window.location.href.lastIndexOf('/') + 1);
  axios.post(apiUrl.apiUrl + "/stories/edit_stories_by_slug/" + slug).then((res) =>
      this.setState({
          


          media_type: res.data[0].media_type,


          

          imageURL:
              apiUrl.apiUrl +
              "/mvd/ourStoriesPost/" +
              res.data[0].section_background_image,




      })
  );

  console.log(this.state);
}


    render() { 
        return ( 
            <Fragment>  
                {/* Media Banner Section */}
                  <Container fluid className="bannerSec stories-inner-banner" style={this.state.media_type == 'image' ? {
                    backgroundImage: `url("` + this.state.imageURL + `")`
                } : {}}>
                    <div className="overlay"></div>   

                    {this.state.media_type == 'video' ?
                        <video src={this.state.imageURL} playsInline="playsInline" autoPlay="autoPlay" muted="muted" loop="loop">

                        </video>
                        : ''

                    }
                  </Container>  
          </Fragment> 
        )
      }
}


export default StoriesInnerBanner