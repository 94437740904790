
import React, { PureComponent, Fragment } from 'react';
import { Container, Col, Row, Button } from 'bootstrap-4-react'; 
import { Link } from 'react-router-dom';  
import { Swiper, SwiperSlide } from 'swiper/react';

import SwiperCore, { Autoplay } from 'swiper'; 

// swiper core styles
import 'swiper/css';  
import storiesImage1 from './../../../assets/Muthuselvi.JPG'; 
import storiesImage2 from './../../../assets/amudha.JPG'; 
import storiesImage3 from './../../../assets/lokeshwari.jpg'; 

SwiperCore.use([Autoplay]);
class AmuthavalliSmilarStories extends PureComponent {
    render() { 
        return (
          <Fragment> 


<section className="latestnews-sec programs-sec lightGreenBG latest-news-inner">
                    <Container fluid className="wrap"> 
                        <div className="commonHeadingDiv">
                            <Row className="commonHeadingRow text-center">  
                                <Col md={9}>   
                                <h1 className="common-heading" data-aos='fade-up'>Similar Stories</h1> 
                                </Col>  
                            </Row> 
                        </div>  
                  </Container>    
                  <Container fluid className="wrap programs-div">
                      <Row>
                          <Col md={12}>
                          <Swiper
                          className="smilarStoriesSlider"
                          spaceBetween={50}
                          slidesPerView={3}
                          loop={true}
                          autoplay={false}
                          draggable={true}
                          breakpoints={{
                            // when window width is >= 640px
                            320: {
                              slidesPerView: 1,
                            },
                            420:{
                                slidesPerView: 1,
                            },
                            768:{
                                slidesPerView: 3,
                            }
                          }}
                        >
                               <SwiperSlide>
                               <Link to="/stories-inner-muthuselvi">
                                  <div className="our-stories" data-aos='fade-up'> 
                                      <div className="stories-img">
                                        <img src={storiesImage1} className="img-fluid" alt="storiesimage" /> 
                                      </div>
                                      <div className="stories-content"> 
                                          <h5 className="sub-heading " >College is but a dream</h5>    
    <p>This is Muthuselvi. She has always been determined to pursue her higher education. She did not give up even when she didn’t meet the academic criteria to join a government college. She got into a private college. </p>
<Link to="/stories-inner-muthuselvi"><Button className="bottomBorder">Know More</Button>  </Link>
                                      </div>
                                  </div>
                              </Link>
                              </SwiperSlide>
                              <SwiperSlide>
                              <Link to="/stories-inner-lokeshwari">
                                  <div className="our-stories" data-aos='fade-up'>
                                      <div className="stories-img">
                                        <img src={storiesImage3} className="img-fluid" alt="storiesimage" /> 
                                      </div>
                                      <div className="stories-content"> 
                                          <h5 className="sub-heading">Listening and hearing are two different senses</h5>    
    <p>Lokeswari, the elder of two sisters, was born with a hearing impairment. When she was just a little girl, she was examined by a medical professional.</p>
<Link to="/stories-inner-lokeshwari"><Button className="bottomBorder">Know More</Button>  </Link>
                                      </div>
                                  </div>
                              </Link>
                              </SwiperSlide> 
                              <SwiperSlide>
                              <Link to="/stories-inner-amuthavalli">
                                  <div className="our-stories" data-aos='fade-up'>
                                      <div className="stories-img">
                                        <img src={storiesImage2} className="img-fluid" alt="storiesimage" /> 
                                      </div>
                                      <div className="stories-content"> 
                                          <h5 className="sub-heading">Happiness begins with good health</h5>    
    <p>Amuthavalli lives with her husband and 2 daughters in the outskirts of Chennai. One day out of the blue, her elder daughter Sivasri was affected by a severe back ache.</p>
<Link to="/stories-inner-amuthavalli"><Button className="bottomBorder">Know More</Button>  </Link>
                                      </div>
                                  </div>
                              </Link>
                              </SwiperSlide> 
                            </Swiper>
                          </Col> 
                      </Row>
                    </Container>   
             </section>
        
          </Fragment>
        )
      }
}


export default AmuthavalliSmilarStories