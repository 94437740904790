import React, { Fragment, PureComponent } from 'react';
import Header from './../common/header';
import Footer from './../common/footer';
import ContactBanner from './contact-banner';
import ContactForm from './contact-form';
import OtherwaysContact from './other-ways-contact';
import * as apiUrl from '../../apiUrl';
import axios from "axios";
import { Helmet } from "react-helmet";


class ContactPage extends PureComponent {

    constructor(props) {
        super(props);
        this.state = {

            contact_us_meta_title: "",
            contact_us_meta_description: "",
        }
    }

    componentDidMount() {

        axios.post(apiUrl.apiUrl + "/seometa/edit_seo_meta/1").then((res) => {
            this.setState({
                contact_us_meta_title: res.data[0].contact_us_meta_title,
                contact_us_meta_description: res.data[0].contact_us_meta_description,


            })


        }
        );


    }



    render() {
        return (
            <Fragment>
                <Header />

                <Helmet>
                    <title>{this.state.contact_us_meta_title}</title>
                    <meta name="description" content={this.state.contact_us_meta_description} />
                </Helmet>
                <ContactBanner />
                <ContactForm />
                <OtherwaysContact />
                <Footer />
            </Fragment>
        )
    }
}
export default ContactPage