import React, { PureComponent, Fragment } from 'react';
import { Container, Col, Row } from 'bootstrap-4-react';  
// import ourprograms1 from './../../../assets/ourprograms.png'; 
// import ourprograms2 from './../../../assets/ourprograms1.png'; 
// import ourprograms3 from './../../../assets/ourprograms2.jpg'; 

class HopeProjectDescription extends PureComponent {
    render() { 
        return (
          <Fragment>  
         <section className="media-inner-description-sec">
             
                  <Container fluid className="wrap"> 
                      <Row>
                           <Col md={12}>
                               <div className="media-inner-des-smallhead"> 
                                    <h6 className="media-inner-desc-heading" data-aos='fade-up'>Why we need to help:</h6>
                                    <p className="common-para" data-aos='fade-right'>Youths from vulnerable backgrounds, if not skilled, resort to working low-paying manual jobs to support their families. Oftentimes, they are left with no choice but to drop out of school at a young age and start working to contribute to their family financially. Some of the youth even resort to crime, just to survive. </p>

                                    <p className="common-para" data-aos='fade-right'>For youths who have not completed their formal education, setting up facilities that could impart skills necessary for employment gives them a second chance to build a life for themselves. Access to education and the opportunity to learn new skills would make them employable at better-paying jobs that could get them out of poverty. 
                                    </p>



                                    <h6 className="media-inner-desc-smallhead" data-aos='fade-up'>How we help:</h6>
                                    <p className="common-para" data-aos='fade-right'>We assess youths from the slums and rural Chennai, who have either dropped out of school or not completed their formal education are enrolled. They are then given over hours of training by qualified professionals to learn various programs based on their skills and requirements. Some of our programs include English speaking skills, sales & customer relationship skills and overall life skills. After the training, we get them placed in supermarkets, retail outlets, and counter sales with fitting salaries that are on par with industry standards.</p>

                                    <p className="common-para" data-aos='fade-right'>This paves the way for more confident and self-assured young adults with independent aspirations and ambitions that will help them attain their goals in life. They become financially independent and are equipped with skills that will assist them in any social or office setting.
With a well-paying job, these young adults subsequently help their families come out of poverty, thus building a more promising future for our country.

                                    </p>
                                </div> 
 
                           </Col>
                      </Row>
                    </Container>   
             </section>
          
          </Fragment>
        )
      }
}


export default HopeProjectDescription