import React, { PureComponent, Fragment } from 'react';
import { Container, Col, Row, Button } from 'bootstrap-4-react';
import Pagination from 'react-bootstrap/Pagination'
import { FaArrowRight } from "react-icons/fa";
import { Link } from 'react-router-dom';


import cardImage1 from './../../assets/newseventsbanner.png';
import cardImage2 from '../../assets/newsevents2.png';
import cardImage3 from '../../assets/newseventsbanner.png';


import cardImage4 from '../../assets/OurProgramsjoyproject.jpg';



class NewsList extends PureComponent {

 

  render() {
    return (
      <Fragment>

        <section className="programs-sec programs-page-sec news-events-sec">
          <Container fluid className="wrap">
            <div className="commonHeadingDiv">
              <Row className="commonHeadingRow text-center blackColor">
                <Col md={9}>
                  <h1 className="common-heading" data-aos='fade-right'>Our News</h1>
                  <p className="common-para" data-aos='fade-left'>The tireless work we do to uplift the underprivileged has turned the spotlight on us in order to raise more awareness about our work. Learn more about what's happening with the Trust through the articles in the Media. </p>
                </Col>
              </Row>
            </div>
          </Container>
         <Container fluid className="wrap programs-div">
              <Link to='/news-inner'>
                <div className="programs-card" data-aos='fade-up'>
                  <Row className="margin-zero">
                    <Col md={6} className="card-bg-content">
                      <div className="card-top-stripe"> The Joy Project News Mentions</div>
                      <div className="card-content">
                     
                        <span className="news-event-name">18-07-2022</span>
                        <h5 className="font-weight-bold">  Nutritional Support Program for Pregnant Women from BPL families</h5>

                        <p>The initiation of our program to support pregnant women hailing from Below Poverty Line families in the slums of Chennai... </p>
                        <Button className="yellowColorBtn">Read More</Button>
                      </div>
                    </Col>

                    <Col md={6} className="card-bg-img">

                      <div className="card-img">
                        <div className="card-bg-imgs">
                        <img
                          src={cardImage1}
                          className="img-fluid"
                          alt="cardimage"
                        />
                        </div>
                      </div>

                    </Col>
                  </Row>
                </div>
              </Link>
          </Container>  
          {/* <Container fluid className="wrap programs-div">
 

            <Row>
 
                <Col md={4}>
                  <Link to='/news-inner'>
                    <div className="programs-card" data-aos='fade-up'>
                      <div className="card-top-stripe">The Joy Project News Mentions</div>
                      <div className="card-img">
                        <img
                          src={cardImage1}
                          className="img-fluid"
                          alt="cardimage"
                        />
                      </div>
                      <div className="card-content">
                        <Row>
                          <Col className="text-left">
                            <span className="news-event-name">News</span>
                          </Col>
                          <Col className="text-right">
                            <span className="news-event-name">18-07-2022</span>
                          </Col>
                        </Row>
                     
                        <h5 className="font-weight-bold">
                        Nutritional Support Program for Pregnant Women from BPL families
                        </h5>
                          <p>The initiation of our program to support pregnant women hailing from Below Poverty Line families in the slums of Chennai...</p>   


                        <Button className="yellowColorBtn">Read More</Button>
                      </div>
                    </div>
                  </Link>
                </Col>
                
                <Col md={4}>
                  <Link to='/news-inner'>
                    <div className="programs-card" data-aos='fade-up'>
                      <div className="card-top-stripe">The Joy Project Press Release</div>
                      <div className="card-img">
                        <img
                          src={cardImage2}
                          className="img-fluid"
                          alt="cardimage"
                        />
                      </div>
                      <div className="card-content">
                      <Row>
                          <Col className="text-left">
                            <span className="news-event-name">News</span>
                          </Col>
                          <Col className="text-right">
                            <span className="news-event-name">20-07-2022</span>
                          </Col>
                        </Row>
                        <h5 className="font-weight-bold">
                        Nutritional Support Program for Pregnant Women from BPL families
                        </h5>
                          <p>Chennai-based Rahul Charitable Trust for Social Welfare organized a Nutrition Program for pregnant women on 2nd July 2022 in Virukampaakam, Chennai... </p>   


                        <Button className="yellowColorBtn">Read More</Button>
                      </div>
                    </div>
                  </Link>
                </Col> 
                <Col md={4}>
                  <Link to='/news-inner'>
                    <div className="programs-card" data-aos='fade-up'>
                      <div className="card-top-stripe">The Joy Project Launch Event</div>
                      <div className="card-img">
                        <img
                          src={cardImage3}
                          className="img-fluid"
                          alt="cardimage"
                        />
                      </div>
                      <div className="card-content">
                      <Row>
                          <Col className="text-left">
                            <span className="news-event-name">News</span>
                          </Col>
                          <Col className="text-right">
                            <span className="news-event-name">20-07-2022</span>
                          </Col>
                        </Row>
                        <h5 className="font-weight-bold">
                        Nutritional Support Program for Pregnant Women from BPL families
                        </h5>
                          <p>Chennai-based Rahul Charitable Trust for Social Welfare organized a Nutrition Program for pregnant women on 2nd July 2022 in Virukampaakam, Chennai... </p>   


                        <Button className="yellowColorBtn">Read More</Button>
                      </div>
                    </div>
                  </Link>
                </Col> 
            </Row>
          </Container> */}
          {/* <Container fluid>
              <Row>
                  <Col md={12}>
                      <Pagination className="justify-content-center">  
                          <Pagination.Item active>{1}</Pagination.Item> 
                          <Pagination.Item>{2}</Pagination.Item>
                          <Pagination.Item>{3}</Pagination.Item> 
                          <Pagination.Ellipsis />
                          <Pagination.Item>{7}</Pagination.Item>
                          <Pagination.Next> NEXT <FaArrowRight/> </Pagination.Next> 
                      </Pagination>
                  </Col>
              </Row>
              
          </Container>  */}
        </section>



      </Fragment>
    )
  }
}


export default NewsList


 