import React, { PureComponent, Fragment } from 'react';
import 'bootstrap-4-react';
import Header from './../../common/header';
import Footer from './../../common/footer';
import GetinvolvedPartnersBanner from './getinvolved-partners-banner';
import PartnersBanner from './partners-banner'; 
import NavTabs from './../nav-tabs';
import PartnersForm from './partners-form'; 
// import PartnersLogos from './partners-logo-swiper';
import scrollToComponent from 'react-scroll-to-component';
import * as apiUrl from '../../../apiUrl';
import axios from "axios";
import { Helmet } from "react-helmet";


class GetInvolvedPartnerspage extends PureComponent{

  constructor(props) {
    super(props);
    this.state = {
      
      get_involved_partners_meta_title:"",
      get_involved_partners_meta_description:"",
    }
  }

  componentDidMount() {


    axios.post(apiUrl.apiUrl + "/seometa/edit_seo_meta/1").then((res) => {
      this.setState({
        get_involved_partners_meta_title: res.data[0].get_involved_partners_meta_title,
        get_involved_partners_meta_description: res.data[0].get_involved_partners_meta_description,

  
      })


  }
  );
    scrollToComponent(this.Blue, { offset: 0, align: 'middle', duration: 250, ease:'inCirc'});
  }

  render(){

      return(
        <Fragment> 
            <Header />

            <Helmet> 
                <title>{this.state.get_involved_partners_meta_title}</title>
                <meta name="description" content={this.state.get_involved_partners_meta_description} />
            </Helmet>   
            <GetinvolvedPartnersBanner/>
            <PartnersBanner/>
            <NavTabs ref={(section) => { this.Blue = section; }}/> 
            <PartnersForm/>  
            {/* <PartnersLogos/> */}
            <Footer />
        </Fragment>
      )

  }
}

export default GetInvolvedPartnerspage