import React, { PureComponent, Fragment } from 'react';
import 'bootstrap-4-react';
import Header from './../../common/header';
import Footer from './../../common/footer';
import TheEmpowerProjectBanner from './the-empower-project-banner'; 
import TheEmpowerProjectForm from './/the-empower-project-form';
import TheEmpowerProjectDescription from './the-empower-project-description';
import TheEmpowerProjectLatestPrograms from './the-empower-project-latest-programs';   

class TheEmpowerProjectPage extends PureComponent{

    render(){

        return(
            <Fragment> 
            <Header /> 
            <TheEmpowerProjectBanner/>
            <TheEmpowerProjectForm />
            <TheEmpowerProjectDescription />
            <TheEmpowerProjectLatestPrograms/>  
            <Footer />
        </Fragment>
        )

    }
}

export default TheEmpowerProjectPage